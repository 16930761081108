import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/WhyDigitizing.css'
import { Container, Row, Col} from 'react-bootstrap';

const WhyDigitizing = () => {
  return (
    <Container fluid className='mt-5 p-5 why-digi-cntainer'>
        <Container>
            <Row className=''>
                <Col>
                <h2>Why Digitizing Fox</h2>
                <Row className='mt-3'>
                    <Col>
                    <ol>
                        <li>
                        Expert in Small text, lettering details.
                        </li>
                        <li>
                        24/7 Live Customer help desk.
                        </li>
                        <li>
                        Best quality in the market just compare us.
                        </li>
                        <li>
                        Friendly Process through our website.
                        </li>
                        <li>
                        Vector art creation at very competitive pricing.
                        </li>
                        <li>
                        Assigned personal Sales Rep and Digitizer for help.
                        </li>
                    </ol>
                    </Col>
                    <Col>
                    <ol start={7}>
                        <li>
                        TAT 2 to 12 hours depending on design.
                        </li>
                        <li>
                        Free Sample with your design.
                        </li>
                        <li>
                        We provide the best pricing to our clients
                        </li>
                        <li>
                        Experienced Professional Staff.
                        </li>
                        <li>
                        Your Machine will tell about the quality we deliver.
                        </li>
                        <li>
                        Biggest digitizing and Vector unit.
                        </li>
                        <li>
                        Embroidered, Chenille, Leather, Woven and Sublimated Patches also Key-chains under one roof.
                        </li>
                    </ol>
                    </Col>
                </Row>
                </Col>
                <Col className='gelry'>
                <Row>
                    <Col>
                        <div>
                        <img alt='img' src='assets/whyDigitizing/Rectangle 24.webp' className='why-digi-gel-1' width={180} height={180}/>
                        <img alt='img' src='assets/whyDigitizing/Rectangle 33.webp'  className='why-digi-gel-2 all' width={120} height={120}/>
                        <img alt='img' src='assets/whyDigitizing/Rectangle 31.webp' className='why-digi-gel-3' width={120} height={120}/>
                        </div>
                        <div className='gel-row'>
                        <img alt='img' src='assets/whyDigitizing/Rectangle 32.webp' className='why-digi-gel-4 all' width={120} height={120}/>
                        <img alt='img' src='assets/whyDigitizing/Rectangle 26.webp' className='why-digi-gel-5 all' width={120} height={120}/>
                        <img alt='img' src='assets/whyDigitizing/Rectangle 30.webp' className='why-digi-gel-6 all' width={120} height={120}/>
                        </div>

                        <div>
                        <img alt='img' src='assets/whyDigitizing/Rectangle 34.webp' className='why-digi-gel-7 all' width={120} height={120}/>
                        </div>
                        

                    </Col>
                </Row>
                </Col>
            </Row>
        </Container>
      </Container>
  )
}

export default WhyDigitizing