import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Col, Button, Row, Container, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { LoginContext } from '../../../ContextProvider/Context';
import '../profile/UserProfile.css';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserProfile = () => {
  const { logindata} = useContext(LoginContext);

  const [userData, setUserData] = useState();
const [data, setData] = useState([]);
const [dataToUpdate, setDataToUpdate] = useState({});
  const [isLoading, setIsLoading] =useState(false)




  ///////////////
  /////////////////////Costumer Profile Get///////////////////
  const costumerProfileUrl = `${process.env.REACT_APP_API_URL}/users/user/${logindata.id}`;

useEffect(() => {
  const fetchData = async () => {
    try {
    setIsLoading(true)

      const response = await fetch(costumerProfileUrl, {
        method: 'GET', // Specify the method
        headers: {
          "Content-Type": "application/json", // Set the headers
        },
        credentials: "include", // Set the credentials option
      });
      setIsLoading(false)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setData(data); // Set the fetched data
    } catch (error) {
      console.error("Error",error)
      setIsLoading(false); // Update loading state
    }
  };

  fetchData();
}, [costumerProfileUrl]); // The empty dependency array ensures this effect runs only once on mount

////////////////////////Costumer Profile Update///////////////////

useEffect(() => {
  // Check if data.user is not undefined or null before updating userData
  const newUser = data
  setUserData(newUser);
}, [data]);

useEffect(() => {
  // Update dataToUpdate whenever userData changes
  setDataToUpdate({ ...userData });
}, [userData]);


const handleInputChange =(e) =>{
  const {name, value} = e.target;
  setDataToUpdate({
    ...dataToUpdate,
    [name]: value,
  })
}


const handleUpdateUser = async () => {
  try {
    setIsLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}/users/update/${logindata.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToUpdate),
        });
        setIsLoading(false)

        // const updatedUser = await res.json();
  
        // console.log("MMMMMMMMMMMMMMMMMMM",updatedUser)
        if (res.ok) {
          toast.success('updated')
          // setData(updatedUser)
          // navigate(`/userProfileInAdmin/${id}`)
        } 
        else if(!res.ok){
          toast.error(`Can't update:${res.status}`)
        }
  
  } catch (error) {
    setIsLoading(false)
          toast.error(`Can't update:${error}`)
  }
}

  return (
    <>
      <Container className="outr-container py-5 p-5" fluid>
        <Row className="justify-content-center mt-5">
          <Col xs={12} md={6}>
            <Container className="innr-container p-3">
            {!isLoading? (
              <Form onSubmit={handleUpdateUser}>
                <img className='d-flex mx-auto dgOrd-frm-logo' src="assets/logo.png" alt="" />
                <div className='fw-bold text text-center '>
                  <h3 className='fw-bold'>Welcome To Asif!</h3>
                  <h4 className='fw-bold'>|My Profile|</h4>
                </div>

                <Row>
                  <Col md={3} xs={4} lg={2} className=' justify-content-center d-flex'>
                  <img src='assets/DashImgs/dshprofile.png' alt='' style={{width: "24", height:"50px"}}></img>

                  </Col>
                </Row>

                <Row>
                  <Col>
                  <span className='btn btn-success mb-3 mx-auto '>{dataToUpdate.salesRep}</span>
                  </Col>
                </Row>

                <Form.Group as={Row} className='mb-1' controlid="formFullName">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Name:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control
                      className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="name"
                      value={dataToUpdate.name}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-1' controlid="salesRep">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Sales Rep:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control
                      className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="salesRep"
                      value={dataToUpdate.salesRep}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullCompany">
                  <Form.Label column xs={6} md={4} className='fw-bold'>
                    Company:
                  </Form.Label>
                  <Col xs={6} md={8}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="company"
                      value={dataToUpdate.company}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullPhone">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Phone:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="tel"
                      name="phone2"
                      value={dataToUpdate.phone2}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullCellPhone">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Cell Phone:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="tel"
                      name="phone"
                      value={dataToUpdate.phone}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullFax">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Fax:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="tel"
                      name="fax"
                      value={dataToUpdate.fax}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullEmail">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                     Email:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="email"
                      name="email"
                      disabled
                      value={dataToUpdate.email}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullEmail">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Email Files To:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="email"
                      name="emailFileTo"
                      value={dataToUpdate.emailFileTo}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullEmail">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Invoice Email:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="email"
                      name="invoiceEmail"
                      value={dataToUpdate.invoiceEmail}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullAddress">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Address:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="address"
                      value={dataToUpdate.address}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>


                <Form.Group as={Row} className='mb-1' controlid="formFullShippingAddress">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                   Shipping Address:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="shippingAddress"
                      value={dataToUpdate.shippingAddress}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formCountry">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Country:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="country"
                      value={dataToUpdate.country}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullState">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    State:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="state"
                      value={dataToUpdate.state}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullCity">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    City:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="city"
                      value={dataToUpdate.city}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullZipcode">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Zip Code:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="number"
                      name="zip"
                      value={dataToUpdate.zip}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formFullCurrency">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    Currency:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="currency"
                      value={dataToUpdate.currency}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-1' controlid="formSocialMedia">
                  <Form.Label column md={4} xs={6} className='fw-bold'>
                    How Did You Hear About Us:
                  </Form.Label>
                  <Col md={8} xs={6}>
                    <Form.Control className="form-control-sm mt-1 profle-inpt-dsign"
                      type="text"
                      name="howDidYouFindUs"
                      value={dataToUpdate.howDidYouFindUs}
                      onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <div className="d-grid mb-3 justify-content-center">
                  <Button variant="primary" type="submit">
                    Update
                  </Button>
                </div>
              </Form>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                  }}
                >
                  Loading... <CircularProgress />
                </Box>
              )}
            </Container>
          </Col>
        </Row>
      </Container>



    </>
  )
}

export default UserProfile
