import React, {  } from 'react'
import { Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/AboutHeader.css'
import {
  FaFacebookF,
  FaYoutube,
  FaInstagram,
  FaTiktok,
  FaLinkedin,
} from 'react-icons/fa';

const AboutHeader = ({title = 'About Us',backgroundImage}) => {
  const headerStyle = {
    backgroundImage: backgroundImage,
  };
    return (
        <>
            <Container fluid className='a-Header' style={headerStyle}>
                    <div className='h1 text-white'>
                        {title}
                    </div>
                    <div className='h3 mt-3 d-inline-flex' >
                                    <a href='https://www.facebook.com/PatchProducer/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0' className='fb-Hvr text-white me-3'><FaFacebookF/></a>
                                    <a href='https://www.youtube.com/@DigitizingFox' className='utube-Hvr text-white me-3'><FaYoutube/></a>
                                    <a href='https://www.instagram.com/digitizingfox' className='insta-Hvr text-white me-3'><FaInstagram/></a>
                                <a href='https://www.linkedin.com/in/your_linkedin_profile' className='linkedin-Hvr text-white me-3'><FaLinkedin/></a>
                                <a href='https://www.tiktok.com/@digitizingfox' className='tiktok-Hvr text-white'><FaTiktok/></a>
                                </div>

            </Container>
        </>
    )
}

export default AboutHeader
