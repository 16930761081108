import React, { useState,useContext } from 'react'
import '../digitizing/DigitizingOrder.css';
import { useParams } from 'react-router-dom';
import {Col,Button,Row,Container,Form} from "react-bootstrap";
import { LoginContext } from '../../../ContextProvider/Context';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const DigitizingQuote = () => {
  const { id, cName } = useParams();

  const navigate = useNavigate()
  const { logindata } = useContext(LoginContext);
  const [validated, setValidated] = useState(false);
  // const [error, setError] = useState('');
  const [isLoading, setIsLoading] =useState(true)

    const [formData, setFormData] = useState({
      designName:'',
      fabric:'piqueCotton',
      placement:'',
      width:'',
      height:'',
      measureScale:'inches',
      numOfColor:'',
      additionalInstruction:'',
      instruction:'',
      ccOrderTo:'',
      superUrgent:false,
      attachments:[null],
      user:id? id : logindata.id,
      dateOrdered:Date.now(),
      costumerName:cName? cName :logindata.name,

    })

    const handleFileUpload = (e) => {
      const files = e.target.files;
  const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
  
  if (totalSize > 500 * 1024 * 1024) {
    // Alert the user about the size limit
    window.alert('Total file size exceeds 200 MB');
    // Optionally, you can clear the input field
    e.target.value = null;
    return;
  }
      setFormData({
        ...formData,
        attachments: files,
      });
    };

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.attachments[0] === null){
          window.alert("Please insert the Attachments!")
          e.target.value = null
          return
        }
        setValidated(true)

        const form = new FormData();
      for (const key in formData) {
        if (key === 'attachments') {
          for (let i = 0; i < formData[key].length; i++) {
            form.append('files', formData[key][i]);
          }
        } else {
          form.append(key, formData[key]);
        }
      }

        try {
          setIsLoading(false)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/digitizing/quote`, {
              method: 'POST',
              body: form,
            });
            
            if (response.status === 421) {
              console.error('files ! 421');
              setIsLoading(true)
            }
      
            if (response.ok) {
            window.alert('success')
              
              setIsLoading(true)
              setFormData(null)
              id ?  navigate(`/digitizingquoterecord/${id}/:${cName}`) :
               navigate('/digitizingquoterecord')
            } else {
              console.error('Registration failed');
              setIsLoading(true)
            }
          } catch (error) {
            console.error('Error:', error);
            setIsLoading(true)
          }
        };


    return (
        <>
        <Container className="outr-container p-3" fluid>
        <Row className="justify-content-center mt-5">
        <Col xs={12} md={6}>
        <Container className="innr-container">
        {isLoading? (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <img className='d-flex mx-auto dgOrd-frm-logo' src="assets/logo.webp" alt="" />
              <h2 className='fw-bold text text-center'>Welcome!</h2>
              <h5 className='fw-bold text text-center'>  |Place To Digitizing Quote|  </h5>
              <p className='mt-3 mb-3 frm-dshline'></p>

                <Row className="small">
                <Form.Group as={Col} className="small" controlid="formdesignName">
                     <Form.Label className="fw-bold">Design Name:</Form.Label>
                        <Form.Control className='form-control-sm dgform-cntrol'
                          placeholder="Enter name"
                          required
                          type="text"
                          name="designName"
                          value={formData.designName}
                          onChange={handleChange}
                        />
                            <Form.Control.Feedback type="invalid">
                                Please provide a  name.
                                </Form.Control.Feedback>
                                </Form.Group>
                  {/* Fabric */}
                  <Form.Group as={Col} className="mb-2 small" controlid="formdesignName">
                  <Form.Label className="fw-bold">Fabric:</Form.Label>
                  <Form.Select className='form-select-sm dgform-cntrol'
                    name="fabric"
                    value={formData.fabric}
                    onChange={handleChange}
                  >
                    <option value='piqueCotton'>Pique Cotton</option>
                    <option value='performanceShirt'>Performance Shirts (like dri-fit)</option>
                    <option value='cottonWoven'>Cotton Woven</option>
                    <option value='flees'>Fleece</option>
                    <option value='leather'>Leather</option>
                    <option value='towel'>Towel</option>
                    <option value='twill'>Twill</option>
                    <option value='t-shirt'>T-Shirt</option>
                    <option value='silk'>Silk</option>
                    <option value='woolen'>Woolen</option>
                    <option value='other'>Other</option>
                    </Form.Select>
                  </Form.Group>
                     </Row>

                     <Row className="small">
                  <Form.Group as={Col} className="small" controlid="formNumOfColor">
                    <Form.Label className="fw-bold">Number of Colors:</Form.Label>
                    <Form.Control className='form-control-sm dgform-cntrol'
                      type="number"
                      placeholder="1 to 10"
                      name="numOfColor"
                      value={formData.numOfColor}
                      onChange={handleChange}
                    />
                  </Form.Group>
                   {/* Placement */}
                   <Form.Group as={Col} className="mb-2 small" controlid="formdesignPlacement">
                  <Form.Label className="fw-bold">Placement:</Form.Label>
                  <Form.Select className='form-select-sm dgform-cntrol'
                    name="placement"
                    value={formData.placement}
                    onChange={handleChange}
                  >
                    <option >Choose...</option>
                    <option value='other'>Other</option>
                    <option value='cap'>Cap</option>
                    <option value='leftChest'>Left Chest</option>
                    <option value='jacketBack'> Jacket Back</option>
                    </Form.Select>
                  </Form.Group>
                  </Row>

                      <Row className="small">     
                  <Form.Group as={Col} className="small mb-2" controlid="formWidth">
                  <Form.Label className="fw-bold">Width:</Form.Label>
                  <Form.Control className='form-control-sm dgform-cntrol'
                    type="number"
                    placeholder="Enter width"
                    name="width"
                    value={formData.width}
                    onChange={handleChange}
                    />
                  </Form.Group>
 
                  <Form.Group as={Col} className="mb-2 small" controlid="formHeight">
                    <Form.Label className="fw-bold">Height:</Form.Label>
                    <Form.Control className='form-control-sm dgform-cntrol'
                      type="number"
                      placeholder="Enter height"
                      name="height"
                      value={formData.height}
                      onChange={handleChange}
                      />
                    </Form.Group>
                 
                  <Form.Group as={Col} className="d-flex align-items-end mb-2 small" controlid="formScale">
                    <Form.Select className='form-select-sm dgform-cntrol'
                      name="measureScale"
                      value={formData.measureScale}
                      onChange={handleChange}
                    >
                      <option value="inches">inches</option>
                      <option value="mm">mm</option>
                      <option value="cm">cm</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

     

                  <Row className="mb-2 small">
                <Col xs={12} md={4}>
                  <label className='fw-bold'>Instruction:</label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group controlid="Instruction">
                    <Form.Control className=' dgform-cntrol'
                      as="textarea"
                      rows={3}
                      placeholder="Enter your instructions here"
                      name="instruction"
                      value={formData.instruction}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-2 small">
                <Col xs={12} md={4}>
                  <label className='fw-bold'>Additional Instruction:</label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group controlid="additionalInstruction">
                    <Form.Control className=' dgform-cntrol'
                      as="textarea"
                      rows={3}
                      placeholder="Enter additional instructions here"
                      name="additionalInstruction"
                      value={formData.additionalInstruction}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="small">
              <Form.Group as={Col} controlid="formAttachments">
                  <Form.Label className='fw-bold'>Attachments</Form.Label>
                  <Form.Control className='form-control-sm dgform-cntrol'
                    type="file"
                    name="attachments"
                    onChange={handleFileUpload}
                    accept=".jpg, .png, .webp, .emb, .dst, .exp, .pes, .cnd, .pxf, .pof,
                    .dsb, .ofm, .jpeg, .jpg, .webp, .psd, .pdf, .ai, .eps, .svg, .cdr, .heic, .zip, .rar, .tif,"
                    required
                    multiple
                  />
                  <Form.Control.Feedback type="invalid">
                                    provide a valid document.
                        </Form.Control.Feedback>
                </Form.Group>
              </Row>

                <Row className='mb-2 small'>
                  <Col xs={12} md={6}>
                  <Form.Group controlid="ccOrderTo">
                    <Form.Label className='fw-bold'>CC this order to:</Form.Label>
                    <Form.Control className='form-control-sm dgform-cntrol'
                      type="email"
                      placeholder="CC Email"
                      name="ccOrderTo"
                      value={formData.ccOrderTo}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                  <Form.Group controlid="formBasicCheckbox">
                    <Form.Check className='small'
                      type="checkbox"
                      label="This order is super urgent"
                      name="superUrgent"
                      checked={formData.superUrgent}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  </Col>
                </Row>
                    <div className="d-grid mb-3 justify-content-center">
                      <Button variant="primary" type="submit">
                        Sumbit
                      </Button>
                    </div>
                </Form>
                ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      Loading... <CircularProgress />
    </Box>
  )}
          </Container>
        </Col>
      </Row>
    </Container>
   
   </>
    )
}

export default DigitizingQuote
