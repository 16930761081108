import React from 'react'
import '../../../App.css'
import CardsSection from './components/CardsSection';
import Hero from './components/Hero';
import OurPricing from './components/OurPricing';
import OurFeature from './components/OurFeature';
import Details from './components/Details';
import Portfolio from './components/Portfolio';
import WhyDigitizing from './components/WhyDigitizing';
import Testimonals from './components/Testimonals';

const Home = () => {
  return (
    <>
    <Hero/>
    <CardsSection/>
    <OurPricing/>
    <OurFeature/>
    <Details/>
    <Portfolio/>
    <WhyDigitizing/>
    <Testimonals/>

  
    </>
  )
}

export default Home