import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './userDashboard.css'

const UsrDashboard = () => {

    return (
        <>

<Container className='py-5' fluid style={{backgroundColor:"#f8f8f8"}}>
<Row className=' justify-content-center'>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/placedigitizingorder'} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-order.webp' alt=''></img>
  <span className="price">Place Digitizing Order</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={'/digitizingorderrecord'} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-rcord.webp' alt=''></img>
  <span className="price">Digitizing Order Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/digitizingquote'} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote.webp' alt=''></img>
  <span className="price">Digitizing Quote</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={'/digitizingquoterecord'} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote-record.webp' alt=''></img>
  <span className="price">Digitizing Quote Record</span></Link>
</Card>

{/* /////////////////////////////////////////////////////////////////////////////////////// */}

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/placevectororder'} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/vector-ordr.webp' alt=''></img>
  <span className="price">Place Vector Order</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={'/vectororderrecord'} className="d-block w-100 h-100">  <img className='dshh-crd-immg border' src='/assets/DashImgs/vector-order-record.webp' alt=''></img>
  <span className="price">Vector Order Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/vectorquote'} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote.webp' alt=''></img>
  <span className="price">Vector Quote</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={'/vectorquoterecord'} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote-record.webp' alt=''></img>
  <span className="price">Vector Quote Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/userprofile'} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/my-profile.webp' alt=''></img>
  <span className="price">MY PROFILE</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/placepatchorder'} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/patchorder.webp' alt=''></img>
  <span className="price">Place Patch Order</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/patchorderrecord'} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/PatchOrderRecord.webp' alt=''></img>
  <span className="price">Patch Order Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/patchquote'} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/patchquotes.webp' alt=''></img>
  <span className="price">Patch Quote</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={'/patchquoterecord'} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/PatchQUoteRecord.webp' alt=''></img>
  <span className="price">Patch Quote Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={`/invoicesCustomer`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/invoices.webp' alt=''></img>
  <span className="price">Invoice</span></Link>
</Card>

</Row>
</Container>
    </>
    )
}

export default UsrDashboard