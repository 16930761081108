import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home/Home'
import About from './components/pages/About/About'
import Patch from './components/pages/Patch/Patch'
import Digitizing from './components/pages/Digitizing/Digitizing'
import Vector from './components/pages/Vector/Vector'
import Pricing from './components/pages/Pricing/Pricing'
import Contact from './components/pages/ContactUs/Contact'
import Footer from './components/layout/Footer'
import Login_1 from './components/pages/User/Login_1';
import Register_1 from './components/pages/User/Register_1';
import PasswordReset from './components/pages/User/PasswordReset'
import Error from './components/pages/User/Error'
import ForgotPassword from './components/pages/User/ForgotPassword'
import UserProfile from './components/pages/userDashboard/profile/UserProfile';
import DigitizingOrder from './components/pages/userDashboard/digitizing/DigitizingOrder';
import DigitizingQuote from './components/pages/userDashboard/digitizing/DigitizingQuote';
import PatchOrder from './components/pages/userDashboard/patch/PatchOrder'
import PatchQuote from './components/pages/userDashboard/patch/PatchQuote'
import PatchQuoteRecord from './components/pages/userDashboard/patch/PatchQuoteRecord'
import PatchOrderRecord from './components/pages/userDashboard/patch/PatchOrderRecord'


import VectorOrder from './components/pages/userDashboard/vector/VectorOrder'
import VectorQuote from './components/pages/userDashboard/vector/VectorQuote'
import SingleRecordDetails from './components/pages/userDashboard/patch/SingleRecordDetails';
import Admindashboard from './components/pages/AdminDashboard/Admindashboard';
import Users from './components/pages/AdminDashboard/Users';
import UserProfileInAdmin from './components/pages/AdminDashboard/UserProfileInAdmin';
import UserDashInAdmin from './components/pages/AdminDashboard/UserDashInAdmin';
import TodayOrders from './components/pages/AdminDashboard/TodayOrders';
import TodayQuotes from './components/pages/AdminDashboard/TodayQuotes';
import DigitizingOrderRecords from './components/pages/userDashboard/digitizing/DigitizingOrderRecords';
import DigitizingQuoteRecords from './components/pages/userDashboard/digitizing/DigitizingQuoteRecords';
import VectorOrderRecords from './components/pages/userDashboard/vector/VectorOrderRecords';
import VectorQuoteRecords from './components/pages/userDashboard/vector/VectorQuoteRecords';
import SinglePatchQuoteDetails from './components/pages/userDashboard/patch/SinglePatchQuoteDetails';
import SingleDigitizOrderDetails from './components/pages/userDashboard/digitizing/SingleDigitizOrderDetails';
import SingleDigitizQuoteDetails from './components/pages/userDashboard/digitizing/SingleDigitizQuoteDetails';
import SingleVectorQuoteDetails from './components/pages/userDashboard/vector/SingleVectorQuoteDetails';
import SingleVectorOrderDetails from './components/pages/userDashboard/vector/SingleVectorOrderDetails';
import ReleaseDigitizingOrder from './components/pages/AdminDashboard/ReleaseDigitizingOrder';
import ReleasedOrders from './components/pages/AdminDashboard/ReleasedOrders';
import ReleaseVectorOrder from './components/pages/AdminDashboard/ReleaseVectorOrder';
import ReleasePatchOrder from './components/pages/AdminDashboard/ReleasePatchOrder';

import SinglRelVectorOrdDetail from './components/pages/AdminDashboard/SinglRelVectorOrdDetail';
import SinglRelPatchOrdDetail from './components/pages/AdminDashboard/SinglRelPatchOrdDetail';
import SinglRelDigitOrdDetail from './components/pages/AdminDashboard/SinglRelDigitOrdDetail';

import SinglRelVectorQuoteDetail from './components/pages/AdminDashboard/SinglRelVectorQuoteDetail';
import SinglRelPatchQuoteDetail from './components/pages/AdminDashboard/SinglRelPatchQuoteDetail';
import SinglRelDigitQuoteDetail from './components/pages/AdminDashboard/SinglRelDigitQuoteDetail';

import ReleaseDigitizingQuote from './components/pages/AdminDashboard/ReleaseDigitizingQuote';
import ReleaseVectorQuote from './components/pages/AdminDashboard/ReleaseVectorQuote';
import ReleasePatchQuote from './components/pages/AdminDashboard/ReleasePatchQuote';
import ReleasedQuotes from './components/pages/AdminDashboard/ReleasedQuotes';
import InvocesRecords from './components/pages/AdminDashboard/InvocesRecords';
import InvoicesCustomer from './components/pages/userDashboard/InvoicesCustomer';
import NaviBar from './components/layout/NaviBar';
import { LoginContext } from './components/ContextProvider/Context';
import { useContext, useEffect } from 'react';
import AdminNav from './components/pages/AdminDashboard/AdminNav';
import DashNav from './components/pages/userDashboard/DashNav';
import UsrDashboard from './components/pages/userDashboard/UsrDashboard';
import ScrollToTop from './components/layout/ScrollToTop';

function App() {
    //////////////////////Discord Button/////////////////////////////
    useEffect(() => {
      const loadTawkToScript = () => {
        var s1 = document.createElement("script");
        var s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/655278d7958be55aeaaf4065/1hf52g3fn';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      };
  
      loadTawkToScript();
      return () => {
        document.getElementById('tawk-to-script')?.remove();
      };
    }, []);//Discord Button End

  const { logindata } = useContext(LoginContext);
  var navbar 
  if (logindata) {
    switch (logindata.isAdmin) {
      case true:
        navbar = 'admin'
        break;
      case false:
        navbar = 'user'
        break;
    
      default:
        navbar = 'navibar'
        break;
    }
    
  } else {
    navbar = 'navibar'
  }
  return (
    <>
    <Router>
      <ScrollToTop/>
      {navbar === 'admin'? <AdminNav/>:navbar === 'user' ? <DashNav/> :navbar === 'navibar' ? <NaviBar/>: <NaviBar/>}
        
        <Routes>
          {/* ///////////////General Routes////////////////// */}
        <Route path='/' exact Component={Home}/>
        <Route path='/abouts' Component={About}/>
        <Route path='/patchs' Component={Patch}/>
        <Route path='/digitizings' Component={Digitizing}/>
        <Route path='/vectors'Component={Vector}/>
        <Route path='/pricings'Component={Pricing}/>
        <Route path='/contacts' Component={Contact}/>
        <Route path='login_1' Component={Login_1}/>
        <Route path='register_1' Component={Register_1}/>
        <Route path="*" element={<Error />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/forgotpassword/:id/:token" element={<ForgotPassword />} />

            {/* /////////////////ADMIN ROUTES///////////////// */}
            <Route path='/adminDashboard' Component={Admindashboard}/>
            <Route path='/users' Component={Users}/>
            <Route path='/userProfileInAdmin/:id/:cName' Component={UserProfileInAdmin}/>
            <Route path='/userDashboard/:id/:cName' Component={UserDashInAdmin}/>
            <Route path='/placepatchorder/:id/:cName' Component={PatchOrder} />
            <Route path='/patchquote/:id/:cName' Component={PatchQuote} />
            <Route path='/placedigitizingorder/:id/:cName' Component={DigitizingOrder} />
            <Route path='/digitizingquote/:id/:cName' Component={DigitizingQuote} />
            <Route path='/placevectororder/:id/:cName' Component={VectorOrder} />
            <Route path='/vectorquote/:id/:cName' Component={VectorQuote} />
            <Route path='/todayOrders' Component={TodayOrders}/>
            <Route path='/todayQuotes' Component={TodayQuotes}/>
            <Route path='/releasedOrders' Component={ReleasedOrders}/>
            <Route path='/releasedQuotes' Component={ReleasedQuotes}/>
            <Route path='/invoicesRecords' Component={InvocesRecords}/>
            <Route path='/invoicesCustomer' Component={InvoicesCustomer}/>
            <Route path='/invoicesCustomer/:id/:cName' Component={InvoicesCustomer}/>

            <Route path='/releaseDigitizingOrder/:id/:priceDetails/:Cname/:Cemail' Component={ReleaseDigitizingOrder}/>
            <Route path='/releaseVectorOrder/:id/:priceDetails/:Cname/:Cemail' Component={ReleaseVectorOrder}/>
            <Route path='/releasePatchOrder/:id/:priceDetails/:Cname/:Cemail' Component={ReleasePatchOrder}/>
            
            <Route path='/singlRelDigitOrdDetail/:id' Component={SinglRelDigitOrdDetail}/>
            <Route path='/singlRelVectorOrdDetail/:id' Component={SinglRelVectorOrdDetail}/>
            <Route path='/singlRelPatchOrdDetail/:id' Component={SinglRelPatchOrdDetail}/>

            <Route path='/singlRelDigitQuoteDetail/:id' Component={SinglRelDigitQuoteDetail}/>
            <Route path='/singlRelVectorQuoteDetail/:id' Component={SinglRelVectorQuoteDetail}/>
            <Route path='/singlRelPatchQuoteDetail/:id' Component={SinglRelPatchQuoteDetail}/>

            <Route path='/releaseDigitizingQuote/:id/:priceDetails/:Cname/:Cemail' Component={ReleaseDigitizingQuote}/>
            <Route path='/releaseVectorQuote/:id/:priceDetails/:Cname/:Cemail' Component={ReleaseVectorQuote}/>
            <Route path='/releasePatchQuote/:id/:priceDetails/:Cname/:Cemail' Component={ReleasePatchQuote}/>


            {/* /////////////////USER ROUTES///////////////// */}
              <Route path='/userdashboard' Component={UsrDashboard} />
              <Route path='/userprofile' Component={UserProfile} />
              <Route path='/placepatchorder' Component={PatchOrder} />
              <Route path='/patchquote' Component={PatchQuote} />
              <Route path='/placedigitizingorder' Component={DigitizingOrder} />
              <Route path='/digitizingquote' Component={DigitizingQuote} />
              <Route path='/placevectororder' Component={VectorOrder} />
              <Route path='/vectorquote' Component={VectorQuote} />
              <Route path='/digitizingorderrecord/:id/:costumerName' Component={DigitizingOrderRecords} />
              <Route path='/digitizingquoterecord/:id/:costumerName' Component={DigitizingQuoteRecords} />
              <Route path='/vectororderrecord/:id/:costumerName' Component={VectorOrderRecords} />
              <Route path='/vectorquoterecord/:id/:costumerName' Component={VectorQuoteRecords} />
              <Route path='/patchorderrecord/:id/:costumerName' Component={PatchOrderRecord} />
              <Route path='/patchquoterecord/:id/:costumerName' Component={PatchQuoteRecord} />
              
              <Route path='/digitizingorderrecord' Component={DigitizingOrderRecords} />
              <Route path='/digitizingquoterecord' Component={DigitizingQuoteRecords} />
              <Route path='/vectororderrecord' Component={VectorOrderRecords} />
              <Route path='/vectorquoterecord' Component={VectorQuoteRecords} />
              <Route path='/patchorderrecord' Component={PatchOrderRecord} />
              <Route path='/patchquoterecord' Component={PatchQuoteRecord} />

              
              <Route path='/singleRecordDetails/:id' Component={SingleRecordDetails} />
              <Route path='/singlePatchQuoteDetails/:id' Component={SinglePatchQuoteDetails} />
              <Route path='/singleDigitizingOrderDetails/:id' Component={SingleDigitizOrderDetails} />
              <Route path='/singleDigitizingQuoteDetails/:id' Component={SingleDigitizQuoteDetails} />

              <Route path='/singleVectorOrderDetails/:id' Component={SingleVectorOrderDetails} />
              <Route path='/singleVectorQuoteDetails/:id' Component={SingleVectorQuoteDetails} />
              
        </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
