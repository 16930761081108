import React, { useEffect, useContext } from 'react'
import { LoginContext } from '../../ContextProvider/Context';
import { Container, Row, Col } from 'react-bootstrap';
import './adminDash.css'

const Admindashboard = () => {
    const { logindata } = useContext(LoginContext);

    const callAdminDashboard = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/adminDashboard/summaries`, {
                method: 'GET',
                headers: {
                    // Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });

            if (!res.ok) {
                // Handle HTTP error status codes here
                throw new Error(`HTTP Error: ${res.status}`);
            }

            // const data1 = await res.json();
        } catch (err) {
            console.error(err);
            // navigate('/login_1');
        }
    };

    useEffect(() => {
        callAdminDashboard();
    }, []);
    return (
        <>

        <Container fluid className='mt-5'>
  <Row className='justify-content-center d-flex align-items-center'>
    <Col md={3} className='Dsh-box'>
      <div>
        <p>Today Order's</p>
        <p>Free: <span>02</span></p>
        <p>Paid: <span>15</span></p>
      </div>
    </Col>
    <Col md={3} className='Dsh-box'>
      <div>
        <p>This Week Order</p>
        <p>Free: <span>15</span></p>
        <p>Paid: <span>75</span></p>
      </div>
    </Col>
    <Col md={3} className='Dsh-box'>
      <div>
        <p>This Month Order's</p>
        <p>Free: <span>27</span></p>
        <p>Paid: <span>115</span></p>
      </div>
    </Col>
    <Col md={3} className='Dsh-box'>
      <div>
        <p>Total Customer's</p>
        <p>Active Cutomers: <span>27</span></p>
        <p>Account Hold: <span>01</span></p>
      </div>
    </Col>
  </Row>
</Container>   

        <Container fluid>
        <h2> {logindata.name}</h2>
            <Row>
            <Col>
                    <table className="table table-bordered text-center my-5">
                        <thead>
                        <tr >
                        
                        <th colSpan="10" >
                           
                            <p>Order Summary</p>
                        </th>
                        </tr>
                        
                        <tr className='AdminSummery'>
                        <th scope="col">Group</th>
                        <th scope="col">New Orders</th>
                        <th scope="col">Edit Orders</th>
                        <th scope="col">Revision Orders</th>
                        <th scope="col">Free Orders</th>
                        <th scope="col">Total Orders</th>
                        <th scope="col">Upload Pending</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                                <tr  className=' m-0 p-0 text-center'>
                                
                                </tr>
                    </tbody>
                    </table>
                </Col>

                <Col>
                    <table className="table table-bordered text-center my-5">
                        <thead>
                        <tr >
                        
                        <th colSpan="10" >
                           
                            <p>Order Summary</p>
                        </th>
                        </tr>
                        
                        <tr className='AdminSummery'>
                        <th scope="col">Group</th>
                        <th scope="col">New Vectors</th>
                        <th scope="col">Edit Edit</th>
                        <th scope="col">Revision Vectors</th>
                        <th scope="col">Free Vectors</th>
                        <th scope="col">Total Vectors</th>
                        <th scope="col">Upload Pending</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                                <tr  className=' m-0 p-0 text-center'>
                                
                                </tr>
                    </tbody>
                    </table>
                </Col>
            </Row>
            <Row>
            <Col>
                    <table className="table table-bordered text-center my-5">
                        <thead>
                        <tr >
                        
                        <th colSpan="10" >
                           
                            <p>Qoutes Summary</p>
                        </th>
                        </tr>
                        
                        <tr className='AdminSummery'>
                        <th scope="col">Group</th>
                        <th scope="col">New Quotes</th>
                        <th scope="col">Edit Quotes</th>
                        <th scope="col">Revision Quotes</th>
                        <th scope="col">Total Quotes</th>
                        <th scope="col">Upload Pending</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                                <tr  className=' m-0 p-0 text-center'>
                                
                                </tr>
                    </tbody>
                    </table>
                </Col>

                <Col>
                    <table className="table table-bordered text-center my-5">
                        <thead>
                        <tr >
                        
                        <th colSpan="10" >
                           
                            <p>Vector Qoutes Summary</p>
                        </th>
                        </tr>
                        
                        <tr className='AdminSummery'>
                        <th scope="col">Group</th>
                        <th scope="col">New Quotes</th>
                        <th scope="col">Edit Quotes</th>
                        <th scope="col">Revision Quotes</th>
                        <th scope="col">Total Quotes</th>
                        <th scope="col">Upload Pending</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                                <tr  className=' m-0 p-0 text-center'>
                                
                                </tr>
                    </tbody>
                    </table>
                </Col>
            </Row>
  
        
    
        </Container>
            
        </>

    )
}

export default Admindashboard
