import React, { useEffect,useContext, useState } from 'react'
import { LoginContext } from '../../ContextProvider/Context';
import { FaEye } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';


const Users = () => {
  const { logindata } = useContext(LoginContext);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);




  
  useEffect(() => {
    const callUserDashboard = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/usersList`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include"
        });
  
        if (!res.ok) {
          throw new Error(`HTTP Error: ${res.status}`);
        }
  
        const result = await res.json();
        setData(result);
      } catch (err) {
        console.error(err);
      }
    };
  
    callUserDashboard();
  }, []); 
  
      const filteredData = data.filter(item =>
        ((item.email || '').toLowerCase().includes(search.toLowerCase())) ||
        ((item.salesRep || '').toLowerCase().includes(search.toLowerCase()))
    );
    
    
    return ( 
        <>
        <table className="table table-bordered text-center my-5">

        <thead>
            <tr >
            <th colSpan="6" >
                <h2>Welcome {logindata.name}</h2>
                <p>All Customers List</p>
                <div className='float-end'>
                <label>Search</label>
                <Form.Control 
                type="text"
                placeholder="Search by Email"
                value={search}
                onChange={e => setSearch(e.target.value)}
                />
                </div>
                <Link className=' float-start btn btn-primary' to={'/register_1'}>Add User</Link>
            </th>
            </tr>
            <tr>
            <th scope="col">Ser#</th>
            <th scope="col">Detail</th>
            <th scope="col">Name</th>
            <th scope="col">Sales Rep</th>
            <th scope="col">Email</th>
            <th scope="col">Contact No</th>
            </tr>
        </thead>
        <tbody>
        {filteredData.map((item, index )=> (
                    <tr key={item._id} className=' m-0 p-0 text-center'>
                    <td>{index + 1}</td>
                    <td><Link to={`/userDashboard/${item.id}/${item.name}`}><FaEye className=' w-100'/></Link></td>
                    <td>{item.name}</td>
                    <td>{item.salesRep}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    </tr>
                    ))}
        </tbody>
        </table>
        </>
    )
}

export default Users
