import React from 'react'
import { Container } from 'react-bootstrap'
import '../Style/VectorBookNow.css'
import { Link } from 'react-router-dom'

const VectorBookNow = () => {
    return (
        <>
        <Container fluid className='text-center text-white v-Booknow '>
            <div className='v-booknow-cont' >
            <h1>Order Now</h1>
            <Link to="/vectors" className="btn btn-primary w-100 py-2 mt-3">Click here</Link>
            </div>
        </Container>
        </>
    )
}

export default VectorBookNow
