import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Carousel, Row } from 'react-bootstrap';

function DigitizingPortfolio() {
  return (
   <>
   <Container className='my-5 w-75'>
<h2 className="mb-3 secnd-hding text-center stylsh-heding">Digitizing</h2>
<Row className="d-none d-md-flex">
     <Col>
        <img src="assets/portfolio_img/Baptist.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/Bird.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/cocklogo.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/Dog.webp" alt="/" className="imag-sze my-2" />
        </Col>
    </Row>

    <Row className="d-none d-md-flex">
      <Col>
        <img src="assets/portfolio_img/ferrari.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/Horsekhusew.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/Negretelogo.webp" alt="" className="imag-sze my-2" />
        </Col>
        <Col>

        </Col>
      </Row>
      </Container>
 
  {/* Mobile View*/}
{/* First head hai */}
<Carousel className="p-4 d-md-none">
         <Carousel.Item className='mt-5'>
 <h2 className="mb-3 text-center stylsh-heding">Digitizing</h2>
         <Row className='mt-5'>
 <Col>
   <img src="assets/portfolio_img/Baptist.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
   <img src="assets/portfolio_img/Bird.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 </Row>
 <Row >
 <Col>
   <img src="assets/portfolio_img/cocklogo.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
   <img src="assets/portfolio_img/Dog.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 
 </Row>
 
         </Carousel.Item>
       {/* //////////////////////////////////////////////////////////////// */}
         <Carousel.Item className='mt-5'>
         <h2 className="mb-3 text-center stylsh-heding">Digitizing</h2>
         <Row className='mt-5'> 
 <Col>
   <img src="assets/portfolio_img/ferrari.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
   <img src="assets/portfolio_img/Horsekhusew.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>

 </Row>       

 <Row>
 <Col>
   <img src="assets/portfolio_img/Negretelogo.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
   {/* <img src="assets/portfolio_img/Rectangle 55.webp" alt="/" className="img-fluid mt-2" /> */}
   </Col>
 
 </Row>
 </Carousel.Item>
 </Carousel>
   </>
  )
}

export default DigitizingPortfolio