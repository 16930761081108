import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Context from './components/ContextProvider/Context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <Context>
    <App />
    <ToastContainer
    position="top-left"
    autoClose={500}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
    />
    </Context>
    </>
);
