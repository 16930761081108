import React, {  useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactHeader.css'
import { FaMapMarkerAlt,FaEnvelopeOpen,FaClock, FaPhone } from "react-icons/fa";
// import { FaPhoneFlip } from "react-icons/fa6";
import { Col, Row, Container, Form, FormGroup, FormLabel, FormControl} from 'react-bootstrap'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import AboutHeader from '../About/Components/AboutHeader';
const ContactHeader = () => {
 

  const [validated, setValidated] = useState(false);
  // const [error, setError] = useState('');
  const [isLoading, setIsLoading] =useState(true)
  const [formData, setFormData] = useState({
    name:'',
    email:'',
    message:'',
  })

  const contactUsUrl =`${process.env.REACT_APP_API_URL}/adminDashboard/contactUs`
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

    const handleSubmit = async (e) => {
     e.preventDefault();
     setValidated(true)
     try {
      setIsLoading(false)
        const response = await fetch(contactUsUrl, {
            method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
          setIsLoading(true)
            //  handleClose()
            //  setIsLoading(false)
            window.alert('Your messege sent successfully')
            
            // setFormData(null)
            // navigate('/patchorderrecord')
        } else {
          setIsLoading(true)
            // Handle registration error
            console.error('order failed');
            // setIsLoading(false)
        }
    } catch (error) {
      setIsLoading(true)
        console.error('Error:', error);
        // setIsLoading(false)
    }
  
  };
        
    return (
        <>
        <AboutHeader
        title='Contact Us'
         backgroundImage={`url("/assets/ContactHeader.webp"), linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%)`}
        />

        {/* <<<<<<<<<FORM & MAP>>>>>>>>> */}
    <Container className='cf-cntainer'>
            <Row className='cf-raow g-0 ' >
                <Col sm={12} md={12} lg={7} className='cf-lft'>
        {isLoading? (

                        <Form className='p-4 cf-form' noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className='mb-4 form-h-cont '>
                                <h4 className='mb-3'>Get In Touch With Us</h4>
                                <p className=''>For high quality and personal customized Logo embroidery digitizing and other such similar digitizing and vector art service, get in touch with us right away by sending us an email at </p>
                            </div>
                            <Row className='mb-4'>
                                <FormGroup className='' sm={12} md={6} as={Col} controlid='formGridName'>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl className='form-control-sm dgform-cntrol w-100' 
                                    type="text" 
                                    placeholder="*Name" 
                                    name='name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    />
                                </FormGroup>
                                <FormGroup sm={12} md={6} as={Col} controlid='formGridEmail'>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl className='form-control-sm dgform-cntrol w-100' 
                                     type="email"
                                     placeholder="*Email"
                                     name='email'
                                     value={formData.email}
                                     onChange={handleChange}
                                     required
                                     />
                                </FormGroup>
                            </Row>
                            <FormLabel>Message</FormLabel>
                            <FormControl className='form-control-sm dgform-cntrol mb-4 w-100 border-1 '
                            as="textarea" 
                            rows={3}
                            placeholder='Write Your messege'
                            name='message'
                            value={formData.message}
                            onChange={handleChange} 
                            required
                             />
                            <button className='btn btn-primary d-flex mx-auto justify-content-center ' type='submit'>Submit</button>
                            
                            <Row className='address-raow mt-4'>
                                <Col sm={12} md={3}>
                                <a href='https://maps.app.goo.gl/ttvpECGwDxiRyb4FA' className='h3 d-flex  flex-column justify-content-center align-items-center text-dark me-3 text-decoration-none'>
                                    <FaMapMarkerAlt/>
                                    <span className=' form-control-sm text-muted text-center'>Address<br/>
                                    25 Kendrick court Ancaster ON L9G5A4, CANADA</span>
                                </a>
                                
                                </Col>
                                <Col sm={12} md={3}>
                                <a href='/' className='h3 d-flex  flex-column justify-content-center align-items-center text-dark me-3 text-decoration-none'>
                                    <FaEnvelopeOpen/>
                                    <span className=' form-control-sm text-muted text-center'>Email</span>
                                </a>
                                </Col>
                                <Col sm={12} md={3}>
                                <a href="tel:3109612236" className='h3 d-flex  flex-column justify-content-center align-items-center text-dark me-3 text-decoration-none'>
                                    <FaPhone/>
                                    <span className=' form-control-sm text-muted text-center'>Call Now</span>
                                    <span className=' form-control-sm text-muted text-center'>3109612236</span>

                                    
                                </a>
                                </Col>
                                <Col sm={12} md={3}>
                                <a href='/' className='h3 d-flex  flex-column justify-content-center align-items-center text-dark me-3 text-decoration-none'>
                                    <FaClock/>
                                    <span className=' form-control-sm text-muted text-center'>24/7 Hours</span>
                                </a>
                                </Col>
                            </Row>

                        </Form>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100vh',
                            }}
                          >
                            Loading... <CircularProgress />
                          </Box>
                        )}
                </Col>
                <Col sm={12} md={12} lg={5} className='cf-rght'>
                <iframe 
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19564.49587090455!2d-80.00702644202124!3d43.20362223499012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c85f3829bc2a5%3A0xb3df5a7b0e45961a!2s25%20Kendrick%20Ct%2C%20Ancaster%2C%20ON%20L9G%205A4%2C%20Canada!5e0!3m2!1sen!2s!4v1697547363267!5m2!1sen!2s"
                title='address' allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className='cf-map'
                />
                </Col>
            </Row>

    </Container>
    </>
    )
}

export default ContactHeader
