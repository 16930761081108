import React from 'react'
import Services from './Components/Services'
import Question from './Components/Question'
import PatchPortfolio from './Components/PatchPortfolio'
import AboutHeader from '../About/Components/AboutHeader'

const Patch = () => {
  return (
    <>
    <AboutHeader
      title="Patch"
      backgroundImage={`url("/assets/patchHeader.webp"), linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%)`}
    />
    <Services/>
   <PatchPortfolio/>
    <Question/>
    </>
  )
}

export default Patch