import React, { useState,useContext } from 'react'
import '../digitizing/DigitizingOrder.css';
import {Col,Button,Row,Container,Form} from "react-bootstrap";
import { LoginContext } from '../../../ContextProvider/Context';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function VectorQuote() {
  const { id, cName } = useParams();

  const navigate = useNavigate()
  const { logindata } = useContext(LoginContext);
  const [validated, setValidated] = useState(false);
  // const [error, setError] = useState('');
const [isLoading, setIsLoading] =useState(true)


  const [formData, setFormData] = useState({
    designName: '',
    requiredFormat: 'Adobe Illustrator (AI)',
    vectorUsageOptions: 'Screen Printing',
    otherVectorUsage: '',
    requiredColor: 'RGB',
    pmsColor: '',
    additionalInstruction: '',
    instruction:'',
    ccOrderTo: '',
    superUrgent: false,
    attachments:[null],
    user:id? id : logindata.id,
    dateOrdered:Date.now(),
    costumerName:cName? cName :logindata.name,
  });
  

  // const [selectedOption, setSelectedOption] = useState('');
  const [showInput, setShowInput] = useState(false);

  const [colorType, setColorType] = useState('');
  const [pmsColor, setPmsColor] = useState('');
  const [separation, setSeparation] = useState('');

  const handleColorTypeChange = (e) => {
    setColorType(e.target.value);
    setFormData({
      ...formData,
      requiredColor: e.target.value,
      pmsColor: '',
    });
  };

  const handlePmsColorChange = (e) => {
    setPmsColor(e.target.value);
    setFormData({
      ...formData,
      pmsColor: e.target.value,
    });
  };

  const handleSelectChange = (e) => {
    // setSelectedOption(e.target.value);
    setShowInput(e.target.value === 'Other');
    setFormData({
      ...formData,
      vectorUsageOptions: e.target.value,
      otherVectorUsage: '',
    });
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);

if (totalSize > 500 * 1024 * 1024) {
  // Alert the user about the size limit
  window.alert('Total file size exceeds 200 MB');
  // Optionally, you can clear the input field
  e.target.value = null;
  return;
}
    setFormData({
      ...formData,
      attachments: files,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'separation') {
      setSeparation(value);
    } else {

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.attachments[0] === null){
      window.alert("Please insert the Attachments!")
      e.target.value = null
      return
    }
    setValidated(true);


    const form = new FormData();
      for (const key in formData) {
        if (key === 'attachments') {
          for (let i = 0; i < formData[key].length; i++) {
            form.append('files', formData[key][i]);
          }
        } else {
          form.append(key, formData[key]);
        }
      }

    
      try {
        setIsLoading(false)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/vector/quote`, {
          method: 'POST',
          // headers: {
          //   'Content-Type': 'application/json',
          // },
          // body: JSON.stringify(formData),
          body: form,
        });
        if(response.status === 421){
          console.error('files ! 421',response.status);
          setIsLoading(true)
        }

        if (response.ok) {
          setIsLoading(true)
          window.alert('success')
          setFormData(null)
          id ?  navigate(`/vectorquoterecord/${id}/:${cName}`) :
          navigate('/vectorquoterecord')
          // Registration successful, you can redirect or show a success message
          
        } else {
          setIsLoading(true)
          // Handle registration error
          console.error('Registration failed');
        }
      } catch (error) {
        setIsLoading(true)
        console.error('Error:', error);
      }
    
  };

            return (
            <>
             <Container className="outr-container p-3" fluid>
               <Row className="justify-content-center mt-5">
                  <Col xs={12} md={6}>
                    <Container className="innr-container">    
              {isLoading? ( 
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <img className='d-flex mx-auto dgOrd-frm-logo' src="assets/logo.webp" alt="" />
              <h2 className='fw-bold text text-center'>Welcome!</h2>
              <h5 className='fw-bold text text-center'>  |Place To Vector Quote|  </h5>
              <p className='mt-3 mb-3 frm-dshline'></p>

              <Row className="small">
              <Form.Group as={Col} sm={4} className="mb-2 small" controlid="formdesignName">
              <Form.Label className="fw-bold">Design Name:</Form.Label>
                        <Form.Control className='form-control-sm dgform-cntrol'
                          placeholder="Enter name"
                          required
                          type="text"
                          name="designName"
                          value={formData.designName}
                          onChange={handleInputChange}
                        />
                            <Form.Control.Feedback type="invalid">
                                Please provide a  name.
                                </Form.Control.Feedback>
                      </Form.Group>
                  </Row>

                  <Row className="mb-2 small">
                  <Form.Group as={Col} className="small" controlid="formRequiredFormat">
                  <Form.Label className="fw-bold">Required Format:</Form.Label>
                    <Form.Select className='form-select-sm dgform-cntrol'
                      name="requiredFormat"
                      value={formData.requiredFormat}
                      onChange={handleInputChange}
                      
                      style={{ width: '150px' }}
                    >
                        <option value='Adobe Illustrator (AI)'>Adobe Illustrator (AI)</option>
                        <option value='Corel Draw (CDR)'>Corel Draw (CDR)</option>
                        <option value='Encapsulated Post Script File (EPS)'>Encapsulated Post Script File (EPS)</option>
                        <option value='Adobe Acrobat (PDF)'>Adobe Acrobat (PDF)</option>
                        <option value='Photoshop (PSD)'>Photoshop (PSD)</option>
                        <option value='Other'>Other</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2 small" controlid="vectorUsageOptions">
                    <Form.Label className='fw-bold'>Vector will be used for</Form.Label>
                      <Form.Select className='form-select-sm dgform-cntrol'
                      as="select" 
                      onChange={handleSelectChange}
                      name='vectorUsageOptions' 
                      value={formData.vectorUsageOptions}
                      style={{ width: '150px' }}
                      >
                        <option value="Screen Printing">Screen Printing</option>
                        <option value="DTG (Direct To Garment)">DTG (Direct To Garment)</option>
                        <option value="Vinyl">Vinyl</option>
                        <option value="Tshirt">T-Shirt</option>
                        <option value="Heat Transfer">Heat Transfer</option>
                        <option value="Block Printing">Block Printing</option>
                        <option value="Digital Printing">Digital Printing</option>
                        <option value="Offset Printing">Offset Printing</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                    {showInput && (
                      <Form.Group as={Col} className="small" controlid="otherVectorUsageInput">
                        <Form.Label className='fw-bold'>Other Vector Usage</Form.Label>
                        <Form.Control className='form-select-sm dgform-cntrol'
                        name="otherVectorUsage"
                        value={formData.otherVectorUsage}
                        onChange={handleInputChange}
                        type="text" 
                        placeholder="Enter other vector usage" />
                      </Form.Group>
                    )}
                </Row>

              <Form.Group as={Row} className="small d-flex flex-content-column" controlid="formFabricColor">
                  <Form.Label className='fw-bold small' >Required Color?</Form.Label>
                  <Row className='small'>
                    <Col md={4} xs={4}>
                  <Form.Check
                    type="radio"
                    label="Pantone (PMS)"
                    name="requiredColor"
                    value="Pantone (PMS)"
                    onChange={handleColorTypeChange}
                  /></Col>
                  <Col md={4} xs={4}>
                  <Form.Check
                    type="radio"
                    label="One Color Solid"
                    name="requiredColor"
                    value="One Color Solid"
                    onChange={handleColorTypeChange}
                  /> </Col>
                   <Col md={4} xs={4}>
                   <Form.Check
                    type="radio"
                    label="RGB"
                    name="requiredColor"
                    value="RGB"
                    onChange={handleColorTypeChange}
                    defaultChecked
                  /></Col>
                   <Col md={4} xs={4}>
                   <Form.Check
                    type="radio"
                    label="CMYK (Process)"
                    name="requiredColor"
                    value="CMYK (Process)"
                    onChange={handleColorTypeChange}
                  /></Col>
                 <Col md={4} xs={4}>
                  <Form.Check
                    type="radio"
                    label="One Color with Halftone"
                    name="requiredColor"
                    value="One Color with Halftone"
                    onChange={handleColorTypeChange}
                  /></Col>
                  <Col md={4} xs={4}>
                   <Form.Check
                    type="radio"
                    label="Other"
                    name="requiredColor"
                    value="Other"
                    onChange={handleColorTypeChange}
                  /></Col>
                  </Row>
                </Form.Group>
                 
              {colorType === 'Pantone (PMS)' && (
                <Row className='mb-2 small'>
                  <Col xs={12} md={6}>
                    <Form.Group controlid="pmsColorInput">
                    <Form.Label className='fw-bold small'>Pantone Color:</Form.Label>
                      <Form.Control className='form-select-sm dgform-cntrol'
                      type="text"
                      placeholder="Enter Pantone color"
                      onChange={handlePmsColorChange}
                      name='pmsColor'
                      value={pmsColor}
                       />
                    </Form.Group>
                  </Col>
                </Row>
              )}

<Row className='small'>
        <Form.Group className="mb-2 small d-flex" controlid="separationRequired">
          <Col md={6} xs={6}>
            <Form.Label className='fw-bold'>Do you require separation?</Form.Label>
          </Col>
          <Col md={3} xs={3}>
            <Form.Check  
              type="radio" 
              label="Yes" 
              name="separation"  
              id="yes"
              value='Yes'
              onChange={handleInputChange}
            />
          </Col>
          <Col md={3} xs={3}>
            <Form.Check
              type="radio" 
              label="No" 
              name="separation"  
              id="no"
              value='No'
              onChange={handleInputChange}
              defaultChecked
            />
          </Col>
        </Form.Group>
      </Row>
      {separation === 'Yes' && (
        <Row className='mb-2 small'>
          <Col xs={12} md={6}>
            <Form.Group controlid="numberOfColors">
              <Form.Label className='fw-bold small'>Number of Colors:</Form.Label>
              <Form.Control 
                className='form-control-sm dgform-cntrol'
                type="text"
                placeholder="1 to 10"
                onChange={handlePmsColorChange}
                name='pmsColor'
                value={pmsColor}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      
              <Row className="mb-2 small">
                <Col xs={12} md={4}>
                  <label className='fw-bold'>Instruction:</label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group controlid="Instruction">
                    <Form.Control className=' dgform-cntrol'
                      as="textarea"
                      rows={3}
                      placeholder="Enter instructions here"
                      name="instruction"
                      value={formData.instruction}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-2 small">
                <Col xs={12} md={4}>
                  <label className='fw-bold'>Aditional Instruction:</label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group controlid="additionalInstruction">
                    <Form.Control className=' dgform-cntrol'
                      as="textarea"
                      rows={3}
                      placeholder="Enter instructions here"
                      name="additionalInstruction"
                      value={formData.additionalInstruction}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="small">
              <Form.Group as={Col} controlid="formAttachments">
                  <Form.Label className='fw-bold'>Attachments</Form.Label>
                  <Form.Control className='form-control-sm dgform-cntrol'
                    type="file"
                    name="attachments"
                    onChange={handleFileUpload}
                    accept=".jpg, .png, .webp, .emb, .dst, .exp, .pes, .cnd, .pxf, .pof,
                    .dsb, .ofm, .jpeg, .jpg, .webp, .psd, .pdf, .ai, .eps, .svg, .cdr, .heic, .zip, .rar, .tif,"
                    required
                    multiple
                  />
                  <Form.Control.Feedback type="invalid">
                                    provide a valid file.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

                <Row className='mb-2 small'>
                  <Col xs={8} md={6}>
                  <Form.Group controlid="ccOrderTo">
                    <Form.Label className='fw-bold'>CC this order to:</Form.Label>
                    <Form.Control className='form-control-sm dgform-cntrol'
                      type="email"
                      placeholder=""
                      name="ccOrderTo"
                      value={formData.ccOrderTo}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                  <Form.Group controlid="formBasicCheckbox">
                    <Form.Check className='small'
                      type="checkbox"
                      label="This order is super urgent"
                      name="superUrgent"
                      checked={formData.superUrgent}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  </Col>
                </Row>
                
                <div className="d-grid mb-3 justify-content-center">
                      <Button variant="primary" type="submit">
                        Sumbit
                      </Button>
                    </div>
             </Form>
             ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                      }}
                    >
                      Loading... <CircularProgress />
                    </Box>
                  )}
                
             </Container>
        </Col>
      </Row>
    </Container>
  
  </>
  )
}

export default VectorQuote