import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image,Navbar,Nav, Container} from 'react-bootstrap';
import LogMenu from '../../layout/LogMenu';
import { Link } from 'react-router-dom';


const AdminNav = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="dash-nav bg-body-secondary">
      <Container>
        <Link to="/adminDashboard">
        <Image
            className='logoImg'
            src="/assets/logo.webp"
            alt="Brand Logo"
            width={120}
            height={80}
        />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            
              <Link to="/adminDashboard">Dashboard</Link>
            
              <Link to="/userprofile">Profile</Link>
              <Link to="/users">Customers</Link>
              <Link to="/todayOrders">Today Orders</Link>
              <Link to="/todayQuotes">Today Quotes</Link>
              <Link to="/releasedOrders">Released Orders</Link>
              <Link to="/releasedQuotes">Released Quotes</Link>
              <Link to="/invoicesRecords">Invoices</Link>
            <LogMenu />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
        </>
    )
}

export default AdminNav
