import React, { useContext, useEffect, useState } from 'react'
import { Container ,Form} from 'react-bootstrap'
import { LoginContext } from '../../ContextProvider/Context'
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';


const InvoicesCustomer = () => {
    const { id } = useParams();
    const {logindata} = useContext(LoginContext)
    const [digitizData, setDigitizData]=useState([])
    const [patchData, setPatchData] = useState([])
    const [vectorData, setVectorData] = useState([])
    const [error, setError] = useState('')
    const [isPatchLoading, setIsPatchLoading] = useState(true)
    const [isDigiLoading, setIsDigiLoading] = useState(true)
    const [isVectorLoading, setIsVectoLoading] = useState(true)
    const Id = id ? id : logindata.id
    


    const [search, setSearch] = useState('');
    const DigitizingInvoiceUrl = `${process.env.REACT_APP_API_URL}/adminDigitRelease/invoiceRecords/${Id}`;
    const VectorInvoiceUrl = `${process.env.REACT_APP_API_URL}/adminVectorRelease/invoiceRecords/${Id}`;
    const PatchInvoiceUrl = `${process.env.REACT_APP_API_URL}/adminPatchRelease/invoiceRecords/${Id}`;

    useEffect(() => {
        const fetchData = async ()=>{
            try {
                const response = await fetch(DigitizingInvoiceUrl,{
                    method: 'GET',
                    credentials: 'include'
                },)

                if(response.status === 404){
                    throw new Error(`Record is Empty`)
                }
                if(!response.ok){
                    throw Error(`HTTP error! Status:${response.status}`)
                }
                const data = await response.json();
                setDigitizData(data)
                setIsDigiLoading(false)
            } catch (error) {
                setError(error ||"Error in catch" )
                setIsDigiLoading(false)
            }
        }
        fetchData();
    }, [DigitizingInvoiceUrl])
    

    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await fetch(VectorInvoiceUrl,{
                    method: 'GET',
                    credentials: 'include'
                })
                if(response.status === 404){
                    throw new Error(`Record is Empty`)
                }
                if(!response.ok){
                    throw Error(`HTTP error! Status:${response.status}`)
                }
                const data = await response.json();
                setVectorData(data)
                setIsVectoLoading(false)
                
            } catch (error) {
                setError(error || 'Error in catch')
                setIsVectoLoading(false)
            }
            
        }
        fetchData();
    }, [VectorInvoiceUrl])

 
    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const response = await fetch(PatchInvoiceUrl,{
                    method:'GET',
                    credentials:'include'
                })
    
                if(!response.ok){
                    throw Error(`HTTP error! Status:${response.status}`)
                }
                const data = await response.json();
                setPatchData(data)
                setIsPatchLoading(false)   
            } catch (error) {
                setError(error || 'Error in catch')
                setIsPatchLoading(false)
            }
        }
        fetchData();
    },[PatchInvoiceUrl])


    const filteredPatchData = patchData.filter(item =>
       (item.designName || '').toLowerCase().includes(search.toLowerCase())
        || (item.orderId || '').toLowerCase().includes(search.toLowerCase())
      );

      const filteredDigiData = digitizData.filter(item =>
       (item.designName || '').toLowerCase().includes(search.toLowerCase())
        || (item.orderId || '').toLowerCase().includes(search.toLowerCase())
      );

      const filteredVectorData = vectorData.filter(item =>
       (item.designName || '').toLowerCase().includes(search.toLowerCase())
        || (item.orderId || '').toLowerCase().includes(search.toLowerCase())
      );
    
  return (
      <>

          <Container>

          {/* /////////////////Digitizing Invoices///////////////////// */}
          <table className="table table-bordered text-center my-5">

              <thead>
                  <tr >
                      <th colSpan="13" >
                          <h2>Digitizing Invoices </h2>
                          <div className='float-end'>
                              <label>Search</label>
                              <Form.Control 
                                type="text"
                                placeholder="ID or DesignName"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                          </div>
                      </th>
                  </tr>
                  <tr>
                      <th scope="col">Ser#</th>
                    <th scope="col">Order Id</th>
                      <th scope="col">Design Name</th>
                      <th scope="col">PO Number</th>
                      <th scope="col">Coustumer Name</th>
                      <th scope="col">Costumer Email</th>
                      <th scope="col">Price</th>
                      <th scope="col">Invoice No.</th>
                      <th scope="col">Invoice Date.</th>


                  </tr>
              </thead>
              {isDigiLoading ? (
                    <tbody>
        <tr>
          <td colSpan="8">Loading...</td>
        </tr>
      </tbody>
                ) : error ? (
                    <tbody>
        <tr>
          <td colSpan="8">Error: {error.message}</td>
        </tr>
      </tbody>
                ) : (
              <tbody>
                {filteredDigiData.map((item,index) => (
                    <tr key={item._id} className=' m-0 p-0 text-center'>
                    
                    <td >{index + 1}</td>
                    <td >{item.orderId}</td>
                    <td >{item.designName}</td>
                    <td >{item.poNumber}</td>
                    <td >{item.costumerName}</td>
                    <td >{item.costumerEmail}</td>
                    <td className=' text-danger'>
                    {item.price === 'paid' ? (
                        <>
                        {item.amount}
                        {(() => {
                            switch (item.currency) {
                            case 'USD':
                                return <span>.$</span>;

                            case 'POUND':
                                return <span>.£</span>;

                            case 'CA$':
                                return <span>.C$</span>;

                            case 'EURO':
                                return <span>.€</span>;

                            default:
                                return null;
                            }
                        })()}
                        </>
                    ) : (
                        item.price
                    )}
                    </td>
                    <td >{item.invoiceNo}</td>
                    <td>{item.invoiceDate ? format(new Date(item.invoiceDate), 'MM/dd/yyyy h:mm a') : null}</td>
                    {/* <td><to={`/singlRelDigitOrdDetail/${item.id}`}><FaEye className=' w-100'/></</td>
                    <td>{item.releasedDate ? format(new Date(item.releasedDate), 'MM/dd/yyyy h:mm a') : null}</td>
                    <td >{item.relOrderId}</td> */}
            </tr>
            ))}
              </tbody>
              )}
          </table>

          {/* ///////////////////////////Vector Invoices/////////////////////// */}
          <table className="table table-bordered text-center my-5">

              <thead>
                  <tr >
                      <th colSpan="12" >
                          <h2>Vector Invoices </h2>
                          <div className='float-end'>
                              <label>Search</label>
                              <Form.Control 
                                type="text"
                                placeholder="ID or DesignName"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                          </div>
                      </th>
                  </tr>
                  <tr>
                       <th scope="col">Ser#</th>
                        <th scope="col">Order Id</th>
                      <th scope="col">Design Name</th>
                      <th scope="col">PO Number</th>
                      <th scope="col">Coustumer Name</th>
                      <th scope="col">Costumer Email</th>
                      <th scope="col">Price</th>
                      <th scope="col">Invoice No.</th>
                      <th scope="col">Invoice Date.</th>
                  </tr>
              </thead>
              {isVectorLoading ? (
                    <tbody>
        <tr>
          <td colSpan="8">Loading...</td>
        </tr>
      </tbody>
                ) : error ? (
                    <tbody>
        <tr>
          <td colSpan="8">Error: {error.message}</td>
        </tr>
      </tbody>
                ) : (
              <tbody>
                {filteredVectorData.map((item,index) => (
                    <tr key={item._id} className=' m-0 p-0 text-center'>
                    <td >{index + 1}</td>
                    <td >{item.orderId}</td>
                    <td >{item.designName}</td>
                    <td >{item.poNumber}</td>
                    <td >{item.costumerName}</td>
                    <td >{item.costumerEmail}</td>
                    <td className=' text-danger'>
                    {item.price === 'paid' ? (
                        <>
                        {item.amount}
                        {(() => {
                            switch (item.currency) {
                            case 'USD':
                                return <span>.$</span>;

                            case 'POUND':
                                return <span>.£</span>;

                            case 'CA$':
                                return <span>.C$</span>;

                            case 'EURO':
                                return <span>.€</span>;

                            default:
                                return null;
                            }
                        })()}
                        </>
                    ) : (
                        item.price
                    )}
                    </td>
                    <td >{item.invoiceNo}</td>
                    <td>{item.invoiceDate ? format(new Date(item.invoiceDate), 'MM/dd/yyyy h:mm a') : null}</td>
                    {/* <td><to={`/singlRelDigitOrdDetail/${item.id}`}><FaEye className=' w-100'/></</td>
                    <td>{item.releasedDate ? format(new Date(item.releasedDate), 'MM/dd/yyyy h:mm a') : null}</td>
                    <td >{item.relOrderId}</td> */}
            </tr>
            ))}
              </tbody>
              )}
          </table>

          {/* //////////////////////Patch Invoices//////////////////// */}
          <table className="table table-bordered text-center my-5">

              <thead>
                  <tr >
                      <th colSpan="12" >
                          <h2>Patch Invoices </h2>
                          <div className='float-end'>
                              <label>Search</label>
                              <Form.Control 
                                type="text"
                                placeholder="ID or DesignName"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                          </div>
                      </th>
                  </tr>
                  <tr>
                     <th scope="col">Ser#</th>
                    <th scope="col">Order Id</th>
                      <th scope="col">Design Name</th>
                      <th scope="col">PO Number</th>
                      <th scope="col">Coustumer Name</th>
                      <th scope="col">Costumer Email</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Price</th>
                      <th scope="col">Invoice No.</th>
                      <th scope="col">Invoice Date.</th>
                  </tr>
              </thead>
              {isPatchLoading ? (
                    <tbody>
        <tr>
          <td colSpan="8">Loading...</td>
        </tr>
      </tbody>
                ) : error ? (
                    <tbody>
        <tr>
          <td colSpan="8">Error: {error.message}</td>
        </tr>
      </tbody>
                ) : (
              <tbody>
                {filteredPatchData.map((item,index) => (
                    <tr key={item._id} className=' m-0 p-0 text-center'>
                    <td >{index + 1}</td>
                    <td >{item.orderId}</td>
                    <td >{item.designName}</td>
                    <td >{item.poNumber}</td>
                    <td >{item.costumerName}</td>
                    <td >{item.costumerEmail}</td>
                    <td >{item.quantity}</td>
                    <td className=' text-danger'>
                    {item.price === 'paid' ? (
                        <>
                        {item.amount}
                        {(() => {
                            switch (item.currency) {
                            case 'USD':
                                return <span>.$</span>;

                            case 'POUND':
                                return <span>.£</span>;

                            case 'CA$':
                                return <span>.C$</span>;

                            case 'EURO':
                                return <span>.€</span>;

                            default:
                                return null;
                            }
                        })()}
                        </>
                    ) : (
                        item.price
                    )}
                    </td>
                    <td >{item.invoiceNo}</td>
                    <td>{item.invoiceDate ? format(new Date(item.invoiceDate), 'MM/dd/yyyy h:mm a') : null}</td>
                    {/* <td><to={`/singlRelDigitOrdDetail/${item.id}`}><FaEye className=' w-100'/></</td>
                    <td>{item.releasedDate ? format(new Date(item.releasedDate), 'MM/dd/yyyy h:mm a') : null}</td>
                    <td >{item.relOrderId}</td> */}
            </tr>
            ))}
              </tbody>
              )}
          </table>
          
          
          </Container>
      </>
  )
}

export default InvoicesCustomer
