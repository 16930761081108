import React from 'react'
import '../Styles/AboutDetails.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap'

const AbouDetails = () => {
    return (
        <>
        <Container className='abD-cntainer'>
            <div className='abD-content'>
                <h1 className=''>About Digitizing Fox</h1>
                <p>
                Digitizing Fox is one of the top digitizing companies in the world, we provide services in several countries including USA, UK, Canada and most of the countries in Europe. We provide the best quality Patches, digitizing and vector art services to meet our customer satisfaction. Our aim is to keep low prices with high quality which is unbeatable in the market. Our Words are not just words we prove it with our work.
                </p>
                <p>
                We convert the image of a logo into specific commands which embroidery machine can read and interpret in a specific, streamlined and automated fashion. Logo digitizing can be done as appliques, cap logos, shirt logos, 2d Digitizing, 3D puff digitizing. With logo digitizing, the best part is that even minute details can be captured within the digitized design by using specific stitches like running or satin stitches.
                </p>
            </div>
        </Container>
        <Container className=' d-flex align-items-center justify-content-center'>
            <Row>
                <Col xs={12} sm={12} md={6} className='Fox-col'>
                <div className='abD-Fox'>
                    <p>
                    Our highly experienced staff can do the best quality of work and design logo of your business or personal brand into a digital embroidery design, all according to the conditions stipulated to us by the customer when submitting the digitizing order. Customers can further avail our digitizing services like puff and applique to make their logos have much more visual impact than with their just 2D/flat self. Moreover, we are proud to fulfill all the requirements of our clients and hence can provide the design in particular formats for your specific embroidery machines.
                    </p>

                </div>
                </Col>
                <Col xs={12} sm={12} md={6}>
                <div className='abD-gellry'>
                    <img
                    src='/AboutGellary.webp'
                    alt='gellary'
                    />
                </div>
                </Col>
            </Row>
           
        </Container>
        <Container className=' d-flex align-items-center justify-content-center w-100 mt-5'>
                <p className=' fs-3 w-75 text-center '>
                For high quality and personal customized Logo embroidery digitizing and other such similar digitizing and vector art service, get in touch with us right away by sending us an email at 
                </p>
        </Container>
        </>
    )
}

export default AbouDetails
