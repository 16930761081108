import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Portfolio.css';
import { Col, Container, Carousel, Row } from 'react-bootstrap';

const Portfolio = () => {
  return (
    <>
      <Container className="w-75">
        <h1 className="d-none d-md-block mt-5 mb-4 text-center ">
          Our Portfolio
        </h1>
        <h2 className="mb-3 d-none d-md-block text-center ">Patches</h2>
        <Row className="d-none d-md-flex">
          <Col>
            <a href="assets/portfolio_img\Png 6-01.webp">
              <img
                src="assets/portfolio_img\Png 6-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Png5-01.webp">
              <img
                src="assets/portfolio_img/Png5-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/IMG_2483.webp">
              <img
                src="assets/portfolio_img/IMG_2483.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Untitled-1-01.webp">
              <img
                src="assets/portfolio_img/Untitled-1-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
        </Row>

        <Row className="d-none d-md-flex">
          <Col>
            <a href="assets/portfolio_img/Untitled-2-01.webp">
              <img
                src="assets/portfolio_img/Untitled-2-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Untitled-3-01.webp">
              <img
                src="assets/portfolio_img/Untitled-3-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Untitled-4-01.webp">
              <img
                src="assets/portfolio_img/Untitled-4-01.webp"
                alt=""
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/IMG_2548.webp">
              <img
                src="assets/portfolio_img/IMG_2548.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          {/* <Col>
        <a href='assets/portfolio_img/Untitled-6-01.webp'>
        <img src="assets/portfolio_img/Untitled-6-01.webp" alt="/" className="imag-sze my-2" />
        </a>
        </Col> */}
        </Row>
      </Container>

      <Container className="my-5 w-75">
        <h2 className="mb-3 secnd-hding text-center ">Vector</h2>
        <Row className="d-none d-md-flex">
          <Col>
            <a href="assets/portfolio_img/15-01.webp">
              <img
                src="assets/portfolio_img/15-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/14-01.webp">
              <img
                src="assets/portfolio_img/14-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/13.webp">
              <img
                src="assets/portfolio_img/13.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/12.webp">
              <img
                src="assets/portfolio_img/12.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
        </Row>

        <Row className="d-none d-md-flex">
          <Col>
            <a href="assets/portfolio_img/10-01.webp">
              <img
                src="assets/portfolio_img/10-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/09-01.webp">
              <img
                src="assets/portfolio_img/09-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/08-01.webp">
              <img
                src="assets/portfolio_img/08-01.webp"
                alt=""
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/07-01.webp">
              <img
                src="assets/portfolio_img/07-01.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
         
        </Row>
      </Container>

      <Container className="my-5 w-75">
        <h2 className="mb-3 secnd-hding text-center ">Digitizing</h2>
        <Row className="d-none d-md-flex">
          <Col>
            <a href="assets/portfolio_img/Baptist.webp">
              <img
                src="assets/portfolio_img/Baptist.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Bird.webp">
              <img
                src="assets/portfolio_img/Bird.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/cocklogo.webp">
              <img
                src="assets/portfolio_img/cocklogo.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Dog.webp">
              <img
                src="assets/portfolio_img/Dog.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          
        </Row>

        <Row className="d-none d-md-flex">
          <Col>
            <a href="assets/portfolio_img/ferrari.webp">
              <img
                src="assets/portfolio_img/ferrari.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Horsekhusew.webp">
              <img
                src="assets/portfolio_img/Horsekhusew.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Negretelogo.webp">
              <img
                src="assets/portfolio_img/Negretelogo.webp"
                alt=""
                className="imag-sze my-2"
              />
            </a>
          </Col>
          <Col>
            <a href="assets/portfolio_img/Baptist.webp">
              <img
                src="assets/portfolio_img/Baptist.webp"
                alt="/"
                className="imag-sze my-2"
              />
            </a>
          </Col>
        </Row>
      </Container>

      {/* Mobile slide ye hai */}
      {/* First head hai */}
      <h1 className="text-center d-md-none ">Our Portfolio</h1>
      <Carousel className="p-4 d-md-none">
        <Carousel.Item>
          <h2 className="mb-3 text-center ">Patches</h2>
          <Row>
            <Col>
              <a href="assets/portfolio_img\Png 6-01.webp">
                <img
                  src="assets/portfolio_img\Png 6-01.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/Png5-01.webp">
                <img
                  src="assets/portfolio_img/Png5-01.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href="assets/portfolio_img/IMG_2483.webp">
                <img
                  src="assets/portfolio_img/IMG_2483.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/Untitled-1-01.webp">
                <img
                  src="assets/portfolio_img/Untitled-1-01.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
          <h2 className="mb-3 text-center ">Patches</h2>
          <Row>
            <Col>
              <a href="assets/portfolio_img/Untitled-2-01.webp">
                <img
                  src="assets/portfolio_img/Untitled-2-01.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/Untitled-3-01.webp">
                <img
                  src="assets/portfolio_img/Untitled-3-01.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href="assets/portfolio_img/Untitled-4-01.webp">
                <img
                  src="assets/portfolio_img/Untitled-4-01.webp"
                  alt=""
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/IMG_2548.webp">
                <img
                  src="assets/portfolio_img/IMG_2548.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>

      {/* 2nd head hai */}

      <Carousel className="p-4 d-md-none">
        <Carousel.Item className="mt-5">
          <h2 className="mb-3 text-center ">Vector</h2>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/15-01.webp">
                <img
                  src="assets/portfolio_img/15-01.webp"
                  alt="/"
                  className="img-fluid  mt-2"
                />
              </a>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/14-01.webp">
                <img
                  src="assets/portfolio_img/14-01.webp"
                  alt="/"
                  className="img-fluid  mt-2"
                />
              </a>
            </Col>
          </Row>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////// */}

        
        <Carousel.Item className="mt-5">
          <h2 className="mb-3 text-center ">Vector</h2>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/10-01.webp">
                <img
                  src="assets/portfolio_img/10-01.webp"
                  alt="/"
                  className="img-fluid  mt-2"
                />
              </a>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/09-01.webp">
                <img
                  src="assets/portfolio_img/09-01.webp"
                  alt="/"
                  className="img-fluid  mt-2"
                />
              </a>
            </Col>
          </Row>
          </Carousel.Item>
          {/* //////////////////////////////////////////////////////////////////// */}
          <Carousel.Item className="mt-5">
          <h2 className="mb-3 text-center ">Vector</h2>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/08-01.webp">
                <img
                  src="assets/portfolio_img/08-01.webp"
                  alt="/"
                  className="img-fluid  mt-2"
                />
              </a>
            </Col>
          </Row>
          <Row className='mt-5'>
 <Col>
 <a href='assets/portfolio_img/12.webp'>
   <img src="assets/portfolio_img/12.webp" alt="/" className="img-fluid  mt-2" />
   </a>
   </Col>
   </Row>
          </Carousel.Item>

          {/* ////////////////////////////////////////////////////////// */}

          <Carousel.Item className="mt-5">
          <h2 className="mb-3 text-center ">Vector</h2>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/07-01.webp">
                <img
                  src="assets/portfolio_img/07-01.webp"
                  alt="/"
                  className="img-fluid  mt-2"
                />
              </a>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/11-01.webp">
                <img
                  src="assets/portfolio_img/11-01.webp"
                  alt="/"
                  className="img-fluid  mt-2"
                />
              </a>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
      {/* 3rd heading slider */}
      <Carousel className="p-4 d-md-none">
        <Carousel.Item className="mt-5">
          <h2 className="mb-3 text-center ">Digitizing</h2>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/Baptist.webp">
                <img
                  src="assets/portfolio_img/Baptist.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/Bird.webp">
                <img
                  src="assets/portfolio_img/Bird.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href="assets/portfolio_img/cocklogo.webp">
                <img
                  src="assets/portfolio_img/cocklogo.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/Dog.webp">
                <img
                  src="assets/portfolio_img/Dog.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////// */}
        <Carousel.Item className="mt-5">
          <h2 className="mb-3 text-center ">Digitizing</h2>
          <Row className="mt-5">
            <Col>
              <a href="assets/portfolio_img/ferrari.webp">
                <img
                  src="assets/portfolio_img/ferrari.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/Horsekhusew.webp">
                <img
                  src="assets/portfolio_img/Horsekhusew.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>

          <Row>
            <Col>
              <a href="assets/portfolio_img/Negretelogo.webp">
                <img
                  src="assets/portfolio_img/Negretelogo.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
            <Col>
              <a href="assets/portfolio_img/Baptist.webp">
                <img
                  src="assets/portfolio_img/Baptist.webp"
                  alt="/"
                  className="img-fluid img-szee mt-2"
                />
              </a>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Portfolio;