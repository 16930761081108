import React from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import '../Style/VectotDetails.css'
const VectorDetails = () => {
    return (
        <>
        <Container className='v-d-cntainer'>
            <Row>
            <Col xs={12} md={6} className='mob-v-imag justify-content-center d-flex d-md-none'>
                <img
                    alt='gallery'
                    src='assets/portfolio_img/vec-mob.webp'
                    className='v-gellary mb-3'
                    />
            </Col>
            <Col xs={12} md={6} className='d-flex align-items-center justify-content-center'>
                    <div className='v-cont'>
                        <p>
                        We can convert your raster image into vector with excellent quality. Digitizing Fox also gives an opportunity for providing a digital look at your hand drawing and gives an exceptional outlook on your hand-drawing skill. We have a talented vector art department who understand the desires of customers and they always try to fulfill clients' requirements with complete assurance.
                        </p>
                        <a href="/" className="btn btn-primary">Order Now</a>
                    </div>
                </Col>
                <Col xs={12} md={6} className='desk-v-gel'>
                    <img
                        alt='gallery'
                        src='assets/portfolio_img/09-01.webp'
                        className='v-gellary'
                        />
                </Col>
            </Row>
        </Container>

        </>
    )
}

export default VectorDetails
