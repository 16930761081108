import React from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import '../Style/DigitizDetails.css'

const DigitizDetails = () => {
    return (
        <>
        <Container className='my-5'>
            <Row className='py-5'>
                <Col xs={12} md={6} className='mob-d-imag justify-content-center d-flex'>
                    <img
                        alt='gallery'
                        src='assets/ss.webp'
                        className='digi-img'
                        />
                </Col>
                <Col xs={12} md={6} className='desk-d-gel'>
                    <img
                        alt='gallery'
                        src='assets/digitiz-gellery.webp'
                        className='Digitiz-gellary'
                        />
                </Col>
            </Row>
                <Row className='py-5'>
                <Col xs={12} md={6}className='digi-cont'>
                    <div className='digitiz-cont'>
                        <p>
                            Embroidery design looks really attractive on hats and the left chest. It is the nature of people that they like little embroidery designs on their left chest or hats/caps. Hat embroidery designs are getting popular all over the world very promptly and mostly people want some amazing embroidery designs on their hats for looking attractive. Our company has efficient skill to digitize headwear embroidery with efficiency and we can easily digitize your logo or digitize any size of letters on caps with eye-catching looks. Our digitizers are professional on cap digitizing for embroidery and they have wide experience to give amazing embroidery designs on caps and different headwear. Cap & Hat digitizing processes are not simple tasks, you need to choose a qualified digitizing team that can provide the sharpest result of your designs with size management.
                        </p>
                        <a href="/" className="btn btn-primary">Order Now</a>
                    </div>
                </Col>
                <Col xs={12} md={6} className='desk-d-gel'>
                    <img
                        alt='gallery'
                        src='assets/zz.webp'
                        className='Digitiz-gellary'
                        />
                </Col>
                </Row>
        </Container>

        </>
    )
}

export default DigitizDetails
