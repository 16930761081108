import React, { useState,useEffect, useContext } from 'react'
import { useParams,  Link } from 'react-router-dom';
import { Col, Button, Row, Container, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../AdminDashboard/UserProfileInAdmin.css';
import { LoginContext } from '../../ContextProvider/Context';
import { format } from 'date-fns';


const UserProfileInAdmin = () => {
const { id } = useParams();
const costumerProfileUrl = `${process.env.REACT_APP_API_URL}/adminDashboard/userProfile/${id}`;
const csNoteAdd = `${process.env.REACT_APP_API_URL}/adminDashboard/csNotes/add`;
const csNoteGet = `${process.env.REACT_APP_API_URL}/adminDashboard/csNotes/get/${id}`;
// const NoteUpdateApi = `${process.env.REACT_APP_API_URL}/adminDashboard/csNotes/update`;
const NoteDeleteApi = `${process.env.REACT_APP_API_URL}/adminDashboard/csNotes/delete`;




// const navigate = useNavigate();
const { logindata } = useContext(LoginContext);
const [data, setData] = useState([]);
const [dataToUpdate, setDataToUpdate] = useState({});
const [noteDataGet, setNoteDataGet] = useState([])
const [search, setSearch] = useState('');
// const [message, setMessage] = useState('');
const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState(null);
const [userData, setUserData] = useState({}); // Change to setUserData
const [csNotesData, setCsNotesData] = useState({});



/////////////////////Costumer Profile Get///////////////////
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(costumerProfileUrl, {
        method: 'GET', // Specify the method
        headers: {
          "Content-Type": "application/json", // Set the headers
        },
        credentials: "include", // Set the credentials option
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setData(data); // Set the fetched data
      setIsLoading(false); // Update loading state
    } catch (error) {
      setError(error.message || 'An error occurred') // Handle errors
      setIsLoading(false); // Update loading state
    }
  };

  fetchData();
}, [costumerProfileUrl]); // The empty dependency array ensures this effect runs only once on mount


////////////////////////Costumer Profile Update///////////////////

useEffect(() => {
  // Check if data.user is not undefined or null before updating userData
  const newUser = data.user || {};
  setUserData(newUser);
}, [data.user]);

useEffect(() => {
  // Update dataToUpdate whenever userData changes
  setDataToUpdate({ ...userData });
}, [userData]);


const handleInputChange =(e) =>{
  const {name, value} = e.target;
  setDataToUpdate({
    ...dataToUpdate,
    [name]: value,
  })
}


const handleUpdateUser = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/users/update/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToUpdate),
        });
        // const updatedUser = await res.json();
  
        // 
        if (res.ok) {
          window.alert('updated')
          // setData(updatedUser)
          // navigate(`/userProfileInAdmin/${id}`)
        } else {
          console.error("can't update")
          // const errorMessage = await res.text();
          // setMessage(errorMessage);
        }
  
  } catch (error) {
    console.error("can't update",error.message)
  }
}
/////////////////////CS Notes Get///////////////////////////
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(csNoteGet, {
        method: 'GET', // Specify the method
        headers: {
          "Content-Type": "application/json", // Set the headers
        },
        credentials: "include", // Set the credentials option
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setNoteDataGet(data); // Set the fetched data
      setIsLoading(false); // Update loading state
    } catch (error) {
      setError(error.message || 'An error occurred') // Handle errors
      setIsLoading(false); // Update loading state
    }
  };

  fetchData();
}, [csNoteGet]); // The empty dependency array ensures this effect runs only once on mount


////////////////////CS Notes POST///////////////////////////
const handleCsChange =(e) =>{
  const {name, value} = e.target;
  setCsNotesData({
    ...csNotesData,
    adminName:logindata.name,
    costumer:data.user.name,
    user:id,
    [name]: value,
  })
}

const handleInputCsNotes = async () => {
  try {
    const res = await fetch(csNoteAdd, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: "include",

          body: JSON.stringify(csNotesData),
        });
        // const updatedUser = await res.json();
  
        // 
        if (res.ok) {
          window.alert('updated')
          // setData(updatedUser)
          // navigate(`/userProfileInAdmin/${id}`)
        } else {
          
        }
  
  } catch (error) {
    
  }
}

 const deleteNote = async(id) =>{
  try{
    const res = await fetch(`${NoteDeleteApi}/${id}`,{
      method:'DELETE',
      credentials:'include',
    },
    )
    if(!res.ok){
      // const uri = `${NoteDeleteApi}/${id}`
      
    }
    if(res.ok){
      window.location.reload();
      window.alert('deleted')
    }

  }
  catch(err){

    
  }

 }
const costumerName = userData.name
/////////////////Search Filter///////////////
// const patchRecords = data.patchOrders || []; // Ensure patchRecords is an array
const filteredData = noteDataGet.filter(item =>
  (item.addedDate || '').toLowerCase().includes(search.toLowerCase())
);
// 

    return (
  <div className='outr-container'>

      
    {isLoading ? (

      <p colSpan="8">Loading...</p>

      ) : error ? (
      <p colSpan="8">Error: {error.message}</p>
        
      ) : (
    <Container className=" gx-5 py-5" fluid>
      
      
            
      

    {/* /////////////////Costumer Profile////////////////*/}

          <Container className="innr-container bg-light w-50">
                    
                    <h2 className='text-center mt-4 fw-bold'>Customer Personal Info</h2>
                    <span className='btn btn-success mb-4 mx-auto '>{dataToUpdate.salesRep}</span>
                      
                <Form  onSubmit={handleUpdateUser}>
                    <Row className="small mb-2">
                    <Col xs={12} md={4}>
                      <Form.Group controlid="Name">
                        <Form.Label className="fw-bold">Name:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="name"
                          value={dataToUpdate.name}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group className="" controlid="CompanyName">
                        <Form.Label className="fw-bold">Company Name:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="company"
                          value={dataToUpdate.company}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group controlid="Phone">
                        <Form.Label className="fw-bold">Contact No:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="phone"
                          value={dataToUpdate.phone}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="small mb-2">
                    <Col xs={12} md={4}>
                      <Form.Group controlid="CellPhone">
                        <Form.Label className="fw-bold">Cell Phone:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="phone2"
                          value={dataToUpdate.phone2}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group controlid="Fax">
                        <Form.Label className="fw-bold">Fax:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="fax"
                          value={dataToUpdate.fax}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    
                    <Col xs={12} md={4}>
                      <Form.Group controlid="EmailFileTo">
                        <Form.Label className="fw-bold">Email File To:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="emailFileTo"
                          value={dataToUpdate.emailFileTo}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="small mb-2">
                    <Col xs={12} md={4}>
                      <Form.Group controlid="InvoiceEmail">
                        <Form.Label className="fw-bold">Invoice Email:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="invoiceEmail"
                          value={dataToUpdate.invoiceEmail}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group controlid="ShippingAddress">
                        <Form.Label className="fw-bold">Shipping Address:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="shippingAddress"
                          value={dataToUpdate.shippingAddress}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group controlid="Country">
                        <Form.Label className="fw-bold">Country:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="country"
                          value={dataToUpdate.country}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="small mb-2">
                    <Col xs={12} md={4}>
                      <Form.Group controlid="State">
                        <Form.Label className="fw-bold">State:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="state"
                          value={dataToUpdate.state}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group controlid="City">
                        <Form.Label className="fw-bold">City:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="city"
                          value={dataToUpdate.city}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group controlid="Zip">
                        <Form.Label className="fw-bold">Zip:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="zip"
                          value={dataToUpdate.zip}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="small mb-4">
                    <Col xs={12} md={4}>
                      <Form.Group controlid="Currency">
                        <Form.Label className="fw-bold">Currency:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="currency"
                          value={dataToUpdate.currency}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4} className="">
                      <Form.Group controlid="SalesRep">
                        <Form.Label className="fw-bold">Sales Rep:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="salesRep"
                          value={dataToUpdate.salesRep}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4}>
                      <Form.Group controlid="HowDidYouFindUs">
                        <Form.Label className="fw-bold">How Did You Find Us:</Form.Label>
                        <Form.Control
                          className="form-control-sm profle-inpt-dsign"
                          type="text"
                          name="howDidYouFindUs"
                          value={dataToUpdate.howDidYouFindUs}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="small">
                    <Col xs={12} md={6}>
                      <Form.Group controlid="Price Details">
                        <Form.Label className="fw-bold">Price Details:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="form-control-sm profle-inpt-dsign"
                          name="priceDetails"
                          value={dataToUpdate.priceDetails}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                      <Form.Group controlid="DigitizingNotes">
                        <Form.Label className="fw-bold">Digitizing Notes:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="form-control-sm profle-inpt-dsign"
                          name="digitizNotes"
                          value={dataToUpdate.digitizNotes}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="small mb-4">
                    <Col xs={12} md={6}>
                      <Form.Group controlid="VectorNotes">
                        <Form.Label className="fw-bold">Vector Notes:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="form-control-sm profle-inpt-dsign"
                          name="vectorNotes"
                          value={dataToUpdate.vectorNotes}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                      <Form.Group controlid="PatchNotes">
                        <Form.Label className="fw-bold">Patch Notes:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="form-control-sm profle-inpt-dsign"
                          name="patchNotes"
                          value={dataToUpdate.patchNotes}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      </Col>
                  </Row>

                      <Row className='mb-4'>
                        <Col className="justify-content-center mx-2 d-flex">
                        {/* <Button className='btn btn-primary mx-1' variant='edit'>Edit Details</Button> */}
                        <Button className='btn btn-primary mx-1' variant='update' type='submit'>Update</Button>
                        </Col>
                      </Row>

                  </Form>
          </Container>
     

        

      {/* ////////////////////Records Buttons//////////// */}

          <Container fluid className='bordr-btn-clr rounded mt-5 rounded-4'>
            <div className='mt-4 justify-content-around d-flex mb-4'>
              <Link to={`/patchorderrecord/${id}/${costumerName}`}variant='' className='btn btn-primary'>Patch Order Records</Link>
              <Link to={`/patchquoterecord/${id}/${costumerName}`}variant='' className='btn btn-primary'>Patch Quote Records</Link>
              <Link to={`/digitizingorderrecord/${id}/${costumerName}`}variant='' className='btn btn-primary'>Digitizing Order Records</Link>
              <Link to={`/digitizingquoterecord/${id}/${costumerName}`}variant='' className='btn btn-primary'>Digitizing Quote Records</Link>
              <Link to={`/vectororderrecord/${id}/${costumerName}`}variant='' className='btn btn-primary'>Vector Order Records</Link>
              <Link to={`/vectorquoterecord/${id}/${costumerName}`}variant='' className='btn btn-primary'>Vector Quote Records</Link>
            </div>
          </Container>

          {/* ///////////////////////CS Notes Section////////// */}

          <div className="container mt-5">
            <h2 className=' text-decoration-underline text-center mb-5'>CS NOTES</h2>
            <Form onSubmit={handleInputCsNotes}>
              <div className="row d-flex justify-content-center mx-5">
                <div className="col-sm-6">
                <Form.Group controlid="shippingAddress">
                      <Form.Control className=' dgform-cntrol'
                        as="textarea"
                        rows={3}
                        placeholder="Enter CS Notes here"
                        name="csNotes"
                        value={csNotesData.csNotes}
                        onChange={handleCsChange}
                      />
                      </Form.Group>
                  {/* <textarea className="form-control tble-bordr" id="yourTextarea" name="yourTextarea" rows="3"></textarea> */}
                </div>
              </div>

              <div className="row ">
                <div className="d-flex justify-content-center mx-5 col-sm-12">
                  <button className='btn btn-primary mt-3 ' type='submit'>Save</button>
                </div>
              </div>
            </Form>
          </div>

          <table className="table table-bordered tble-bordr text-center mt-5">
            <thead>
              <tr>
                <th colSpan="10" >

                  <div className='float-end'>
                    <label>Search</label>
                    <Form.Control 
                      type="text"
                      placeholder="ID or DesignName"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th scope="col">Date/Time</th>
                <th scope="col-" className=' col-2'>Admin User</th>
                <th scope="col">CS Notes</th>
                <th scope="col" className='col-2'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map(item => (
                <tr key={item._id} className=' m-0 p-0 text-center'>
                  <td>{item.addedDate ? format(new Date(item.addedDate), 'MM/dd/yyyy h:mm a') : null}</td>
                  <td>{item.adminName}</td>
                  <td>{item.csNotes}</td>
                  <td><button onClick={() => deleteNote(item._id)}>Delete</button></td>
                </tr>
              ))}
            </tbody>
          </table>
  </Container> 
      )}
  </div>
  )
}

export default UserProfileInAdmin
