import React from 'react'
import '../styles/Details.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Details = () => {
  return (

    <Container className='detail-container py-5'>
      <Row className='roaw'>
        <Col xs={12} md={6} className=' d-sm-grid'>
      {/* <span className='h2 text-center d-md-none mb-3'>Patches</span><br /> */}
      <Link to={'/patches'}className='h2 text-center d-md-none mb-3 '>Patches</Link><br/>

          <img src='assets/patchHome.webp' className='img-fluid' alt='Patches' />
        </Col>
        <Col xs={12} md={6}>
          <div className='rght-side'>
      {/* <span className='h2 text-center d-none d-md-block '>Patches</span><br /> */}
      <Link to={'/patches'}className='h2 text-center d-none d-md-block '>Patches</Link><br/>
            <p className='d-none d-md-block'>At Digitizing Fox, quality of the patches and our customer’s satisfaction are our foremost concern.<br /><br />
              Our talented and well-trained team of embroidery artists, with years of experience in developing award-winning designs, will assist you in creating a beautiful patch that will be worn with pride.
            </p><br />
            {/* <a href="/" className="d-none d-md-block btn btn-primary mx-auto">Order Now!</a> */}
            <Link to={'/login_1'}className="d-none d-md-block mx-auto btn btn-primary">
            Order Now
          </Link>
          </div>
          {/* <a href="/" className="d-md-none btn btn-primary">Order Now!</a> */}
          <Link to={'/login_1'}className="d-md-none btn btn-primary">
            Order Now
          </Link>
        </Col>
      </Row>
      
      <Row className='roaw-revrs mt-5'>     
        <Col xs={12} md={6} className='order-md-2 d-sm-grid'>
      {/* <span className='h2 text-center d-md-none mb-3'>Digitizing</span> <br/> */}
      <Link to={'/digitizings'}className='h2 text-center d-md-none mb-3 '>Digitizing</Link><br/>

          <img src='assets/digiHome.webp' className='img-fluid' alt='Digitizing' />
        </Col>
        <Col xs={12} md={6} className='order-md-1'>
          <div className='rght-side'>
            {/* <span className='h2 text-center d-none d-md-block '>Digitizing</span><br /> */}
            <Link to={'/digitizings'}className='h2 text-center d-none d-md-block '>Digitizing</Link><br/>
            <p className='d-none d-md-block'>
              Embroidery design looks really attractive on hats and the left chest. It is the nature of people that they like little embroidery designs on their left chest or hats/caps. Hat embroidery designs are getting popular all over the world very promptly and mostly people want some amazing embroidery designs on their hats for looking attractive. Our company has an efficient skill to digitize headwear embroidery with efficiency and we can easily digitize your logo or digitize any size of letters on caps with eye-catching looks.
            </p><br />
            {/* <a href="/" className="d-none d-md-block mx-auto btn btn-primary">Order Now!</a> */}
            <Link to={'/login_1'}className="d-none d-md-block mx-auto btn btn-primary">
            Order Now
          </Link>
            
          </div>
          {/* <a href="/" className="d-md-none btn btn-primary">Order Now!</a> */}
          <Link to={'/login_1'}className="d-md-none btn btn-primary">
            Order Now
          </Link>
        </Col>
      </Row>
      <Row className='roaw mt-5'>
        <Col xs={12} md={6} className=' d-sm-grid'>
        {/* <span className='h1 text-center d-md-none mb-3'>Vector</span><br/> */}
      <Link to={'/vectors'}className='h2 text-center d-md-none mb-3 '>Vector</Link><br/>

          <img src='assets/vector.webp' className='img-fluid' alt='Vector' />
        </Col>
        <Col xs={12} md={6}>
          <div className='rght-side'>
            <Link to={'/vectors'}className='h2 text-center d-none d-md-block '>Vector</Link><br/>
            {/* <span className='h2 text-center d-none d-md-block '>Vector</span><br /> */}
            <p className='d-none d-md-block'>
              We can convert your raster image into vector with excellent quality. Digitizing Fox also gives an opportunity for providing a digital look at your hand drawing and gives an exceptional outlook on your hand-drawing skill. We have a talented vector art department who understand the desires of customers and they always try to fulfill clients' requirements with complete assurance.
            </p><br />
            {/* <a href="/" className="d-none d-md-block mx-auto btn btn-primary">Order Now!</a> */}
            <Link to={'/login_1'}className="d-none d-md-block mx-auto btn btn-primary">
            Order Now
          </Link>
          </div>
          {/* <a href="/" className="d-md-none btn btn-primary">Order Now!</a> */}
          <Link to={'/login_1'}className="d-md-none btn btn-primary">
            Order Now
          </Link>
        </Col>
      </Row>
    </Container>

  )
}

export default Details