import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Image } from 'react-bootstrap';
import { Link} from 'react-router-dom';
import './NaviBar.css'
import '../../App.css'
import LogMenu from './LogMenu';


function NaviBar() {

  return (
          <Navbar expand="lg" className='nav-container'>
              <Container>
                  <Link to="/">
                      <Image
                          className='logoImg'
                          src="/assets/logo.webp"
                          alt="Brand Logo"
                          width={120}
                          height={80}
                      />
                  </Link>
                  <Navbar.Toggle className=' border-0' />
                  <Navbar.Collapse>
                      <Nav
                          className="ms-auto my-2 my-lg-0 nav-links mob-nav-epend"
                      >
                          <Link to="/">Home</Link>
                          <Link to="/abouts">About Us</Link>
                          <Link to="/patchs">Patch</Link>
                          <Link to="/digitizings">Digitizing</Link>
                          <Link to="/vectors">Vector</Link>
                          <Link to="/pricings">Pricing</Link>
                          <Link to="/contacts">Contact Us</Link>

                      </Nav>
                      <LogMenu/>
                      
                  </Navbar.Collapse>
              </Container>
          </Navbar>
  );
}

export default NaviBar;