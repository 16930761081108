import React from 'react'
import { Container,Card, CardGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style/Services.css'
const Services = () => {
    return (
      <div>
        <Container className='my-5'>

          <CardGroup>

            <Card className="crd-patch">
              <div className='crd-patch-img'>
                <Card.Img variant="top" src="/assets/delivery.webp" />
              </div>
              <Card.Body>
                <div className='crd-patch-tit'>
                  <Card.Title className='text-white btn btn-primary'>Fast Delivery</Card.Title>
                </div>
                <div className='crd-patch-body'>
                  <Card.Text>
                    We will ship your patches in ASAP within 8-12 days.
                  </Card.Text>
                </div>

              </Card.Body>
            </Card>

            <Card className="crd-patch">
              <div className='crd-patch-img'>
                <Card.Img variant="top" src="/assets/freeSample.webp" />
              </div>
              <Card.Body>
                <div className='crd-patch-tit'>
                  <Card.Title className='text-white btn btn-primary'>Free Sample</Card.Title>
                </div>
                <div className='crd-patch-body'>
                  <Card.Text>
                    We will provide you free, no obligation image of the actual sample.
                  </Card.Text>
                </div>

              </Card.Body>
            </Card>

            <Card className="crd-patch">
              <div className='crd-patch-img'>
                <Card.Img variant="top" src="/assets/greatPricing.webp" />
              </div>
              <Card.Body>
                <div className='crd-patch-tit'>
                  <Card.Title className='text-white btn btn-primary'>Great Pricing</Card.Title>
                </div>
                <div className='crd-patch-body'>
                  <Card.Text>
                    We offer the most competitive prices in the market.
                  </Card.Text>

                </div>
              </Card.Body>
            </Card>

            <Card className="crd-patch">
              <div className='crd-patch-img'>
                <Card.Img variant="top" src="/assets/qa.webp" />
              </div>
              <Card.Body>
                <div className='crd-patch-tit'>
                  <Card.Title className='text-white btn btn-primary'>Quality Guaranteed</Card.Title>
                </div>
                <div className='crd-patch-body'>
                  <Card.Text>
                    We will provide you with industry's best quality patches.
                  </Card.Text>

                </div>
              </Card.Body>
            </Card>

          </CardGroup>
        </Container>
      </div>
    )
}

export default Services
