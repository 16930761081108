import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, useNavigate} from 'react-router-dom';
import { useContext,useState} from 'react';
import { LoginContext } from '../ContextProvider/Context';
import axios from 'axios';
import Cookies from 'js-cookie'
import { Avatar } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function LogMenu() {
    const {logindata,setLoginData} = useContext(LoginContext)
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const goDash = () => {
        if(logindata.isAdmin){
            navigate("/adminDashboard")
        }
        else
        navigate("/userdashboard")
    }

    const goError = () => {
        navigate("*")
    }
    const goLogin =()=>{
        navigate('/login_1')
    }
    ///////////////////LogOut/////////////////////
    const handleLogout = async () => {
        try {
            // Send logout request to the server
            await axios.post(`${process.env.REACT_APP_API_URL}/users/logout`);

            Cookies.remove('jwtoken');
            setLoginData(false)
            navigate('/')
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    return (
                    // <Avatar style={{ background: "blue" }} onClick={handleClick} />

        <div>
            {
                logindata ? ( <Avatar style={{ background: "salmon", fontWeight: "bold", textTransform: "capitalize" }} onClick={handleClick}>{logindata.name[0].toUpperCase()}</Avatar>
                    ):(
                    <>    
                    <Link to={'/register_1'} className='LR'>Register</Link>
                    <Link to={'/login_1'} className='LR'>Login</Link>
                    </>
                    )

            }
            
            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {
                logindata ? [(
                
                    <div key={Menu.id=0}><MenuItem onClick={() => {
                            goDash();
                            handleClose();
                        } }>Dashboard</MenuItem><MenuItem onClick={() => {
                            handleLogout();
                            handleClose();
                        } }>Logout</MenuItem></div>
                    
                )] : [(
                    
                    <div key={Menu.id=1}><MenuItem onClick={() => {
                            goError()
                            goLogin()
                            handleClose()
                        }}>Login</MenuItem></div>
                    
                )]
            }

        </Menu>
        </div>
    )
}

export default LogMenu
