import React, { useState,useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Container,Row,Col} from 'react-bootstrap';
import { FaEye, FaDownload } from "react-icons/fa6";



const SinglRelVectorOrdDetail = () => {
    const { id } = useParams();
    const productId = id


  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);

  const apiUrl = `${process.env.REACT_APP_API_URL}/adminVectorRelease/singlRelVectorOrdDetail/${productId}`;

  useEffect(() => {
    fetch(apiUrl,({
      method:'GET',
      credentials:'include'
    }))
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setData(data); // Set the fetched data
        setIsLoading(false); // Update loading state
      })
      .catch((error) => {
        setError(error); // Handle errors
        setIsLoading(false); // Update loading state
      });
  }, [apiUrl]); // The empty dependency array ensures this effect runs only once on mount

  const downloadFile = (fileUrl,filenameParts) => {
      // Create a blob from the file URL or data
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          // Create a temporary URL for the blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a virtual link element
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `${filenameParts[0]}.${filenameParts[1]}`; // Specify the file name
  
          // Append the link to the document
          document.body.appendChild(link);
  
          // Trigger a click on the link to initiate the download
          link.click();
  
          // Remove the link from the document
          document.body.removeChild(link);
        });
    };

    return (
        <>
        
        <Container  className='innr-continer w-50 bg-light my-5 p-3'>
              
              <h3 className='fw-bold text text-center mt-2'>Vector Released Order Details</h3>
        <h5 className='fw-bold text text-center '>  Released No: <span className=' text-danger fw-normal fs-6'>{}</span>  </h5>
                
                <p className='mt-3 mb-3 frm-dashline'></p>
      
                {isLoading ? (

                <p colSpan="8">Loading...</p>

                ) : error ? (
                <p colSpan="8">Error: {error.message}</p>
                  
                ) : (
         <> 
        {data.map((item) => (
          <>
            <Row key={item.id}>
              <Col md={4}>
                <p><strong>OrderId:</strong> {item.orderId}</p>
              </Col>
              <Col md={4}>
              <p><strong>Status:</strong> {item.status}</p>
              </Col>
              <Col md={4}>
                <p><strong>Ordered Date:</strong> {item.dateOrdered}</p>
              </Col>
              <Col md={4}>
                <p><strong>costumer Name:</strong> {item.costumerName}</p>
              </Col>
            </Row>

            <Row key={item.id}>
              
              <Col md={4}>
                <p><strong>CostumerEmail:</strong> {item.costumerEmail}</p>
              </Col>
              <Col md={4}>
                <p><strong>Price Details:</strong> {item.priceDetails}</p>
              </Col>
              <Col md={4}>
                <p><strong>Price:</strong>
                <span className=' ms-3 text-danger'>
                    {item.price === 'paid' ? (
                        <>
                        {item.amount}
                        {(() => {
                            switch (item.currency) {
                            case 'USD':
                                return <span>.$</span>;

                            case 'POUND':
                                return <span>.£</span>;

                            case 'CA$':
                                return <span>.C$</span>;

                            case 'EURO':
                                return <span>.€</span>;

                            default:
                                return null;
                            }
                        })()}
                        </>
                    ) : (
                        item.price
                    )}
                    </span>
                </p>
              </Col>
            </Row>

            <Row key={item.id}>
              <Col md={4}>
                <p><strong>Design Name:</strong> {item.designName}</p>
              </Col>
              <Col md={4}>
                <p><strong>PO No:</strong> {item.poNumber}</p>
              </Col>
              <Col md={4}>
                <p><strong>Rrequired Format:</strong> {item.requiredFormat}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
                <p><strong>Vector usage:</strong> {item.vectorUsageOptions}</p>
              </Col>
              <Col md={4}>
                <p><strong>Vectot usage other:</strong> {item.otherVectorUsage}</p>
              </Col>
              <Col md={4}>
                <p><strong>Required colors:</strong> {item.requiredColor}</p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <p><strong>Pantone color (PMS):</strong> {item.pmsColor}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
              <p><strong>Instruction:</strong> {item.instruction}</p>
              </Col>
              <Col md={4}>
              <p><strong>Additional Instruction:</strong> {item.additionalInstruction}</p>
              </Col>
            </Row>

            <Row>
              
              <Col md={4}>
              <p><strong>CC To::</strong> {item.ccOrderTo}</p>
              </Col>
              <Col md={4}>
              <p><strong>Super Urgent:</strong> {item.superUrgent ? <span>yes</span> : <span>No</span>}</p>
              </Col>
            </Row>

            <Row>
            <p><strong>Files:</strong></p>
              <Col md={12}>
              {item.attachments.map((urlfile,index)=>{
                const segments = urlfile.split('/');
                const fileEx = segments[segments.length - 1];
                const filenameParts = fileEx.split('.');
                return(
                <>
                <div className=' border row d-flex flex-row  align-items-center justify-content-center'>
                <p className='  col-8 '>{index + 1}_{`${filenameParts[0]}.${filenameParts[1]}`}</p>
                  <Link className=' col-2 mx-auto ' to={urlfile}><FaEye className='fs-4' /></Link> 
                  <FaDownload className=' link-success  mx-auto p-0 col-2 fs-4' onClick={()=> downloadFile(urlfile,filenameParts)} />
                
                </div>
                </>)
              })}
              </Col>
            </Row>
            
            <p className='mt-3 mb-3 frm-dashline'></p>
            <Row className=' d-flex text-center justify-content-center'>
              <Col md={6} className=' text-start'>
              <p><strong>Option A</strong></p>
              <p><strong>Width:</strong> {item.widthA}<span className=' ms-2 text-primary'>.{item.measureScaleA}</span></p>
              <p><strong>Height:</strong> {item.heightA}<span className=' ms-2 text-primary'>.{item.measureScaleA}</span></p>
              <p><strong>Released Files:</strong></p>
              {item.attachReleaseA.map((urlfile,index)=>{
                const segments = urlfile.split('/');
                const fileEx = segments[segments.length - 1];
                const filenameParts = fileEx.split('.');
                return(
                <>
                <div className=' border row d-flex flex-row  align-items-center justify-content-center'>
                <p className='  col-8 '>{index + 1}_{`${filenameParts[0]}.${filenameParts[1]}`}</p>
                  <Link className=' col-2 mx-auto ' to={urlfile}><FaEye className='fs-4' /></Link> 
                  <FaDownload className=' link-success  mx-auto p-0 col-2 fs-4' onClick={()=> downloadFile(urlfile,filenameParts)} />
                </div>
                </>)
              })}
              </Col>
              {item.attachReleaseB ?
              <Col md={6} className=' text-start'>
              <p><strong>Option B</strong></p>
              <p><strong>Width:</strong> {item.widthB}<span className=' ms-2 text-primary'>.{item.measureScaleB}</span></p>
              <p><strong>Height:</strong> {item.heightB}<span className=' ms-2 text-primary'>.{item.measureScaleB}</span></p>
              <p><strong>Released Files:</strong></p>
              <Col md={12}>
              {item.attachReleaseB.map((urlfile,index)=>{
                const segments = urlfile.split('/');
                const fileEx = segments[segments.length - 1];
                const filenameParts = fileEx.split('.');
                return(
                <>
                <div className=' border row d-flex flex-row  align-items-center justify-content-center'>
                <p className='  col-8 '>{index + 1}_{`${filenameParts[0]}.${filenameParts[1]}`}</p>
                  <Link className=' col-2 mx-auto ' to={urlfile}><FaEye className='fs-4' /></Link> 
                  <FaDownload className=' link-success  mx-auto p-0 col-2 fs-4' onClick={()=> downloadFile(urlfile,filenameParts)} />
                </div>
                </>)
              })}
              </Col>
              </Col>
              : <span>Option B isn't Selected</span>
              }
            </Row>
            </>
        ))}
      </>
      )}
      
        </Container>
        </>
    )
}

export default SinglRelVectorOrdDetail
