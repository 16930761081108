import React, { useState,useContext,useEffect } from 'react'
import { useParams ,useNavigate, Link } from 'react-router-dom';
import { Container, Form, Col, Row, Modal, Button } from 'react-bootstrap';
import { LoginContext } from '../../ContextProvider/Context';
import '../../../App.css'
import { FaEye, FaDownload } from "react-icons/fa6";



const ReleaseDigitizingOrder = () => {
  const navigate = useNavigate()

const { id, priceDetails, Cname, Cemail } = useParams();
const { logindata } = useContext(LoginContext);
const [orderDetails, setOrderDetails] = useState();
const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState(null);
const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

    //////////////////Release Order/////////////////////////////////
const [validated, setValidated] = useState(false);
const [price, setPrice] = useState('paid');
// const [amount, setAmount] = useState('');
const [currency, setCurrency] = useState('USD');

const [formData, setFormData] = useState({
  price:'paid',
  amount:'',
  currency:'USD',
  widthA:'',
  heightA:'',
  measureScaleA:'inches',
  widthB:'',
  heightB:'',
  measureScaleB:'inches',
  attachReleaseA:[],
  attachReleaseB:[],
  status:'Processing',
  adminUser: logindata.id,
  costumerId: '',
  priceDetails:priceDetails,
  costumerName: Cname,
  costumerEmail: Cemail,
  releasedDate: Date.now(),
})

const releaseOrderApi = `${process.env.REACT_APP_API_URL}/adminDigitRelease/order`;

const handlePriceChange = (event) => {
  const newPrice = event.target.value;
  setPrice(newPrice);
  setFormData({
    ...formData,
    price: newPrice,
    amount: newPrice === 'free' ? '' : formData.amount,
    currency: newPrice === 'free' ? 'USD' : formData.currency,
  });
};

const handleAmountChange = (event) => {
  // setAmount(event.target.value);
  setFormData({
    ...formData,
    amount: event.target.value,
  });
};

const handleCurrencyChange = (event) => {
  setCurrency(event.target.value);
  setFormData({
    ...formData,
    currency: event.target.value,
  });
};

const handleFileUpload = (e) => {
  const { name, files } = e.target;
  if (name === 'attachReleaseA' || name === 'attachReleaseB') {
    const fileList = Array.from(files);
    /////////
    let totalSize = 0;
    for (const file of fileList) {
      totalSize += file.size; // Accumulate the total size of all files
      if (totalSize > 500 * 1024 * 1024) { // Check if total size exceeds 200 MB
        alert('Total file size exceeds 500 MB limit.');
        e.target.value = null
        return; // Stop further processing
      }
    }
    ////////////
    setFormData(prevState => ({
      ...prevState,
      [name]: [...prevState[name], ...fileList]
    }));
  }
};

const handleChange = (e) => {
  const { name, value, type, checked } = e.target;
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
  }

const validateing = async (e) => {
  e.preventDefault();
  const frm = e.currentTarget;
  if (frm.checkValidity() === false) {
    e.preventDefault();
    e.stopPropagation();
    

  }
  setValidated(true);
  if(formData.attachReleaseA[0] === undefined){
    window.alert("Please insert the Attachments!")
    return
  }else{
    handleShow() 
  }
}

const handleSubmit = async (e) => {
  setIsLoading(true)
  

    const form = new FormData();
    for (const key in formData) {
      if (key === 'attachReleaseA' || key === 'attachReleaseB') {
        formData[key].forEach(file => {
          form.append(key, file);
        });
      } else {
        form.append(key, formData[key]);
      }
    }
    try {
        const response = await fetch(releaseOrderApi, {
            method: 'POST',
            body: form,
            credentials:'include'
        });

        if (response.ok) {
             handleClose()
             setIsLoading(false)
            window.alert('Your order has been Released successfully')
            navigate('/releasedOrders')
            // setFormData(null)
            // navigate('/patchorderrecord')
        } else {
            // Handle registration error
            console.error('order failed');
            setIsLoading(false)
        }
    } catch (error) {
        console.error('Error:', error);
        setIsLoading(false)
    }
};
    
////////////////////////
  const orderDetailsApi = `${process.env.REACT_APP_API_URL}/digitizing/orderdetails/${id}`;
  useEffect(() => {
  const fetchDataFromApi1 = async () => {
    try {
      const response1 = await fetch(orderDetailsApi);
      const data1 = await response1.json();
      if (!response1.ok) {
        throw new Error('Network response was not ok');
      }
      setFormData({
        ...formData,
        designName:data1[0].designName,
        poNumber: data1[0].poNumber ? data1[0].poNumber : '',
        requiredFormat: data1[0].requiredFormat,
        otherFormat: data1[0].otherFormat,
        fabric: data1[0].fabric,
        placement: data1[0].placement,
        width: data1[0].width? data1[0].width: '',
        height:data1[0].height? data1[0].height: '',
        measureScale: data1[0].measureScale,
        numOfColor: data1[0].numOfColor ? data1[0].numOfColor: '',
        fabricColor: data1[0].fabricColor,
        colorBlending: data1[0].colorBlending,
        backgroundFill: data1[0].backgroundFill,
        pictureEmbroidery: data1[0].pictureEmbroidery,
        instruction: data1[0].instruction,
        additionalInstruction: data1[0].additionalInstruction,
        ccOrderTo: data1[0].ccOrderTo,
        superUrgent: data1[0].superUrgent,
        attachments: data1[0].attachments,
        costumerId: data1[0].user,
        orderId: data1[0].orderId,
        dateOrdered: data1[0].dateOrdered,

        

      })
      setOrderDetails(data1);
    } catch (error) {
      setError(error); // Handle errors
    } finally {
      setIsLoading(false); // Update loading state
    }
  };

  

    // Trigger the first API call when the component mounts
    fetchDataFromApi1();
  }, [orderDetailsApi]);

  
  
  const downloadFile = (fileUrl,filenameParts) => {
      // Create a blob from the file URL or data
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          // Create a temporary URL for the blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a virtual link element
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `${filenameParts[0]}.${filenameParts[1]}`; // Specify the file name
  
          // Append the link to the document
          document.body.appendChild(link);
  
          // Trigger a click on the link to initiate the download
          link.click();
  
          // Remove the link from the document
          document.body.removeChild(link);
        });
    };


  return (
    <>
    <Container>

      {/* /////////////////////Release Form/////////////////////////// */}
      <Container className="innr-container w-75 bg-light my-5 p-3">
      <h3 className='fw-bold text text-center mt-2'>Release Digitizing Order</h3>
        <h5 className='fw-bold text text-center'> Order ID: {formData.orderId}  </h5>

                <p className='mt-3 mb-3 frm-dashline'></p>
      {isLoading ? (

          <p colSpan="8">Loading...</p>
      
          ) : error ? (
          <p colSpan="8">Error: {error.message}</p>
            
      ) : (
        
        <>
       <Row className='p-3'>
        {orderDetails.map((item) => (
          <Col md={12} key={item.id}>
            <Row>
              <Col md={4}>
                <p><strong>Design Name:</strong> {item.designName}</p>
              </Col>
              <Col md={4}>
                <p><strong>PO No:</strong> {item.poNumber}</p>
              </Col>
              <Col md={4}>
                <p><strong>Rrequired Format:</strong> {item.requiredFormat}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
                <p><strong>Other Format:</strong> {item.otherFormat}</p>
              </Col>
              <Col md={4}>
                <p><strong>Fabric:</strong> {item.fabric}</p>
              </Col>
              <Col md={4}>
                <p><strong>Placement:</strong> {item.placement}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
                <p><strong>Width:</strong> {item.width}</p>
              </Col>
              <Col md={4}>
                <p><strong>Height:</strong> {item.height}</p>
              </Col>
              <Col md={4}>
                <p><strong>Scale:</strong> {item.measureScale}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
                <p><strong>Numbers of Colors:</strong> {item.numOfColor}</p>
              </Col>
              <Col md={4}>
                <p><strong>Fabric Color:</strong> {item.fabricColor}</p>
              </Col>
              <Col md={4}>
                <p><strong>Color Blending:</strong> {item.colorBlending}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
              <p><strong>Background Fill:</strong> {item.backgroundFill}</p>
              </Col>
              <Col md={4}>
                <p><strong>Picture Embroidery:</strong> {item.pictureEmbroidery}</p>
              </Col>
              <Col md={4}>
              <p><strong>Instruction:</strong> {item.instruction}</p>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
              <p><strong>Additional Instruction:</strong> {item.additionalInstruction}</p>
              </Col>
              <Col md={4}>
              <p><strong>CC To::</strong> {item.ccOrderTo}</p>
              </Col>
              <Col md={4}>
              <p><strong>Super Urgent:</strong> {item.superUrgent ? <span>yes</span> : <span>No</span>}</p>
              </Col>
            </Row>

            <Row>
            <p><strong>Files:</strong></p>
              <Col md={12}>
              {item.attachments.map((urlfile,index)=>{
                const segments = urlfile.split('/');
                const fileEx = segments[segments.length - 1];
                const filenameParts = fileEx.split('.');
                return(
                <>
                <div className=' border row d-flex flex-row  align-items-center justify-content-center'>
                <p className='  col-8 '>{index + 1}_{`${filenameParts[0]}.${filenameParts[1]}`}</p>
                  <Link className=' col-2 mx-auto ' to={urlfile}><FaEye className='fs-4' /></Link> 
                  <FaDownload className=' link-success  mx-auto p-0 col-2 fs-4' onClick={()=> downloadFile(urlfile,filenameParts)} />
                
                </div>
                </>)
              })}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      
        <p className='mt-3 mb-3 frm-dshline'></p>
        

    <Form noValidate validated={validated} onSubmit={validateing}> 
      <Row>
      <Col md={6} xs={12} className="d-flex align-items-center">
        <Form.Group as={Col} className="mb-2 small d-flex align-items-center" controlid="formPlacement">
          <Form.Label className='h6  me-3'>Price details:</Form.Label>
          <Form.Control className='form-select-sm dgform-cntrol w-auto h-auto'
                      as="textarea"
                      rows={2}
                      value={priceDetails}
                      disabled
                    />
        </Form.Group>
      </Col>

      <Col md={6} xs={12} className="d-flex align-items-center">
        <Form.Group as={Col} className="mb-2 small d-flex align-items-center" controlid="formPlacement">
          <Form.Label className='h6  me-5'>Status :</Form.Label>
          <Form.Select className='form-select-sm dgform-cntrol w-auto'
              name="status"
              value={formData.status}
              onChange={handleChange}

          >
              <option value='Processing'>Processing</option>
              <option value='Ready'>Ready</option>
              <option value='Account hold'>Account hold</option>
              <option value='Cancel'> Cancel</option>
              <option value='Dispatched'> Dispatched</option>

          </Form.Select>
        </Form.Group>
      </Col>  
    </Row>
   
    <Row className='py-4'>
      <Col md={6} xs={12} className="d-flex align-items-center">
      <Form.Label className='h6  me-5'>Price :</Form.Label>
        <Form.Label className='me-2 ms-3'>
          <Form.Control 
            className='me-1 form-select-sm dgform-cntrol w-auto'
            type="radio"
            value="free"
            checked={formData.price === 'free'}
            onChange={handlePriceChange}
          />Free
          </Form.Label>
        
        <Form.Label>
        <Form.Control 
          className='me-1 form-select-sm dgform-cntrol w-auto'
          type="radio"
          value="paid"
          checked={formData.price === 'paid'}
          defaultChecked
          onChange={handlePriceChange}
        />Paid</Form.Label>
        
      </Col>

      <Col md={6} xs={12}>
      {price === 'paid' && (
        <Form.Group controlid="numberOfColors" className="d-flex align-items-center ">
          <Form.Label className='h6  me-5'>Amount:</Form.Label>
          <Form.Control 
          className='form-select-sm dgform-cntrol w-50'
              type="number"
              value={formData.amount}
              onChange={handleAmountChange}
              placeholder="Enter amount"
            />

            <select value={currency} onChange={handleCurrencyChange} className='form-select-sm dgform-cntrol w-25'>
                <option value="USD">USD</option>
                <option value="EURO">EURO</option>
                <option value="POUND">POUND</option>
                <option value="CA$">CA$</option>
            </select>
            </Form.Group>
      )}
      </Col>
     </Row>
     
     <Row>
          <Col md={6} xs={12}>
            <span className=' h5 py-3 d-block text-center'>Option: A</span>
            <Form.Group className=" mb-3 small d-flex" controlid="formScale">
            <Form.Label className='h6  me-4'>Measure in:</Form.Label>
                <Form.Select className='form-select-sm  dgform-cntrol w-auto'
                    name="measureScaleA"
                    value={formData.measureScaleA}
                    onChange={handleChange}
                >
                    <option value="inches">inches</option>
                    <option value="mm">mm</option>
                    <option value="cm">cm</option>
                </Form.Select>
            </Form.Group>

            <Form.Group  className="small mb-3 d-flex align-items-center " controlid="formWidth">
                <Form.Label className='h6  me-5'>Width :</Form.Label>
                <Form.Control className='form-control-sm dgform-cntrol ms-2 w-auto'
                    type="number"
                    placeholder="Enter width"
                    name="widthA"
                    value={formData.widthA}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group as={Col} className="mb-3 small d-flex align-items-center " controlid="formHeight">
                <Form.Label className='h6  me-5'>Height :</Form.Label>
                <Form.Control className='form-control-sm dgform-cntrol w-auto'
                    type="number"
                    placeholder="Enter height"
                    name="heightA"
                    value={formData.heightA}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group as={Col} controlid="formAttachReleaseA" className='mb-3 small d-flex align-items-center'>
                <Form.Label className='h6  me-2'>Attachments:</Form.Label>
                <Form.Control className='form-control-sm dgform-cntrol w-auto'
                    type="file"
                    name="attachReleaseA"
                    onChange={handleFileUpload}
                    accept=".jpg, .png, .webp, .emb, .dst, .exp, .pes, .cnd, .pxf, .pof,
                    .dsb, .ofm, .jpeg, .jpg, .webp, .psd, .pdf, .ai, .eps, .svg, .cdr, .heic, .zip, .rar, .tif,"
                    required
                    multiple
                />
        <Form.Control.Feedback type="invalid">Provide Attachments</Form.Control.Feedback>
            </Form.Group>
            
          </Col>

          <Col md={6} xs={12}>
          <span className=' h5 py-3 d-block text-center'>Option: B</span>
            <Form.Group as={Col} className=" d-flex mb-3 small" controlid="formScale">
            <Form.Label className='h6  me-4'>Measure in:</Form.Label>
                <Form.Select className='form-select-sm dgform-cntrol w-auto'
                    name="measureScaleB"
                    value={formData.measureScaleB}
                    onChange={handleChange}
                >
                    <option value="inches">inches</option>
                    <option value="mm">mm</option>
                    <option value="cm">cm</option>
                </Form.Select>
            </Form.Group>
            <Form.Group as={Col} className="small mb-3 d-flex align-items-center" controlid="formWidth">
                <Form.Label className='h6  me-5'>Width :</Form.Label>
                <Form.Control className='form-control-sm ms-2 dgform-cntrol w-auto'
                    type="number"
                    placeholder="Enter width"
                    name="widthB"
                    value={formData.widthB}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group as={Col} className="mb-3 small d-flex align-items-center" controlid="formHeight">
                <Form.Label className='h6  me-5'>Height :</Form.Label>
                <Form.Control className='form-control-sm dgform-cntrol w-auto'
                    type="number"
                    placeholder="Enter height"
                    name="heightB"
                    value={formData.heightB}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group as={Col} controlid="formAttachReleaseB" className='mb-3 small d-flex align-items-center'>
                <Form.Label className='h6  me-2'>Attachments:</Form.Label>
                <Form.Control className='form-control-sm dgform-cntrol w-auto'
                    type="file"
                    name="attachReleaseB"
                    onChange={handleFileUpload}
                    multiple
                    accept=".jpg, .png, .webp, .emb, .dst, .exp, .pes, .cnd, .pxf, .pof,
                    .dsb, .ofm, .jpeg, .jpg, .webp, .psd, .pdf, .ai, .eps, .svg, .cdr, .heic, .zip, .rar, .tif,"
                    
                />
            </Form.Group>
          </Col>
      </Row>
        
        <div className="d-grid py-4 justify-content-center">
            <Button variant="primary" type='submit'>
                Release
            </Button>
        </div>
        
    </Form>
    <Modal show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to Release Order</Modal.Title>
        </Modal.Header>
        
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Release
          </Button>
        </Modal.Footer>
      </Modal>
      </>
      )}
      </Container>
    </Container>
    </>
  )
}

export default ReleaseDigitizingOrder