import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/OurFeature.css'
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 


const OurFeature = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
    // Add additional slider settings here
  };

  return (
    <div>
      {/* Ye Desktop k liye hai */}
      <Container fluid className='section py-5 fox'>
        <Container>
      <div className='dskt-OurFetur-off'>
          <Row>
                <h2>Our Features</h2>
                <p>We deliver what we promise!</p>
              <div>
              </div>

                    <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/qa.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/qa.webp' /><br/><br/>
                            <span className='h5'>Discount on Bulk Orders</span><br/><br/>
                            <p>We can provide you a handsome discount on bulk orders with our great quality. Please contact us for details.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src="assets/ourFeatures/discount.webp" /><br/><br/>
                            <span className='h5'>Fastest Turnaround</span><br/><br/>
                            <p>Our normal turnaround time is 4-5 hours, if you need any rush order we will do in 2-4 hours without any extra charge.</p>
                        </div>
                      </Col>
                      </Row>

                      <Row>
                    <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/fastest.webp' /><br/><br/>
                            <span className='h5'>Edit / Revision Free</span><br/><br/>
                            <p>Small edits are totally free from us. And revisions are also being provided free to all of our new and old customers.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/edit.webp' /><br/><br/>
                            <span className='h5'>All Formats Available</span><br/><br/>
                            <p>We normally provide BMP, DST, PDF & EMB but if you need any other file format i.e CND, PXF, PES etc. We can also provide those.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/forms.webp' /><br/><br/>
                            <span className='h5'>365 Days Support</span><br/><br/>
                            <p>Our professional customer support team is available and ready to serve you 24/7 via call or email. We are also available via live chat.</p>
                        </div>
                      </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/365.webp' /><br/><br/>
                            <span className='h5'>Small Text Digitizing</span><br/><br/>
                            <p>Our digitizers are expert in small text digitizing. We have more than 40+ designer who are able to make small text digitizing for you.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/small.webp' /><br/><br/>
                            <span className='h5'>Applique Digitizing</span><br/><br/>
                            <p>Our professional team is also expert in Appliqué digitizing for all kind of fabric, you just need to try our service first.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/Applique.webp' /><br/><br/>
                            <span className='h5'>Animal Digitizing</span><br/><br/>
                            <p>Some customers like to get their pet digitized, not everyone or every company is expert in animal digitizing, but we are.</p>
                        </div>
                      </Col>
                </Row>
                </div>
   
      {/* Show Slider only on mobile */}
      <div className="mbile-slidr">
      <h1>Our Features</h1>
        <Slider {...sliderSettings}>
        <Col xs={12} sm={12} md={6}>
          
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/qa.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src="assets/ourFeatures/discount.webp" /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>

                    <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/fastest.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/edit.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/forms.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                      
                    <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/365.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/small.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/Applique.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>

                    <Col xs={12} md={6}>
                        <div className='grid-box grid-box-com'>
                            <img alt="img" src='assets/ourFeatures/Animal.webp' /><br/><br/>
                            <span className='h5'>100% Quality Assurance</span><br/><br/>
                            <p>With the help of our expert designers we give 100% quality assurance by double checking the quality of each order.</p>
                        </div>
                      </Col>
                
        </Slider>
      </div>
      </Container>
      </Container>   
    </div>
  );
};

export default OurFeature;