import React from 'react'
import { Container, Row, Card } from 'react-bootstrap';
import { useParams,Link } from 'react-router-dom';
import '../userDashboard/userDashboard.css'


const UserDashInAdmin = () => {
    const { id, cName } = useParams();
  return (
    <>
      <Container className='py-5' fluid style={{ backgroundColor: "#f8f8f8" }}>
          <Row className=' justify-content-center'>

          <Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/placedigitizingorder/${id}/${cName}`} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-order.webp' alt=''></img>
  <span className="price">Place Digitizing Order</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={`/digitizingorderrecord/${id}/${cName}`} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-rcord.webp' alt=''></img>
  <span className="price">Digitizing Order Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/digitizingquote/${id}/${cName}`} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote.webp' alt=''></img>
  <span className="price">Digitizing Quote</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={`/digitizingquoterecord/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote-record.webp' alt=''></img>
  <span className="price">Digitizing Quote Record</span></Link>
</Card>

{/* /////////////////////////////////////////////////////////////////////////////////////// */}

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/placevectororder/${id}/${cName}`} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/vector-ordr.webp' alt=''></img>
  <span className="price">Place Vector Order</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={`/vectororderrecord/${id}/${cName}`} className="d-block w-100 h-100">  <img className='dshh-crd-immg border' src='/assets/DashImgs/vector-order-record.webp' alt=''></img>
  <span className="price">Vector Order Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/vectorquote/${id}/${cName}`} className="d-block w-100 h-100"><img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote.webp' alt=''></img>
  <span className="price">Vector Quote</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={`/vectorquoterecord/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/digitizing-quote-record.webp' alt=''></img>
  <span className="price">Vector Quote Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/userProfileInAdmin/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/my-profile.webp' alt=''></img>
  <span className="price">MY PROFILE</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/placepatchorder/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/patchorder.webp' alt=''></img>
  <span className="price">Place Patch Order</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/patchorderrecord/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/PatchOrderRecord.webp' alt=''></img>
  <span className="price">Patch Order Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/patchquote/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/patchquotes.webp' alt=''></img>
  <span className="price">Patch Quote</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-1'>
<Link to={`/patchquoterecord/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/PatchQUoteRecord.webp' alt=''></img>
  <span className="price">Patch Quote Record</span></Link>
</Card>

<Card className='dshh-crdss mx-4 mt-5 mb-5'>
<Link to={`/invoicesCustomer/${id}/${cName}`} className="d-block w-100 h-100"> <img className='dshh-crd-immg border' src='/assets/DashImgs/invoices.webp' alt=''></img>
  <span className="price">Invoice</span></Link>
</Card>
          </Row>
      </Container>
      </>
  )
}

export default UserDashInAdmin