import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Carousel, Row } from 'react-bootstrap';


function VectorPortfolio() {
  return (
    <>
    <Container className='my-5 w-75'>
<h2 className="mb-3 secnd-hding text-center stylsh-heding">Vector</h2>
<Row className="d-none d-md-flex">
     <Col>
        <img src="assets/portfolio_img/15-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/14-01.webp" alt="/" className="imag-sze my-2" />
        </Col> 
        <Col>
        <img src="assets/portfolio_img/13.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/12.webp" alt="/" className="imag-sze my-2" />
        </Col>
        
    </Row>

    <Row className="d-none d-md-flex">
      <Col>
        <img src="assets/portfolio_img/10-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/09-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/08-01.webp" alt="" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/07-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/11-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
      </Row>
</Container>
 
  {/* Mobile View*/}
{/* First head hai */}
<Carousel className="p-4 d-md-none">
         
         <Carousel.Item className='mt-5'>
 <h2 className="mb-3 text-center stylsh-heding">Vector</h2>
         <Row className='mt-5'>
 <Col>
   <img src="assets/portfolio_img/15-01.webp" alt="/" className="img-fluid img-szee mt-2"/>
   </Col>
 <Col>
   <img src="assets/portfolio_img/14-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>

 </Row>
 <Row >
 <Col>
   <img src="assets/portfolio_img/13.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
   <img src="assets/portfolio_img/12.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 
 </Row>
 
         </Carousel.Item>
       {/* //////////////////////////////////////////////////////////////// */}
         <Carousel.Item className='mt-5'>
         <h2 className="mb-3 text-center stylsh-heding">Vector</h2>
         <Row className='mt-5'>
 {/* <Col>
   <img src="assets/portfolio_img/10-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col> */}
 <Col>
   <img src="assets/portfolio_img/09-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
   <Col>
   <img src="assets/portfolio_img/08-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 </Row>
 <Row>
 <Col>
   <img src="assets/portfolio_img/11-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
   <img src="assets/portfolio_img/07-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 </Row>
 <Row>
 
 </Row>
                </Carousel.Item>
                </Carousel>
    </>
  )
}

export default VectorPortfolio