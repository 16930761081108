import React, { createContext, useState, useEffect } from 'react'


export const LoginContext = createContext("");

const Context = ({children}) => {

    const [logindata,setLoginData] = useState(()=>{
      const storedLoginData = localStorage.getItem('logindata');
      return storedLoginData ? JSON.parse(storedLoginData) : null;
    });

    useEffect(() => {
      localStorage.setItem('logindata', JSON.stringify(logindata));
    }, [logindata]);

  return (
    <>
    <LoginContext.Provider value={{logindata,setLoginData}}>
        {children}
    </LoginContext.Provider>
    </>
  )
}

export default Context