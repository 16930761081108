import React, { useState } from 'react'
import { Row, Col, Form, Button} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Login_1.css';


const PasswordReset = () => {
   const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] =useState(false)
    const [message, setMessage] = useState("");

    const setVal = (e) => {
        setEmail(e.target.value)
    }

    const sendLink = async (e) => {
        e.preventDefault();

        if (email === "") {
            toast.error("email is required!", {
            });
        } else if (!email.includes("@")) {
            toast.warning("includes @ in your email!", {
            });
        } else {
        setIsLoading(true)
            const res = await fetch(`${process.env.REACT_APP_API_URL}/users/sendpasswordlink`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email })
            });
            setIsLoading(false)

            if (res.ok) {
                setEmail("");
                setMessage(true)
                toast.success("Password Reset Link sent to your Email", { autoClose: 5000 });
                navigate('/')
                
            } else if(!res.ok) {
                toast.error("Invalid User")
            }
        }
    }

    return (
        <>
            {/* <NaviBar /> */}
                <Row className='logn-raow g-0'>
                    <Col sm={12} md={5} >
                        {!isLoading ?(
                        <Form onSubmit={sendLink} className='logn-form'>
                            <div className='my-5 d-flex flex-column align-items-center justify-content-center'>
                                <h1>Reset Password</h1>
                                <p>Enter your registered email</p>
                            </div>
                    {message ? <p style={{ color: "green", fontWeight: "bold" }}>pasword reset link send Succsfully in Your Email</p> : ""}
                            <Form.Group className='small' controlid='email'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control className='mb-5form-control-sm lgnform-cntrol' 
                                value={email}
                                onChange={setVal}
                                required
                                placeholder="Enter your Email" name="email" type='email' />
                            </Form.Group>

                            <Row className='my-5 d-flex align-items-center justify-content-center'>

                                <Button variant="primary" type="submit">
                                    Send
                                </Button>
                        <ToastContainer />
                                
                            </Row>
                        </Form>
                        ):(
                            <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100vh',
                            }}
                          >
                            Loading... <CircularProgress />
                          </Box>
                        )}
                    </Col>
                    <Col sm={12} md={7}>
                        <div className='logn-rght'>
                            <h1 className='display-2'>Get Your Digitizing Logo At Amazing Prices</h1>
                            <Link to="/" className='btn btn-primary'>Learn More</Link>
                        </div>
                    </Col>
                </Row>
        </>
    )
}

export default PasswordReset