import React from 'react'
import { Card, Col, Container, ListGroup, Row, Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/OurPricing.css'
import { MdCheckCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';

function OurPricing() {
    const data = [
        {
          title: 'Stitch Count',
          price: 3,
          desc: '/ 1K Stiches',
          features: [
            '4 hours turnaround',
            '2 hours if rush',
            'EMB DST PDF BMP/JPG',
            'Other formats (on request)',
            'Small edits free',
            'Revisions free',
            'Discount on bulk order',
          ],
        },
        {
            title: 'Cap/Left Chest',
            price: '15-20',
            desc: 'Depends on Complexity',
            features: [
              '4 hours turnaround',
              '2 hours if rush',
              'EMB DST PDF BMP/JPG',
              'Other formats (on request)',
              'Small edits free',
              'Revisions free',
              'Discount on bulk order',
            ],
            tag: 'Popular',
          },
          {
            title: 'Mid Size / Full Back',
            price: "40-150",
            desc: 'Depends on Complexity',
            features: [
              '48 hours turnaround',
              '4-8 hours if rush',
              'EMB DST PDF BMP/JPG',
              'Other formats (on request)',
              'Small edits free',
              'Revisions free',
              'Discount on bulk order',
            ],
          },
          {
            title: 'Vector Graphics',
            price: '15-150',
            desc: 'Depends on Complexity',
            features: [
              '4 hours turnaround',
              '3-4 hours if rush',
              'AI EPS PDF CDR JPG',
              'Other formats (on request)',
              'Small edits free',
              'Revisions free',
              'Discount on bulk order',
            ],
          },
        // Add more card data here if needed.
      ];
    return (
        <Container fluid className='p-cntainer'>
            <h2 className='h2-orpricing'>Our Pricing List</h2>
            <Carousel indicators={true} controls={true} touch={true} className='mob-prizList'>
      {data.map((cardData, index) => (
        <Carousel.Item key={index} >
          <Row className='crd-row'>
            <Col sm={12} md={6} className={`crd-pricing py-3 ${index === 1 ? "second-item" : ""}`}>
              <Card.Body>
              <Card.Title className='populer text-center d-flex flex-column align-items-center'>
              <div className='batn'>{cardData.tag}</div>
              </Card.Title>
                <Card.Title className='text-center py-4'>
                  {cardData.title}
                  <br />
                  <span className='color-1'>
                    $<span className='h1'>{cardData.price}</span>
                  </span>
                  <br />
                  {cardData.desc}
                </Card.Title>
                <ListGroup>
                  {cardData.features.map((feature, idx) => (
                    <ListGroup.Item key={idx}>
                      <MdCheckCircle className='me-2' />
                      {feature}
                    </ListGroup.Item>
                  ))}
                  <Card.Title className='populer text-center d-flex flex-column align-items-center'>
                  <Link to={'/login_1'}className='btn btn-primary ms-3'>
                  Order Now
                  </Link>
                    {/* <a href='/' className='btn btn-primary ms-3'>
                    Order Now
                    </a> */}
                  </Card.Title>
                </ListGroup>
              </Card.Body>
            </Col>
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>   
    <Row className="crd-row desk-prizList">
      {data.map((item, index) => (
        <Col key={index} sm={12} md={3} className={`crd-pricing py-3 ${index === 1 ? "second-item" : ""}`}>
          <Card.Body>
          <Card.Title className='populer text-center d-flex flex-column align-items-center'>
          <div className='batn'>{item.tag}</div>
          </Card.Title>
            <Card.Title className="text-center py-4">
              {item.title}
              <br />
              <span className="h1 color-1">
                ${item.price}
              </span>
              <br />
              {item.desc}
            </Card.Title>
            <ListGroup>
              {item.features.map((feature, i) => (
                <ListGroup.Item key={i} as="li">
                  <MdCheckCircle className="me-2" /> {feature}
                </ListGroup.Item>
              ))}
              <Card.Title className='populer text-center d-flex flex-column align-items-center'>
                <Link to={'/login_1'}className='btn btn-primary ms-3'>
                Order Now
                </Link>
                {/* <a href='/' className='btn btn-primary ms-3'>
                Order Now
                </a> */}
              </Card.Title>
            </ListGroup>
          </Card.Body>
        </Col>
      ))}
    </Row>        
    </Container>



    )
}

export default OurPricing
