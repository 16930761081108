import React from 'react'
import { Container } from 'react-bootstrap'
import '../Style/BookNow.css'
import { Link } from 'react-router-dom'

const BookNow = () => {
    return (
        <>
        <Container fluid className='text-center text-white Booknow '>
            <div className='booknow-cont' >
            <h1>Order Now</h1>
            <Link to="/digitizing" className="btn btn-primary w-100 py-2 mt-3">Click here</Link>
            </div>
        </Container>
        </>
    )
}

export default BookNow
