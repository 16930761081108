import React from 'react';
import { City, Country, State } from "country-state-city";
import { useState,useEffect } from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist"
import './Register_1.css';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register_1 = () => {
  let countryData = Country.getAllCountries();
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();
  
  const [isLoading, setIsLoading] =useState(false)

  const [formData, setFormData]=useState({
    name: '',
    salesRep:'',
    email: '',
    company: '',
    phone: '',
    phone2: '',
    emailFileTo:'',
    invoiceEmail: '',
    multipleEmails: '',
    address: '',
    shippingAddress: '',
    fax: '',
    country: (countryData[232].isoCode),// Set initial country isoCode
    state: '',
    city: '',
    zip: '',
    currency: 'USD',
    password: '',
    confirmPassword: '',
    howDidYouFindUs:'',
  })
  //////Country State City/////////
  

  useEffect(() => {
    setStateData(State.getStatesOfCountry(formData.country));
  }, [formData.country]);

  useEffect(() => {
    if (formData.state) {
      setCityData(City.getCitiesOfState(formData.country, formData.state));
    }
  }, [formData.state, formData.country]);

  useEffect(() => {
    stateData && setFormData(prevFormData =>({
      ...prevFormData,
      state: stateData[0]?.isoCode })) // Set initial state isoCode
    
  }, [stateData]);
  useEffect(() => {
    cityData && setFormData(prevFormData =>({
      ...prevFormData,
      city: cityData[0]?.name })) // Set initial state isoCode
    
  }, [cityData]);

    const navigate = useNavigate();
    
    const handleChange = (e) =>{
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      })
    }

    const [validated, setValidated] = useState(false);
    // const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    
    try {
    setIsLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });


      if (response.ok) {
        setIsLoading(false)
        toast.success("Registered Successfully")
        navigate('/login_1')
        return
        // You can redirect the user to a success page or perform other actions here
      }
      else if (response.status === 421) {
        toast.error("Please filed the Fields")
        setIsLoading(false)
        return
        // You can redirect the user to a success page or perform other actions here
        
      }
      else if (response.status === 422) {
        toast.error("User email already exists try with different email")
        setIsLoading(false)
        return
      }
      else if (response.status === 400) {
        toast.error("Failed to create user")
        setIsLoading(false)
        return
      }
      else if (response.status === 490) {
        toast.error("Failed to create user")
        setIsLoading(false)
        return
      }
      else {
        toast.error("Failed to create user")
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error registering user:', error);
      toast.error("Failed to create user",error)
        setIsLoading(false)
      return
      // Handle error scenarios here
    }
  };
    return (
        <>
                        <Container className='w-50 registr-containr'>
                          {!isLoading?(

                          
                        <Form noValidate validated={validated} onSubmit={handleSubmit} className='registr-form1'>
                            <div className='my-4 d-flex flex-column align-items-center justify-content-center'>
                                <h3 className='fw-bold'>Welcome To Digitizing Fox</h3>
                                <h3 className='fw-bold'>Registration</h3>
                                
                               
                            </div>
                              <Row className='small fw-bold reg-frm'>
                                <Form.Group className='small' as={Col} md={6}>
                                  <Form.Label>Full Name:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol'  
                                      // onChange={(e) => setName(e.target.value)} 
                                      onChange={handleChange}
                                      name='name'
                                      value={formData.name} 
                                      required 
                                      placeholder="*Enter Full Name" 
                                      type='text' />
                                    <Form.Control.Feedback type="invalid">
                                    Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className='small mb-2' as={Col} md={6}>
                                  <Form.Label>Company:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      // onChange={(e) => setCompany(e.target.value)} 
                                      onChange={handleChange}
                                      name='company'
                                      value={formData.company} 
                                      placeholder="Name optional" 
                                      type="text" />
                                  </Form.Group>
                                </Row>
                                
                                <Row className='mb-2 small fw-bold'>
                                  <Form.Group className='small' as={Col} md={6}>
                                      <Form.Label>Contact No:</Form.Label>
                                          <Form.Control className='form-control-sm regsform-cntrol' 
                                          // onChange={(e) => setPhone(e.target.value)} 
                                          onChange={handleChange}
                                          name='phone' 
                                          value={formData.phone}
                                          required 
                                          placeholder="000 000 0000" 
                                          type="tel" />
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid Number.
                                        </Form.Control.Feedback>
                                  </Form.Group>
                                    
                                  <Form.Group className='small' as={Col} md={6}>
                                    <Form.Label>Cell No.</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      // onChange={(e) => setphone2(e.target.value)} 
                                      onChange={handleChange}
                                      name='phone2' 
                                      value={formData.phone2}
                                      placeholder="optional" 
                                      type="tel" />
                                  </Form.Group>
                                </Row>
                                
                                <Row className='small fw-bold mb-2'>
                                  <Form.Group className='small' as={Col} md={6}>
                                    <Form.Label>Fax:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      // onChange={(e) => setFax(e.target.value)} 
                                      name='fax' 
                                      onChange={handleChange}
                                      value={formData.fax}
                                      placeholder="optional" 
                                      type="tel" />
                                  </Form.Group>

                                  <Form.Group className='small' as={Col} md={6}>
                                    <Form.Label>Email:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      // onChange={(e) => setEmail(e.target.value)} 
                                      onChange={handleChange}
                                      name='email' 
                                      value={formData.email}
                                      required 
                                      placeholder="Enter Email" 
                                      type="email" />
                                    <Form.Control.Feedback type="invalid">
                                      Please provide a valid email.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                                
                                <Row className='fw-bold small mb-2'>
                                  <Form.Group className='small' as={Col} md={6}>
                                    <Form.Label>Email File To:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      // onChange={(e) => setEmailFileTo(e.target.value)} 
                                      onChange={handleChange}
                                      name='emailFileTo' 
                                      value={formData.emailFileTo}
                                      placeholder="optional Email" type="email" />
                                  </Form.Group>
                                
                                <Form.Group className='small' as={Col} md={6}>
                                  <Form.Label>Invoice Email:</Form.Label>
                                    <Form.Control className='form-control-sm regsform-cntrol' 
                                    // onChange={(e) => setInvoiceEmail(e.target.value)} 
                                    onChange={handleChange}
                                    name='invoiceEmail'
                                    value={formData.invoiceEmail}
                                    placeholder=" Email" 
                                    type="email" />
                                </Form.Group>
                                </Row>
                                
                                <Row className='fw-bold small mb-2'>
                                  <Form.Group className='small' as={Col} md={6}>
                                    <Form.Label>Address:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      // onChange={(e) => setAddress(e.target.value)} 
                                      onChange={handleChange}
                                      name='address'
                                      value={formData.address}
                                      placeholder="Enter Address" 
                                      type="text" />
                                  </Form.Group>

                                  <Form.Group className='small' as={Col} md={6} sm={6}>
                                      <Form.Label>Shipping Address:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      // onChange={(e) => setShippingAddress(e.target.value)} 
                                      onChange={handleChange}
                                      name='shippingAddress' 
                                      value={formData.shippingAddress}
                                      placeholder="Enter Address"  
                                      type="text" />
                                  </Form.Group>
                                </Row>
                               
                                <Row className='fw-bold small mb-2'>
                                  <Form.Group className='small' as={Col} md={3} sm={6} controlId="country"> 
                                      <Form.Label>Country:</Form.Label>
                                        <Form.Select className='form-select-sm small regsform-cntrol'
                                            required
                                            onChange={(e) => {handleChange(e)}}
                                            name='country'
                                            value={formData.country}
                                            // onChange={(e) => setCountry(e.target.value)}
                                        >
                                            {countryData.map((country) => (
                                            <option key={country.isoCode} value={country.isoCode}>
                                                {country.name}
                                            </option>
                                            ))}
                                        </Form.Select>
                                      </Form.Group>

                                    <Form.Group className='small' as={Col} md={3} sm={6} controlId="state">
                                      <Form.Label>State:</Form.Label>
                                        <Form.Select className='form-select-sm small regsform-cntrol'
                                            required
                                            name='state'
                                            value={formData.state}
                                            // onChange={(e) => setState(e.target.value)}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                        >
                                            {stateData && stateData.map((state) => (
                                            <option key={state.isoCode} value={state.isoCode}>
                                                {state.name}
                                            </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>


                                    <Form.Group className='small' as={Col} md={3} sm={6} controlId="city">
                                      <Form.Label>City:</Form.Label>
                                        <Form.Select className='form-select-sm regsform-cntrol'
                                            required
                                            name='city'
                                            value={formData.city}
                                            // onChange={(e) => setCity(e.target.value)}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                        >
                                            {cityData && cityData.map(city => (
                                            <option key={city.name} value={city.name}>
                                                {city.name}
                                            </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className='small' as={Col} md={3} sm={6} controlId="currency">
                                        <Form.Label>Currency:</Form.Label>
                                        <Form.Select className='form-select-sm small regsform-cntrol'
                                            required
                                            name='currency'
                                            value={formData.currency} 
                                            // onChange={(e) => setCurrency(e.target.value)}
                                            onChange={handleChange}
                                        >
                                            <option />
                                            <option value='USD'>USD</option>
                                            <option value='EURO'>EURO</option>
                                            <option value='POUND'>POUND</option>
                                            <option value='CA$'>CA$</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                          select currency.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>    
                                 
                                <Row className='fw-bold small mb-2'>
                                    <Form.Group className='small' as={Col} md={3} controlId='zip'>
                                      <Form.Label>Zip Code:</Form.Label>
                                      <Form.Control className='form-control-sm regsform-cntrol' 
                                      name='zip' 
                                      // onChange={(e)=>setZip(e.target.value)} 
                                      onChange={handleChange}
                                      value={formData.zip}
                                      placeholder="*Zip code" 
                                      type="zip" />
                                    </Form.Group>
                                      
                                  <Form.Group as={Col} md={5} className="small" controlId="formFabric">
                                    <Form.Label className="fw-bold">How Did You Hear About Us?</Form.Label>
                                      <Form.Select className='form-select-sm dgform-cntrol w-auto'
                                        name="howDidYouFindUs"
                                        required
                                        value={formData.howDidYouFindUs}
                                        // onChange={(e) => setHowDidYouFindUs(e.target.value)}
                                        onChange={handleChange}
                                      >
                                        <option >Choose...</option>
                                        <option value='searchengine'>Search Engine</option>
                                        <option value='socialmedia'>Social Media</option>
                                        <option value='cottonWoven'>Cotton Woven</option>
                                        <option value='radio'>Radio</option>
                                        <option value='ServiceAd'>Streaming Service Ad</option>
                                        <option value='referral'>Referral</option>
                                        <option value='other'>Other</option>
                                        </Form.Select>
                                  </Form.Group>
                                    
                                <Form.Group className='small mb-2' as={Col} md={4}>
                                  <Form.Label>Sales Rep:</Form.Label>
                                    <Form.Control className='form-control-sm regsform-cntrol' 
                                    // onChange={(e) => setSalesRep(e.target.value)} 
                                    onChange={handleChange}
                                    name='salesRep'
                                    value={formData.salesRep}
                                    placeholder="Name optional" 
                                    type="text" />
                                </Form.Group>
                              </Row>
                                
                                <Row className='fw-bold small'>
                                    <Form.Group className='small' as={Col} md={4} sm={12} controlId="password">
                                        <Form.Label>Password:</Form.Label>
                                        <Form.Control className='form-control-sm regsform-cntrol'
                                        type="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        name='password'
                                        // onChange={(e) => setPassword(e.target.value)}
                                        onChange={handleChange}
                                        required
                                        />
                                    </Form.Group>
                                    <Form.Group className='small' as={Col} md={6} sm={12} controlId="confirmPassword">
                                        <Form.Label>Confirm Password:</Form.Label>
                                        <Form.Control className='form-control-sm regsform-cntrol'
                                        required
                                        type="password"
                                        placeholder="Confirm password"
                                        name='confirmPassword'
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        // onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        <PasswordChecklist
                                                rules={["minLength","match"]}
                                                minLength={6}
                                                value={formData.password}
                                                valueAgain={formData.confirmPassword}
                                                onChange={(isValid) => {}}
                                            />
                                    </Form.Group>
                                    </Row>
                                    
                            <Row className='my-3 d-flex align-items-center justify-content-center'>
                                <Button variant="primary" type="submit">
                                    Sign Up
                                </Button>
                            </Row>
                            <div className='text-center'>
                                <p>Already Have An Account? <Link to='/login_1'>Sign In</Link></p>
                            </div>
                        </Form>
                        ):(
                          <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                          }}
                        >
                          Loading... <CircularProgress />
                        </Box>
                        )}
                        </Container>
        </>
    )
}

export default Register_1
