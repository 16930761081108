import React, {  useEffect, useState } from 'react'
import { Container,Form } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa6";
import { format } from 'date-fns';


const TodayOrders = () => {
    const [digitizData, setDigitizData]=useState([])
    const [patchData, setPatchData] = useState([])
    const [vectorData, setVectorData] = useState([])
    const [errorD, setErrorD] = useState('')
    const [errorV, setErrorV] = useState('')
    const [errorP, setErrorP] = useState('')

    const [isPatchLoading, setIsPatchLoading] = useState(true)
    const [isDigiLoading, setIsDigiLoading] = useState(true)
    const [isVectorLoading, setIsVectoLoading] = useState(true)


    const [search, setSearch] = useState('');

    const vectorUrl = `${process.env.REACT_APP_API_URL}/adminDashboard/todayVectorOrders`
    const patchUrl = `${process.env.REACT_APP_API_URL}/adminDashboard/todayPatchOrders`
    const digitizUrl = `${process.env.REACT_APP_API_URL}/adminDashboard/todayDigitizOrders`

    // GET All Digitiz Orders 24hrs
    useEffect(() => {
        const fetchData = async ()=>{
            try {
                const response = await fetch(digitizUrl,{
                    method: 'GET',
                    credentials: 'include'
                },)

                if(response.status === 404){
                    throw new Error(`Record is Empty`)
                }
                if(!response.ok){
                    throw Error(`HTTP error! Status:${response.status}`)
                }
                const data = await response.json();
                setDigitizData(data)
                setIsDigiLoading(false)
            } catch (error) {
                setErrorD(error ||"Error in catch" )
                setIsDigiLoading(false)
            }
        }
        fetchData();
    }, [digitizUrl])

    // GET All Vector Orders of 24hrs
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await fetch(vectorUrl,{
                    method: 'GET',
                    credentials: 'include'
                })
                if(response.status === 404){
                    throw new Error(`Record is Empty`)
                }
                if(!response.ok){
                    throw Error(`HTTP error! Status:${response.status}`)
                }
                const data = await response.json();
                setVectorData(data)
                setIsVectoLoading(false)
                
            } catch (error) {
                setErrorV(error || 'Error in catch')
                setIsVectoLoading(false)
            }
            
        }
        fetchData();
    }, [vectorUrl])

    //GET All Patch Orders of 24hrs
    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const response = await fetch(patchUrl,{
                    method:'GET',
                    credentials:'include'
                })
    
                if(!response.ok){
                    throw Error(`HTTP error! Status:${response.status}`)
                }
                const data = await response.json();
                setPatchData(data)
                setIsPatchLoading(false)   
            } catch (error) {
                setErrorP(error || 'Error in catch')
                setIsPatchLoading(false)
            }
        }
        fetchData();
    },[patchUrl])

    const filteredPatchData = patchData.filter(item =>
       (item.designName || '').toLowerCase().includes(search.toLowerCase())
        || (item.orderId || '').toLowerCase().includes(search.toLowerCase()) 
      );

      const filteredDigiData = digitizData.filter(item =>
       (item.designName || '').toLowerCase().includes(search.toLowerCase())
        || (item.orderId || '').toLowerCase().includes(search.toLowerCase())  
      );

      const filteredVectorData = vectorData.filter(item =>
       (item.designName || '').toLowerCase().includes(search.toLowerCase())
        || (item.orderId || '').toLowerCase().includes(search.toLowerCase()) 
      );
    
  return (
      <>
          <Container>

          {/* /////////////////Digitizing Orders///////////////////// */}
          <table className="table table-bordered text-center my-5">

              <thead>
                  <tr >
                      <th colSpan="11" >
                          <h2>Digitizing Orders </h2>
                          <div className='float-end'>
                              <label>Search</label>
                              <Form.Control 
                                type="text"
                                placeholder="OrderID or DesignName"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                          </div>
                      </th>
                  </tr>
                  <tr>
                    <th scope="col">Ser#</th>
                      <th scope="col">Ordered Date</th>
                      <th scope="col">Coustumer Name</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Design Name</th>
                      <th scope="col">Details</th>
                      <th scope="col">Release</th>
                  </tr>
              </thead>
              {isDigiLoading ? (
                    <tbody>
        <tr>
          <td>Loading...</td>
        </tr>
      </tbody>
                ) : errorD ? (
                    <tbody>
                    <tr>
                      <td colSpan="8">Error: {errorD.message}</td>
                    </tr>
                  </tbody>
                ) : (
              <tbody>
                {filteredDigiData.map((item,index) => (
                    <tr key={item._id} className=' m-0 p-0 text-center'>
                    <td>{index + 1}</td>
                    <td>{item.dateOrdered ? format(new Date(item.dateOrdered), 'MM/dd/yyyy h:mm a') : null}</td>
                    <td>{item.costumerName}</td>
                    <td >{item.orderId}</td>
                    <td>{item.designName}</td>
                    <td><Link to={`/singleDigitizingOrderDetails/${item.id}`}><FaEye className=' w-100'/></Link>
                    </td>
                    <td><Link 
                    to={`/releaseDigitizingOrder/${item.id}/${item.user && item.user.priceDetails ? item.user.priceDetails : ''}/${item.user && item.user.name}/${item.user && item.user.email}`}>
                        <button>Release</button></Link></td>
            </tr>
            ))}
              </tbody>
              )}
          </table>

          {/* ///////////////////////////Vector Orders/////////////////////// */}
          <table className="table table-bordered text-center my-5">

              <thead>
                  <tr >
                      <th colSpan="11" >
                          <h2>Vector Orders </h2>
                          <div className='float-end'>
                              <label>Search</label>
                              <Form.Control 
                                type="text"
                                placeholder="OrderID or DesignName"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                          </div>
                      </th>
                  </tr>
                  <tr>
                    <th scope="col">Ser#</th>
                      <th scope="col">Ordered Date</th>
                      <th scope="col">Coustumer Name</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Design Name</th>
                      <th scope="col">Details</th>
                      <th scope="col">Release</th>
                  </tr>
              </thead>
              {isVectorLoading ? (
                    <tbody>
                    <tr>
                    <td>Loading...</td>
                    </tr>
                </tbody>
                ) : errorV ? (
                   <tbody>
                <tr>
                <td colSpan="8">Error: {errorV.message}</td>
                </tr>
                </tbody>
                ) : (
              <tbody>
                {filteredVectorData.map((item,index) => (
                    <tr key={item._id} className=' m-0 p-0 text-center'>
                    <td>{index + 1}</td>
                    <td>{item.dateOrdered ? format(new Date(item.dateOrdered), 'MM/dd/yyyy h:mm a') : null}</td>
                    <td>{item.costumerName}</td>
                    <td >{item.orderId}</td>
                    <td>{item.designName}</td>
                    <td><Link to={`/singleVectorOrderDetails/${item.id}`}><FaEye className=' w-100'/></Link></td>
                    <td><Link 
                    to={`/releaseVectorOrder/${item.id}/${item.user && item.user.priceDetails ? item.user.priceDetails : ''}/${item.user && item.user.name}/${item.user && item.user.email}`}>
                        <button>Release</button></Link></td>
            </tr>
            ))}
              </tbody>
              )}
          </table>

          {/* //////////////////////Patch Orders//////////////////// */}
          <table className="table table-bordered text-center my-5">

              <thead>
                  <tr >
                      <th colSpan="11" >
                          <h2>Patch Orders </h2>
                          <div className='float-end'>
                              <label>Search</label>
                              <Form.Control 
                                type="text"
                                placeholder="OrderID or DesignName"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                          </div>
                      </th>
                  </tr>
                  <tr>
                    <th scope="col">Ser#</th>
                      <th scope="col">Ordered Date</th>
                      <th scope="col">Coustumer Name</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Design Name</th>
                      <th scope="col">Details</th>
                      <th scope="col">Release</th>
                  </tr>
              </thead>
              {isPatchLoading ? (
                    <tbody>
                    <tr>
                    <td>Loading...</td>
                    </tr>
                    </tbody>
                ) : errorP ? (
                   <tbody>
                <tr>
                <td colSpan="8">Error: {errorP.message}</td>
                </tr>
                </tbody>
                ) : (
              <tbody>
                {filteredPatchData.map((item,index) => (
                    <tr key={item._id} className=' m-0 p-0 text-center'>
                    <td>{index + 1}</td>
                    <td>{item.dateOrdered ? format(new Date(item.dateOrdered), 'MM/dd/yyyy h:mm a') : null}</td>
                    <td>{item.costumerName}</td>
                    <td >{item.orderId}</td>
                    <td>{item.designName}</td>
                    <td><Link to={`/singleRecordDetails/${item.id}`}><FaEye className=' w-100'/></Link></td>
                    <td><Link 
                    to={`/releasePatchOrder/${item.id}/${item.user && item.user.priceDetails ? item.user.priceDetails : ''}/${item.user && item.user.name}/${item.user && item.user.email}`}>
                        <button>Release</button></Link></td>
            </tr>
            ))}
              </tbody>
              )}
          </table>
          
          </Container>
      </>
  )
}

export default TodayOrders