import React from 'react'
import { Container } from 'react-bootstrap'
import '../Style/Question.css'
import { Link } from 'react-router-dom'

const Question = () => {
    return (
        <>
        <Container fluid className='text-center text-white patch-Question '>
            <div className='quest-cont' >
            <h1>Have A Question?</h1>
            <p className='h4 font-weight-light'>Let's Chat!</p>
            <Link to="/contacts" className="btn btn-primary">Contact Us</Link>
            </div>
        </Container>
        </>
    )
}

export default Question
