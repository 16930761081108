
import React, { useState, useContext, useEffect } from 'react';
import {Container,Form} from 'react-bootstrap';
import { LoginContext } from '../../../ContextProvider/Context';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../dashComon.css'
import { Link,useParams } from 'react-router-dom';
import { FaEye,FaDownload } from "react-icons/fa6";
import { format } from 'date-fns';

function PatchQuoteRecord() {
  const { logindata } = useContext(LoginContext);
  const {id,costumerName} = useParams();
  const recId = id ? id : logindata.id
  const Name = costumerName ? costumerName : logindata.name
  const dataUrl = `${process.env.REACT_APP_API_URL}/patch/quoterecords/${recId}`;
  const delOrd = `${process.env.REACT_APP_API_URL}/patch/deleteQuoterecord`;

  
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

    useEffect(() => {
      

    const callPatchQuoteRecords = async () => {
      try {
        const response = await fetch(dataUrl);
        if (response.ok) {
          const result = await response.json();
          setData(result);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
  };
  callPatchQuoteRecords();
}, [dataUrl]);

const downloadFile = (fileUrl,filenameParts) => {
  // Create a blob from the file URL or data
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      // Create a temporary URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a virtual link element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${filenameParts[0]}.${filenameParts[1]}`; // Specify the file name

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to initiate the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    });
};
  
  const filteredData = data.filter(item =>
   (item.designName || '').toLowerCase().includes(search.toLowerCase())
        || (item.orderId || '').toLowerCase().includes(search.toLowerCase())
  );

  const deleteOrder = async (orderId) => {
    try {
      const res = await fetch(`${delOrd}/${orderId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (!res.ok) {
        window.alert('Server did not respond');
        console.error('Status:', res.status);
      } else {
        window.location.reload()
      }
    } catch (err) {
      window.alert('Server did not respond');
      console.error('Error:', err);
    }
  }
  
    return (
      <>
<Container fluid>
  
  <table className="table table-bordered text-center my-5">

  <thead>
    <tr >
      <th colSpan="11" >
        <h2>Welcome {Name}</h2>
        <p>Patches Quotes Details</p>
        <div className='float-end'>
        <label>Search</label>
        <Form.Control 
          type="text"
          placeholder="ID or DesignName"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        </div>
      </th>
    </tr>
    <tr>
      <th scope="col">Ser#</th>
      <th scope="col">Details</th>  
      <th scope="col-" className=' col-2'>Order Id</th>
      <th scope="col">Design Name</th>
      <th scope="col">Patch type</th>
      <th scope="col" className='col-2'>PO Number</th>
      <th scope="col">Date Ordered</th>
      <th scope="col">Quantity</th>
      <th scope="col">Files</th>
      <th scope="col">Price</th>
      <th scope="col">status</th>
      {id?
      <th scope="col">Delete</th>
        :null
      }
    </tr>
  </thead>
  <tbody>
  {filteredData ? filteredData.map((item,index) => (
            <tr key={item._id} className=' m-0 p-0 text-center'>
              <td>{index + 1}</td>
              <td><Link to={`/singlePatchQuoteDetails/${item.id}`}><FaEye className=' w-100'/></Link></td>
              <td >{item.orderId}</td>
              <td>{item.designName}</td>
              <td>{item.type}</td>
              <td>{item.poNumber}</td>
              <td>{item.dateOrdered ? format(new Date(item.dateOrdered), 'MM/dd/yyyy h:mm a') : null}</td>
              <td>{item.quantity}</td>
             <td>
              {item.attachments ? item.attachments.map((urlfile)=>{
                const segments = urlfile.split('/');
                const fileEx = segments[segments.length - 1];
                const filenameParts = fileEx.split('.');
                const filename = filenameParts.slice(0, -1).join('.');
                return(<FaDownload className='fs-5 link-success me-1 text-primary' onClick={()=> downloadFile(urlfile,filename)} />)
                }):<p>no files</p>}
              </td>
              <td className=' text-danger'>
                    {item.price === 'paid' ? (
                        <>
                        {item.amount}
                        {(() => {
                            switch (item.currency) {
                            case 'USD':
                                return <span>.$</span>;

                            case 'POUND':
                                return <span>.£</span>;

                            case 'CA$':
                                return <span>.C$</span>;

                            case 'EURO':
                                return <span>.€</span>;

                            default:
                                return null;
                            }
                        })()}
                        </>
                    ) : (
                        item.price
                    )}
                    </td>
              <td>{item.status}</td>
              
              {id ? <td className=''><button className='px-2' onClick={() => deleteOrder(item.orderId)} >Delete</button></td>
                : null}
            </tr>
            )):<h4> No Data</h4>}
  </tbody>
</table>
    
        </Container>
      </>
    );
  }

export default PatchQuoteRecord