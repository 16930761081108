import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Carousel, Row } from 'react-bootstrap';

function PatchPortfolio() {
  return (
    <>
   <Container className='w-75'>
    <h2 className="mb-3 d-none d-md-block text-center stylsh-heding">Patches</h2>
    <Row className="d-none d-md-flex">
     <Col>
        <img src="assets/portfolio_img\Png 6-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/Png5-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/IMG_2483.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/Untitled-1-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
        
    </Row>

    <Row className="d-none d-md-flex">
      <Col>
        <img src="assets/portfolio_img/Untitled-2-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/Untitled-3-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
      <Col>
        <img src="assets/portfolio_img/Untitled-4-01.webp" alt="" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/IMG_2548.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        <img src="assets/portfolio_img/Untitled-6-01.webp" alt="/" className="imag-sze my-2" />
        </Col>
        <Col>
        
        </Col>
        <Col>
        
        </Col>
        <Col>
       
        </Col>
      </Row>
    </Container>
 
  {/* Mobile View*/}
{/* First head hai */}
  <Carousel className="p-4 d-md-none">
          <Carousel.Item >
  <h2 className="mb-3 text-center stylsh-heding">Patches</h2>
          <Row >
          <Col>
          <img src="assets/portfolio_img\Png 6-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
 <img src="assets/portfolio_img/Png5-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>

 </Row>
 <Row >
 <Col>
 <img src="assets/portfolio_img/IMG_2483.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
 <img src="assets/portfolio_img/Untitled-1-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 
 </Row>
  
          </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////// */}
          <Carousel.Item>
          <h2 className="mb-3 text-center stylsh-heding">Patches</h2>
          <Row>

          <Col>
          <img src="assets/portfolio_img/Untitled-2-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
 <img src="assets/portfolio_img/Untitled-3-01.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>

 </Row>
 <Row >
 <Col>
 <img src="assets/portfolio_img/Untitled-4-01.webp" alt="" className="img-fluid img-szee mt-2" />
   </Col>
 <Col>
 <img src="assets/portfolio_img/IMG_2548.webp" alt="/" className="img-fluid img-szee mt-2" />
   </Col>
 
 </Row>
          </Carousel.Item>

                  </Carousel>

    </>
  )
}

export default PatchPortfolio