import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image,Navbar,Nav, Container,NavDropdown} from 'react-bootstrap';
import './DashNav.css';
import LogMenu from '../../layout/LogMenu';
import { Link } from 'react-router-dom';

function DashNav() {

    return (
        <>
    <Navbar collapseOnSelect expand="lg"  style={{backgroundColor: "#adb5bd"}}>
      <Container>
        <Link to="/userdashboard">
        <Image
            className='logoImg'
            src="/assets/logo.webp"
            alt="Brand Logo"
            width={120}
            height={80}
        />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto dash-nav">
            
              <Link to="/userdashboard">Dashboard</Link>
            
              <Link to="/userprofile">Profile</Link>
              
            <NavDropdown title="Patch" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/placepatchorder">Place Patch Order</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/patchquote">Place Patch Quote</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/patchorderrecord">Patch Order Record</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/patchquoterecord">Patch Quote Record</Link>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Digitizing" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/placedigitizingorder">Place Digitizing Order</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/digitizingquote">Place Digitizing Quote</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/digitizingorderrecord">Digitizing Order Record</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/digitizingquoterecord">Digitizing Quote Record</Link>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Vector" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/placevectororder">Place Vector Order</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/vectorquote">Place Vector Quote</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/vectororderrecord">Vector Order Record</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/vectorquoterecord">Vector Quote Record</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <LogMenu />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>  
    </>
    )
}

export default DashNav