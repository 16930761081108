import React from 'react'
import { Container,Card, CardGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/CardsSection.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const CardsSection = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay:true,
    autoplaySpeed: 4000,
    // Yaha slide ki aur setting add kr sakhty ho aur baki slide ki setting apnay hisaab sy set krlo agr ye theek nh to
  };

  return (
    <Container className="mt-5 g-5">
      {/* Mobile Slider */}
      <div className="mobile-slider">
        <Slider {...sliderSettings}>
          <div className="slider-item">
          <Card className="crd">
        <div className='crd-img'>
          <Card.Img variant="top" src="assets/portfolio_img/Horsekhusew-circule.webp" />
        </div>
        <Card.Body>
            <div className='crd-tit'>
              <Link to={'/digitizings'}><Card.Title className='text-white btn btn-primary'>Digitizing</Card.Title></Link>
            </div>
             <div className='crd-body'>
               <Card.Text>
               Embroidery design looks really attractive on hats and the left chest. It is the nature of people that they like little embroidery designs on their left chest or hats/caps.
               </Card.Text>
               <div className="card-btan">
                {/* <a href="/" className="btn btn-primary">Order Now</a> */}
                <Link to={'/login_1'}className="btn btn-primary">
                  Order Now
                </Link>
              </div>
             </div>
          
         </Card.Body>
       </Card>
          </div>
      
          <div className="slider-item">
          <Card className="crd">
         <div className='crd-img'>
           <Card.Img variant="top" src="assets/portfolio_img/skeletonman.webp"/>
         </div>
      <Card.Body>
           <div className='crd-tit'>
             <Link to={'/patches'}><Card.Title className='text-white btn btn-primary'>Patches</Card.Title></Link>
           </div>
           <div className='crd-body'>
             <Card.Text>
             At Digitizing Fox, quality of the patches and our customer’s satisfaction are our foremost concern.
             </Card.Text>
             <div className="card-btan">
               <br/><br/>
               <Link to={'/login_1'}className="btn btn-primary">
                  Order Now
                </Link>
                 {/* <a href="/" className="btn btn-primary">Order Now</a> */}
               </div>
             </div>
          </Card.Body>
       </Card>
          </div>
          <div className="slider-item">
          <Card className="crd">
         <div className='crd-img'>
           <Card.Img variant="top" src="assets/vector-card.webp"/>
         </div>
         <Card.Body>
             <div className='crd-tit'>
               <Link to={'/vectors'}><Card.Title className='text-white btn btn-primary'>Vector</Card.Title></Link>
             </div>
             <div className='crd-body'>
               <Card.Text>
               Digitizing Fox also gives opportunity for providing a digital look of your hand drawing and gives an exceptional outlook of your hand drawing skill.
               </Card.Text>
               <div className="card-btan">
                 <br/>
                 <Link to={'/login_1'}className="btn btn-primary">
                  Order Now
                </Link>
                 {/* <a href="/" className="btn btn-primary">Order Now</a> */}
               </div>
             </div>
         </Card.Body>
          </Card>
          </div>
          </Slider>
          </div>

      {/* Desktop Layout */}
      <div className="desktop-cards">
      <Container className='mt-3'>
      <div className='card-group-container'>
    <CardGroup >
      
      <Card className="crd" >
        <div className='crd-img'>
          <Card.Img variant="top" src="assets/portfolio_img/Horsekhusew-circule.webp"   />
        </div>
        <Card.Body>
            <div className='crd-tit'>
              <Link to={'/digitizings'}><Card.Title className='text-white btn btn-primary'>Digitizing</Card.Title></Link>
            </div>
            <div className='crd-body '>
              <Card.Text>
              Embroidery design looks really attractive on hats and the left chest. It is the nature of people that they like little embroidery designs on their left chest or hats/caps.
              </Card.Text>
              <div className="card-btan">
                {/* <a href="/" className="btn btn-primary">Order Now</a> */}
                <Link to={'/login_1'}className="btn btn-primary">
                  Order Now
                </Link>
              </div>
            </div>
        </Card.Body>
          </Card>
        
        <Card className="crd">
        <div className='crd-img'>
          <Card.Img variant="top" src="assets/portfolio_img/skeletonman.webp"/>
        </div>
        <Card.Body>
            <div className='crd-tit'>
              <Link to={'/patchs'}><Card.Title className='text-white btn btn-primary'>Patches</Card.Title></Link>
            </div>
            <div className='crd-body'>
              <Card.Text>
              At Digitizing Fox, quality of the patches and our customer’s satisfaction are our foremost concern.
              </Card.Text>
              <div className="card-btan">
                <br/><br/>
                {/* <a href="/" className="btn btn-primary">Order Now</a> */}
                <Link to={'/login_1'}className="btn btn-primary">
                  Order Now
                </Link>
              </div>
            </div>
        </Card.Body>
      </Card>
      
      <Card className="crd">
        <div className='crd-img'>
          <Card.Img variant="top" src="assets/vector-card.webp"/>
        </div>
        <Card.Body>
            <div className='crd-tit'>
              <Link to={'/vectors'}><Card.Title className='text-white btn btn-primary'>Vector</Card.Title></Link>
            </div>
            <div className='crd-body'>
              <Card.Text>
              Digitizing Fox also gives opportunity for providing a digital look of your hand drawing and gives an exceptional outlook of your hand drawing skill.
              </Card.Text>
              <div className="card-btan">
                <br/>
                {/* <a href="/" className="btn btn-primary">Order Now</a> */}
                <Link to={'/login_1'}className="btn btn-primary">
                  Order Now
                </Link>
              </div>
            </div>
        </Card.Body>
      </Card>
    
    </CardGroup>
    </div>
  </Container>
      </div>
    </Container>
  );
};

export default CardsSection;