import React from 'react'
import VectorDetails from './Components/VectorDetails'
import VectorBookNow from './Components/VectorBookNow'
import VectorPortfolio from './Components/VectorPortfolio'
import AboutHeader from '../About/Components/AboutHeader'

const Vector = () => {
  return (
    <>
    <AboutHeader
      title='Vector'
      backgroundImage={`url("/assets/VectorHeader.webp"), linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%)`}
    />
    <VectorDetails/>
<VectorPortfolio/>
    <VectorBookNow/>
    </>
  )
}

export default Vector