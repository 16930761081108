import React from 'react'
import OurPricing from '../Home/components/OurPricing'
import AboutHeader from '../About/Components/AboutHeader'

const Pricing = () => {
  return (
    <>
    <AboutHeader
      title='Pricing'
      backgroundImage={`url("/assets/ContactHeader.webp"), linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%)`}
    />
    <OurPricing/>
    </>
  )
}

export default Pricing