import React, {  useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button,  } from 'react-bootstrap';
import PasswordChecklist from "react-password-checklist"
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    const { id, token } = useParams();

    const navigate = useNavigate();


    // const [data2, setData2] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isLoading, setIsLoading] =useState(false)


    const [message, setMessage] = useState("");

    // const forgetPssUrl =`${process.env.REACT_APP_API_URL}/users/forgotpassword/${id}/${token}`

    // useEffect(() => {
    // const userValid = async () => {
    //     const res = await fetch( forgetPssUrl, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });

    //     const data = await res.json()

    //     if (data.status === 201) {
            
    //     } else {
    //         // navigate("*")
    //     }
    // }
    // userValid()
    //     setTimeout(() => {
    //         setData2(true)
    //     }, 3000)
    // }, [forgetPssUrl])


    const setval = (e) => {
        setPassword(e.target.value)
    }

    const sendpassword = async (e) => {
        e.preventDefault();

            setIsLoading(true)
            const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}/${token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ password })
            });
            setIsLoading(false)
            

            if (res.ok) {
                // const data = await res.json()
                setPassword("")
                toast.success("Password is Updated",{
                })
                setMessage(true)
                navigate('/login_1')
            } 
            
            else if(!res.ok) {
                console.error("ERROR",res.status)
                toast.error("! Token Expired generate new LInk",{
                })
            }
            // console.log("!!!!!!",res.status,res)
        
    }

    
        

    return (
        <>
                
                <Row className='logn-raow g-0'>
                    <Col sm={12} md={5} >
                    {!isLoading ? (<>
                        <Form onSubmit={sendpassword} className='logn-form'>
                            <div className='my-5 d-flex flex-column align-items-center justify-content-center'>
                                <h1>New Password</h1>
                                <p>Enter new password</p>
                            </div>
                            {message ? <p style={{ color: "green", fontWeight: "bold" }}>Password Succesfulyy Update </p> : ""}
                            <Form.Group className='small' as={Col} md={6} sm={12} controlid="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control className='lgnform-cntrol form-control-sm'
                                        type="password"
                                        value={password}
                                        name="password"
                                        placeholder='Enter Your maj password'
                                        onChange={setval}
                                        required
                                        />
                                    </Form.Group>
                                    <Form.Group className='small' as={Col} md={6} sm={12} controlid="confirmPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control className='lgnform-cntrol form-control-sm'
                                        required
                                        type="password"
                                        placeholder="Confirm password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        <PasswordChecklist
                                                rules={["minLength","match"]}
                                                minLength={6}
                                                value={password}
                                                valueAgain={confirmPassword}
                                                onChange={(isValid) => {}}
                                            />
                                    </Form.Group>

                            <Row className='my-5 d-flex align-items-center justify-content-center'>

                                <Button variant="primary" type="submit">
                                    Send
                                </Button>
                            </Row>
                        </Form>
                        </>
                        ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                              }}
                            >
                              Loading... <CircularProgress />
                            </Box>
                          )}
                    </Col>
                    <Col sm={12} md={7}>
                        <div className='logn-rght'>
                            <h1 className='display-2'>Get Your Digitizing Logo At Amazing Prices</h1>
                            <Link to="/" className='btn btn-primary'>Learn More</Link>
                        </div>
                    </Col>
                </Row>
                
        </>
    )
}

export default ForgotPassword