import React from 'react'
import DigitizDetails from './Components/DigitizDetails'
import BookNow from './Components/BookNow'
import DigitizingPortfolio from './Components/DigitizingPortfolio'
import AboutHeader from '../About/Components/AboutHeader'

const Digitizing = () => {
  return (
    <>
    <AboutHeader
        title='Digitizing'
         backgroundImage={`url("/assets/PricingHeader.webp"), linear-gradient(180deg, rgba(21, 45, 64, 0.00) 0%, rgba(30, 25, 78, 0.46) 25.43%, #625f83 100%)`}
        />
    <DigitizDetails/>
    <DigitizingPortfolio/>
    <BookNow/>
    </>
  )
}

export default Digitizing