import React from 'react'
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Testimonals.css'
import { Row,Col,Card, Container} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonals = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    // Update the slidesToShow value based on the screen width
    const updateSlidesToShow = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(3);
      }
    };

    // Call the function once to set the initial value
    updateSlidesToShow();

    // Add event listener to update the value when the window is resized
    window.addEventListener('resize', updateSlidesToShow);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateSlidesToShow);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1, // Set this to 1 if you want to scroll one slide at a time
  };
  return (
    <>
    <Container fluid className='pt-5 testi-cntainer'>
      <Row className='pt-5'>
        
        <Col sm={12} md={3} className='d-flex align-items-center justify-content-center'>
            <div className='testi-head'>
              <span>Our Clients</span>
              <h2>Testimonals</h2>
            </div>
        </Col>
        
        <Col sm={12} md={9} className='testi-slid-col'>
          <Slider {...settings}>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                "Your work is Amazing".

                </Card.Text>
                <Card.Title>
                  <Row >
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>Greg Hogg</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                "Got the files in an hour with excellent quality."
                </Card.Text>
                <Card.Title>
                  <Row>
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>David </span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                "The patches were amazing and beautiful with the best backing I have ever seen."
                </Card.Text>
                <Card.Title>
                  <Row>
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>John</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                "Your graphic work is truly awesome."
                </Card.Text>
                <Card.Title>
                  <Row>
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>Finn</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                "Found the company which works on weekends too. I will be referring your company to everyone."
                </Card.Text>
                <Card.Title>
                  <Row>
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>Jenny</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                  "The puff design was fantastic and thanks for delivering so fast."
                </Card.Text>
                <Card.Title>
                  <Row>
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>Parker</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                "The PVC patches were terrific and will be re-ordering more."
                </Card.Text>
                <Card.Title>
                  <Row>
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>Steve Moore</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className=' shadow-sm bg-white testi-crd'>
              <Card.Body>
                <Card.Title>
                  <div>
                    <img src="assets/quote.webp" alt='client-img' width={15} height={15} />
                  </div>
                </Card.Title>
                <Card.Text className='testimonal-body-txt'>
                "Needed someone to work with me 24/7 GLAD I found you."
                </Card.Text>
                <Card.Title>
                  <Row>
                    
                    <Col xs={9}>
                      <div className='card testi-footer'>
                        <span className='h4'>Lisa</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
          </Slider>
        </Col>

      </Row>
    </Container>
    
    <Container className='py-5'>
      <Row className=''>
        <Col xs={6} sm={6} md={3} className='d-flex justify-content-center align-items-center'>
          <div className='clrcl shadow-lg my-2 circle'>
            <span className='h1'>603</span>
            <p className='h6'>Clients</p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} className='d-flex justify-content-center align-items-center'>
          <div className='clrcl shadow-lg my-2 circle'>
            <span className='h1'>530</span>
            <p className='h6 text-center'>Happy customers</p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} className='d-flex justify-content-center align-items-center'>
          <div className='clrcl shadow-lg my-2 circle '>
            <span className='h1'>847</span>
            <p className='h6 text-lg-center'>Digitizing Projects</p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} className='d-flex justify-content-center align-items-center'>
          <div className='clrcl shadow-lg my-2 circle'>
            <span className='h1'>389</span>
            <p className='h6 text-center'>Vector Projects</p>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Testimonals