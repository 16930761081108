import React from 'react';
import { useState, useContext } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Login_1.css';
import { LoginContext } from '../../ContextProvider/Context';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = `${process.env.REACT_APP_API_URL}/users/login`;

const Login_1 = () => {

  const { setLoginData } = useContext(LoginContext)

    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] =useState(true)


console.log("!!!!!!!!!!Login URL",API_URL)

const handleSubmit = async(e)=>{
    e.preventDefault();

    const userData = {
        email,
        password
    }
    try {
    setIsLoading(false)
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: "include",
          body: JSON.stringify(userData),
        });
  
        if (response.ok) {
          const result = await response.json();

          setLoginData(result.user)
          setIsLoading(true)
          if(result.user.isAdmin){
                  navigate('/adminDashboard')
                }
                if(!result.user.isAdmin){
                  navigate('/userdashboard')
                }
          // callUserDashboard()
            toast.success("Login Successfully")
          } else if (response.status === 401) {
            setIsLoading(true)
            setError('Incorrect username or password');
          }
          else if (response.status === 400) {
            setIsLoading(true)
            setError('Incorrect Email or Password');
          }
          else {
            setIsLoading(true)
            setError('An error occurred. Please try again later.');
          }
        } catch (error) {
          setIsLoading(true)
          setError('An error occurred. Please try again later.');
        }
      };

    return (
        <>
                <Row className='logn-raow g-0'>
                    <Col sm={12} md={5} >
                      {isLoading? (
                        <Form onSubmit={handleSubmit} className='logn-form mt-5 justify'>
                            <div className='my-5 d-flex flex-column align-items-center justify-content-center'>
                                <h1>Sign In</h1>
                                <p>Enter your username and password</p>
                            </div>
                            <Form.Group controlid='email'>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control className='mb-2 form-control-sm lgnform-cntrol' 
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                                required
                                placeholder="*Username" name="email" type='email' />
                            </Form.Group>

                            <Form.Group controlid='password'>
                                <Form.Label>Password:</Form.Label>
                                <Form.Control className='form-control-sm lgnform-cntrol'
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                                required
                                placeholder="*Enter Password" name="password" type="password" />
                                <Link to="/password-reset" className='text-black float-end'>
                                Forget Password?
                                </Link>
                                {/* <a href='/' className='text-black float-end'>Forget Password?</a> */}
                            </Form.Group>
                            {error && <p className="error_msg">{error}</p>}
                            <Row className='my-5 d-flex align-items-center justify-content-center'>

                                <Button className='mx-1' variant="primary" type="submit">
                                    Login
                                </Button>
                                <Link to="/register_1" className='btn btn-primary mx-1'>Register</Link>
                            </Row>
                            <div className='text-center'>
                                <p><a href='/'>Join</a> our great community</p>
                            </div>
                        </Form>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100vh',
                            }}
                          >
                            Loading... <CircularProgress />
                          </Box>
                        )}
                    </Col>
                    <Col sm={12} md={7}>
                        <div className='logn-rght'>
                            <h1 className='display-2'>Get Your Digitizing Logo At Amazing Prices</h1>
                            <Link to="/" className='btn btn-primary'>Learn More</Link>
                        </div>
                    </Col>
                </Row>
        </>
    );
};

export default Login_1;