import React from 'react'
import '../Styles/LetsWork.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const LetsWork = () => {
    return (
        <>
        <Container fluid className='text-center text-white letsWork '>
            <div className='letsWork-cont' >
            <h1>Lets Work Together</h1>
            <Link to={'/contacts'} className="btn btn-primary w-50 py-2 mt-5">Contact Us</Link>
            </div>
        </Container>
        </>
    )
}

export default LetsWork
