import React from 'react'
import AboutHeader from './Components/AboutHeader'
import AbouDetails from './Components/AbouDetails'
import Portfolio from '../Home/components/Portfolio'
import LetsWork from './Components/LetsWork'

const About = () => {
    return (
        <>
        <AboutHeader/>
        <AbouDetails/>
        <Portfolio/>
        <LetsWork/>

        </>
    )
}

export default About
