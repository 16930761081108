import React, { useState,useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Container,Row,Col} from 'react-bootstrap';
import { FaDownload, FaEye } from "react-icons/fa6";
import './style/SingleRecordDetails.css'

const SingleRecordDetails = () => {
    const { id } = useParams();
    const productId = id

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);

  const apiUrl = `${process.env.REACT_APP_API_URL}/patch/orderdetails/${productId}`;

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setData(data); // Set the fetched data
        setIsLoading(false); // Update loading state
      })
      .catch((error) => {
        setError(error); // Handle errors
        setIsLoading(false); // Update loading state
      });
  }, [apiUrl]); // The empty dependency array ensures this effect runs only once on mount

  const downloadFile = (fileUrl,filenameParts) => {
      // Create a blob from the file URL or data
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          // Create a temporary URL for the blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a virtual link element
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `${filenameParts[0]}.${filenameParts[1]}`; // Specify the file name
  
          // Append the link to the document
          document.body.appendChild(link);
  
          // Trigger a click on the link to initiate the download
          link.click();
  
          // Remove the link from the document
          document.body.removeChild(link);
        });
    };

    return (
        <>
        <Container fluid className='Sngle-recrd-details d-flex justify-content-center '>
          
        <Row className='Sngle-recrd-details mt-5 mb-5'>
        <Container className="innr-continer">
              <h3 className='fw-bold text text-center mt-2'>Your Patch Order Details</h3>
                <p className='mt-3 mb-3 frm-dashline'></p>
          
          {isLoading ? (

          <p colSpan="8">Loading...</p>

          ) : error ? (
              <p colSpan="8">Error: {error.message}</p>
          ) : (
        
       <Row className='p-3'>
        {data.map((item) => (
          <Col md={12} key={item.id}>
            <Row>
              <Col md={4}>
                <p><strong>Design Name:</strong> {item.designName}</p>
              </Col>
              <Col md={4}>
                <p><strong>PO No:</strong> {item.poNumber}</p>
              </Col>
              <Col md={4}>
                <p><strong>Type:</strong> {item.type}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
                <p><strong>Quantity:</strong> {item.quantity}</p>
              </Col>
              <Col md={4}>
                <p><strong>Backing:</strong> {item.backing}</p>
              </Col>
              <Col md={4}>
                <p><strong>Border:</strong> {item.border}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
                <p><strong>Width:</strong> {item.width}</p>
              </Col>
              <Col md={4}>
                <p><strong>Height:</strong> {item.height}</p>
              </Col>
              <Col md={4}>
                <p><strong>Scale:</strong> {item.measureScale}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={4}>
                <p><strong>Numbers of Colors:</strong> {item.numOfColor}</p>
              </Col>
              <Col md={4}>
                <p><strong>Delivery Date:</strong> {item.deliveryDate}</p>
              </Col>
              <Col md={4}>
                <p><strong>Instruction:</strong> {item.instruction}</p>
              </Col>
            </Row>
            
            <Row>
              <Col md={6}>
              <p><strong>Additional Instruction:</strong> {item.additionalInstruction}</p>
              </Col>
              <Col md={6}>
                <p><strong>Shipping Address:</strong> {item.shippingAddress}</p>
              </Col>
            </Row>

            <Row>
            <Col md={6}>
              <p><strong>CC Order To:</strong> {item.ccOrderTo}</p>
              </Col>
              <Col md={6}>
              <p><strong>Super Urgent:</strong> {item.superUrgent ? <span>yes</span> : <span>No</span>}</p>
              </Col>
            </Row>
            <Row>
            <p><strong>Files:</strong></p>
              <Col md={12}>
              {item.attachments ? item.attachments.map((urlfile,index)=>{
                const segments = urlfile.split('/');
                const fileEx = segments[segments.length - 1];
                const filenameParts = fileEx.split('.');
                return(
                <>
                <div className=' border row d-flex flex-row  align-items-center justify-content-center'>
                <p className='  col-8 '>{index + 1}_{`${filenameParts[0]}.${filenameParts[1]}`}</p>
                  <Link className=' col-2 mx-auto ' to={urlfile}><FaEye className='fs-4' /></Link> 
                  <FaDownload className=' link-success  mx-auto p-0 col-2 fs-4' onClick={()=> downloadFile(urlfile,filenameParts)} />
                </div>
                </>)
              }):<p>no files</p>}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      )}
      </Container>
        </Row>
        </Container>
        </>
    )
}

export default SingleRecordDetails
