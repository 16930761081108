import React from 'react'
import { useState } from 'react';
import '../../../../App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Modal, Carousel} from 'react-bootstrap';
import '../styles/Hero.css';
import { FaFacebookF, FaYoutube, FaInstagram, FaTiktok, FaLinkedin } from 'react-icons/fa';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Hero = () => {



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  // const [error, setError] = useState('');
  const [isLoading, setIsLoading] =useState(false)

  const [formData, setFormData] = useState({
    name:'',
    email:'',
    contact: '',
    width:'',
    height:'',
    type:'Embroidered',
    quantity:'',
    backing:'Iron On Patch',
    requiredDate: '',
    instruction:'',
    attachments:[null],
  })
  
  
  
  // const handleFileUpload = (e) => {
  //   const name = e.target.name;
  //   setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: e.target.files[0],
  //   }));
  // }

  const handleFileUpload = (e) => {
    const files = e.target.files;
const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);

if (totalSize > 500 * 1024 * 1024) {
  // Alert the user about the size limit
  window.alert('Total file size exceeds 200 MB');
  // Optionally, you can clear the input field
  e.target.value = null;
  return;
}
    setFormData({
      ...formData,
      attachments: files,
    });
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const instantQuoteUrl =`${process.env.REACT_APP_API_URL}/adminDashboard/instantQuote`
console.log("!!!!!!!!!!!",formData)
  const handleSubmit = async(e) => {
    if(formData.attachments[0] === null){
      window.alert("Please insert the Attachments!")
      e.target.value = null
      return
    }
    e.preventDefault();
    setValidated(true)
  // setIsLoading(true)

    // const form = new FormData();
    // for (const key in formData) {
    //     form.append(key, formData[key]);
    // }
    const form = new FormData();
      for (const key in formData) {
        if (key === 'attachments') {
          for (let i = 0; i < formData[key].length; i++) {
            form.append('files', formData[key][i]);
          }
        } else {
          form.append(key, formData[key]);
        }
      }
    try {
      setIsLoading(true)
        const response = await fetch(instantQuoteUrl, {
            method: 'POST',
          // headers: {
          //   'Content-Type': 'application/json',
          // },
            body: form,
            // body: JSON.stringify(formData),
        });

        if (response.ok) {
          setIsLoading(false)
            //  handleClose()
            
            toast.success("Qute send Successfully")
            
        } else {
            // Handle registration error
          setIsLoading(false)
          toast.error("sending failed")
            console.error('order failed');
            // setIsLoading(false)
        }
    } catch (error) {
      setIsLoading(false)
      toast.error("sending failed")
        console.error('Error:', error);
        // setIsLoading(false)
    }
  
  };
  return (
    <Container fluid className='hero-section'>
      
      {/* Mobile Button Checkin Modal*/}         
      <Button className='btn-primary instQ-btn' variant="primary" onClick={handleShow}>
        Instant Quotation
      </Button>

      <Modal show={show} size='md' onHide={handleClose}>
      {!isLoading?(<>
        <Modal.Header closeButton>
          <Modal.Title>Instant Quotation</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
            <Form.Group as={Col} className=' w-100 mb-2' controlid="formGridName">
            <Form.Label>Name:</Form.Label>
            <Form.Control className='form-control-sm dgform-cntrol' 
            type="text" 
            placeholder="*Name" 
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
            />
          </Form.Group>
          
          <Form.Group as={Col} className=' w-100 mb-2' controlid="formGridEmail">
            <Form.Label>Email:</Form.Label>
            <Form.Control className='form-control-sm dgform-cntrol'  
            type="email"
            placeholder="*Email"
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
            />
          </Form.Group>

          <Form.Group as={Col}className=' w-100 mb-2' controlid="formGridPhone">
            <Form.Label>Contact No:</Form.Label>
            <Form.Control className='form-control-sm dgform-cntrol' 
            placeholder="contact No" 
            type="number" 
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            />
          </Form.Group>

          <Row className="mb-2">
            <Form.Group className="w-100"as={Col} controlid="formGridWidth">
                <Form.Label>Width:</Form.Label>
                <Form.Control className='form-control-sm dgform-cntrol' 
                type="number" 
                placeholder="*In inches"
                name='width'
                value={formData.width}
                onChange={handleChange}
                 />
            </Form.Group>

            <Form.Group className="w-100"as={Col} controlid="formGridHeight">
                <Form.Label>Height:</Form.Label>
                <Form.Control className='form-control-sm dgform-cntrol' 
                type="number" 
                placeholder="*In inches"
                name='height'
                value={formData.height}
                onChange={handleChange}
                />
            </Form.Group>
         </Row>
        
         <Row >
            <Form.Group className='mb-2' as={Col}  controlid="formType">
              <Form.Label>Patch Type:</Form.Label>
              <Form.Select className='form-select-sm dgform-cntrol'
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
                <option value='Embroidered' >Embroidered</option>
                <option value='Pins' >Pins</option>
                <option value='Socks' >Socks</option>
                <option value='Chenille' >Chenille</option>
                <option value='Sublimated' >Sublimated</option>
                <option value='Leather' >Leather</option>
                <option value='EMB+Sublimated' >EMB + Sublimated</option>
                <option value='PVC/Silicon' >PVC / Silicon</option>
                <option value='Woven' >Woven / Lables</option>
              </Form.Select>
            </Form.Group>
              
            <Form.Group as={Col}  controlid="backing">
              <Form.Label>Backing:</Form.Label>
              <Form.Select className='form-select-sm dgform-cntrol'
                name="backing"
                value={formData.backing}
                onChange={handleChange}
              >
                <option value='Iron On Patch'>Iron on Patch</option>
                <option value='PealAndStick'>Peel and Stick</option>
                <option value='Sew On Patch'>Plain sew on Patch</option>
                <option value='Sew On With Sew DownFile'>Sew on with sew down file</option>
                <option value='Not Sure'>Not Sure</option>
                <option value='Velcro Backing'>Velcro Backing</option>
              </Form.Select>
           </Form.Group>
         </Row>
              
            <Row>
              <Form.Group  as={Col}  className="mb-2" controlid="quantity">
              <Form.Label >Quantity:</Form.Label>
              <Form.Control className='form-control-sm dgform-cntrol'
              type="number"
              placeholder="Add Your Quantity"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              />
              </Form.Group>
              
              <Form.Group  as={Col}>
              <Form.Label>Required Date</Form.Label>
              <Form.Control className='form-control-sm dgform-cntrol' 
              type='date'
              name='requiredDate'
              value={formData.requiredDate}
              onChange={handleChange}
              />
            </Form.Group>
          </Row>
                    
                  
                          
        <Form.Group className="mb-3" controlid="ControlTextarea">
            <Form.Label>Instruction:</Form.Label>
            <Form.Control className='form-select-sm dgform-cntrol border-1 form-txt-area' 
            as="textarea" 
            rows={3}
            placeholder='Write Instruction'
            name='instruction'
            value={formData.instruction}
            onChange={handleChange} 
             />
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
        <Form.Group as={Col}  controlid="formFile1">
          <Form.Label >Upload file</Form.Label>
          <Form.Control className='form-control-sm dgform-cntrol'
            type="file"
            name="attachments"
            onChange={handleFileUpload}
            accept=".jpg, .png, .webp, .emb, .dst, .exp, .pes, .cnd, .pxf, .pof,
            .dsb, .ofm, .jpeg, .jpg, .webp, .psd, .pdf, .ai, .eps, .svg, .cdr, .heic, .zip, .rar, .tif,"
            required
            multiple
          />
        </Form.Group> 
          <Button className='btn-primary' variant='primary' type='submit' >
            Submit
          </Button>
        </Modal.Footer>
        </Form>
        </>
        ):(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          Loading... <CircularProgress />
        </Box>
        )}
      </Modal>
          
    <Container className='container-form d-flex align-items-center justify-content-center'>

                
              
              {/* Desktop Checkin Container */}
                  <Row className='checkIn g-0'>
                      {/* left side checkin */}
                      <Col md={4} >
                              <Form className='hero-form-left'noValidate validated={validated} >
                                <h3>Instant Quotation</h3>
                                {!isLoading?(<>
                                <Row>
                                      <Col as={Col} md={6} controlid="formGridName">
                                      <Form.Group>

                                          <Form.Label>Name:</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol' 
                                          type="text" 
                                          placeholder="*Name" 
                                          name='name'
                                          value={formData.name}
                                          onChange={handleChange}
                                          required
                                          />
                                          </Form.Group>


                                          <Form.Group>

                                    
                                          <Form.Label>Email:</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol' 
                                          type="email"
                                          placeholder="*Email"
                                          name='email'
                                          value={formData.email}
                                          onChange={handleChange}
                                          required
                                          />
                                          
                                          </Form.Group>

                                          <Form.Group>
                                          
                                          <Form.Label>Contact No:</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol' 
                                          placeholder="contact No" 
                                          type="number" 
                                          name="contact"
                                          value={formData.contact}
                                          onChange={handleChange}
                                          />
                                          </Form.Group>
                                          
                                          <Form.Group>
                                          <Form.Label>Width</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol' 
                                          type="number" 
                                          placeholder="*In inches"
                                          name='width'
                                          value={formData.width}
                                          onChange={handleChange}
                                           />
                                          </Form.Group>


                                          <Form.Group>
                                          <Form.Label>Height:</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol' 
                                          type="number" 
                                          placeholder="*In inches"
                                          name='height'
                                          value={formData.height}
                                          onChange={handleChange}
                                          />
                                          </Form.Group>
                                          <Form.Group as={Col}  controlid="formFile1">
                                            <Form.Label >Upload file</Form.Label>
                                            <Form.Control className='form-control-sm dgform-cntrol'
                                              type="file"
                                              name="attachments"
                                              onChange={handleFileUpload}
                                              accept=".jpg, .png, .webp, .emb, .dst, .exp, .pes, .cnd, .pxf, .pof,
                                              .dsb, .ofm, .jpeg, .jpg, .webp, .psd, .pdf, .ai, .eps, .svg, .cdr, .heic, .zip, .rar, .tif,"
                                              required
                                              multiple
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                provide a valid file.
                                            </Form.Control.Feedback>
                                          </Form.Group>  
                                      
                                            <Button className='btn btn-primary mt-3' variant="primary" onClick={handleSubmit} >
                                              Submit
                                            </Button>
                                      </Col> 
                                      
                                      <Col as={Col} md={6} controlid="formGridState">
                                      <Form.Group>
                                      <Form.Label>Patch Type:</Form.Label>
                                            <Form.Select className='form-select-sm dgform-cntrol'
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                            required
                                            >
                                              <option value='Embroidered' >Embroidered</option>
                                              <option value='Pins' >Pins</option>
                                              <option value='Socks' >Socks</option>
                                              <option value='Chenille' >Chenille</option>
                                              <option value='Sublimated' >Sublimated</option>
                                              <option value='Leather' >Leather</option>
                                              <option value='EMB+Sublimated' >EMB + Sublimated</option>
                                              <option value='PVC/Silicon' >PVC / Silicon</option>
                                              <option value='Woven' >Woven / Lables</option>
                                            </Form.Select>
                                          </Form.Group>
                                            
                                            <Form.Group as={Col}  controlid="backing">
                                            <Form.Label>Backing:</Form.Label>
                                            <Form.Select className='form-select-sm dgform-cntrol'
                                              name="backing"
                                              value={formData.backing}
                                              onChange={handleChange}
                                            >
                                              <option value='Iron On Patch'>Iron on Patch</option>
                                              <option value='PealAndStick'>Peel and Stick</option>
                                              <option value='Sew On Patch'>Plain sew on Patch</option>
                                              <option value='Sew On With Sew DownFile'>Sew on with sew down file</option>
                                              <option value='Not Sure'>Not Sure</option>
                                              <option value='Velcro Backing'>Velcro Backing</option>
                                            </Form.Select>
                                          </Form.Group>

                                            <Form.Group as={Col} className="mb-2" controlid="quantity">
                                            <Form.Label >Quantity:</Form.Label>
                                            <Form.Control className='form-control-sm dgform-cntrol'
                                            type="number"
                                            placeholder="Add Your Quantity"
                                            name="quantity"
                                            value={formData.quantity}
                                            onChange={handleChange}
                                            />
                                          </Form.Group>

                                          <Form.Group>
                                          <Form.Label>Required Date</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol' 
                                          type='date'
                                          name='requiredDate'
                                          value={formData.requiredDate}
                                          onChange={handleChange}
                                          />
                                          </Form.Group>

                                      
                                          {/* <Form.Label>Quantity</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol' type='number' placeholder='*In Dozen'/>
                                       */}
                                      <Form.Group>

                                          <Form.Label>Instruction</Form.Label>
                                          <Form.Control className='form-control-sm dgform-cntrol border-1 form-txt-area' 
                                          as="textarea" 
                                          rows={3}
                                          placeholder='Write Instruction'
                                          name='instruction'
                                          value={formData.instruction}
                                          onChange={handleChange} 
                                           />
                                      </Form.Group>

                                      </Col>

                        
                                  </Row>
                                </>) : (
                                  <Box
                                    sx={{
                                      marginTop:10,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'start',
                                      height: '100vh',
                                    }}
                                  >
                                    Loading... <CircularProgress />
                                  </Box>
                                )}
                              </Form>
                      </Col>
                        {/* Right-Side Checkin */}
                      <Col md={2} >
                          <div className='hero-form-right'>

                              <h5>Digitizing Fox</h5>
                              <p>Whatsapp Business Account</p>
                              <img
                                  src='/assets/qr-code.webp'
                                  alt='QR-Code'
                              />
                              <p>Scan this code to start a Whatsapp chat with Digitizing Fox</p>
                                <div className='h3 mt-3 d-inline-flex' >
                                    <a href='https://www.facebook.com/PatchProducer/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0' className='fb-Hvr text-white me-3'><FaFacebookF/></a>
                                    <a href='https://www.youtube.com/@DigitizingFox' className='utube-Hvr text-white me-3'><FaYoutube/></a>
                                    <a href='https://www.instagram.com/digitizingfox' className='insta-Hvr text-white me-3'><FaInstagram/></a>
                                </div>
                                <div className='h3 mt-3 d-inline-flex' >
                                <a href='https://www.linkedin.com/in/your_linkedin_profile' className='linkedin-Hvr text-white me-3'><FaLinkedin/></a>
                                <a href='https://www.tiktok.com/@digitizingfox' className='tiktok-Hvr text-white'><FaTiktok/></a>
                                </div>

                          </div>
                      </Col>
                     
                      <Col md={5} className='d-flex align-items-center justify-content-center'>
                        <Carousel className='hero-slider ms-3 rounded-5'>
                          <Carousel.Item>
                              <img
                              alt='imge'
                              src='/assets/zz.webp'
                              className=' img-fluid'
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                              alt='imge'
                              src='/assets/Embroidered patch.webp'
                              className=' img-fluid'
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                              alt='imge'
                              src='/assets/ss.webp'
                              className=' img-fluid'
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                              alt='imge'
                              src='/assets/r.webp'
                              className=' img-fluid'
                              />
                          </Carousel.Item><Carousel.Item>
                              <img
                              alt='imge'
                              src='/assets/f.webp'
                              className=' img-fluid'
                              />
                          </Carousel.Item><Carousel.Item>
                              <img
                              alt='imge'
                              src='/assets/PVC Patch.webp'
                              className=' img-fluid'
                              />
                          </Carousel.Item><Carousel.Item>
                              <img
                              alt='imge'
                              src='/assets/a.webp'
                              className=' img-fluid'
                              />
                          </Carousel.Item>
                        </Carousel>
                      </Col>
                  </Row>
          </Container>
       
        </Container>
    
  )
}

export default Hero